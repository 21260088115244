import React from 'react';
import PropTypes from 'prop-types';
import { Div, Label, StyledInput } from './inputStyles';

class Input extends React.Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.props.onChange({
      [this.props.name]: e.target.value,
    });
  }

  render() {
    const {
      value, label, disabled,
    } = this.props;
    return (
      <Div>
        <Label>{label}</Label>
        <StyledInput
          disabled={disabled}
          value={value || ''}
          onChange={this.onChange}
        />
      </Div>
    );
  }
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

Input.defaultProps = {
  value: '',
};

export default Input;
