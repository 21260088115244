import * as constants from '../constants';

export function decrementCount(panelSlug) {
  return (dispatch) => {
    dispatch({
      type: constants.DECREMENT_NOTIFICATION,
      payload: panelSlug,
    });
  };
}
