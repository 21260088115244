import styled, { css } from 'styled-components';

import { ButtonStyles } from '../../UI/Button/Button';

export const SplitScreen = styled.div`
  display: flex;
  > * {
    width: 50%;
  }
`;

export const NewQuestionForm = styled.form`
  background: #ffffff;
`;

export const QuestionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.color.brand.dark};
  color: #ffffff;
  padding: 0 20px;

  .question-info {
    width: 100%;
    text-align: center;
  }

  button {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    font-size: 12px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    font-weight: 900;
    display: flex;
    align-items: center;
  }
`;

export const PreviewContainer = styled.div`
  background: white;
  border-left: 1px solid #dedede;
  position: relative;
`;

export const SpacingContainer = styled.div`
  padding: 20px;

  > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CenteredButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const SaveQuestionButton = styled.button`
  ${ButtonStyles}
  height: 30px;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 20px;
  ${props => !props.saveDisabled && css`
    cursor: not-allowed;
  `}
`;

export const DeleteQuestionButton = styled.button`
  ${ButtonStyles}
  height: 30px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: ${props => props.theme.color.brand.error};
`;

export const SubmitButton = styled.input`
  ${ButtonStyles}
`;

export const BoldHr = styled.hr`
  border-top: 1px solid #222;
`;

export const SummaryHeader = styled.div`
  font-weight: 900;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 30px;
`;
