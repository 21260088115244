import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { newModel, fetchModel, fetchModelParams } from '../../actions/modelActions';
import EditorPanel from './editorPanel';

const propTypes = {
  panelObject: PropTypes.instanceOf(Object).isRequired,
  models: PropTypes.instanceOf(Object).isRequired,
  newModel: PropTypes.instanceOf(Object).isRequired,
  fetchModel: PropTypes.instanceOf(Object).isRequired,
  fetchModelParams: PropTypes.instanceOf(Object).isRequired,
  isModelFetched: PropTypes.bool.isRequired,
  isParamsFetched: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  panelObject: state.navigation.panelObject,
  models: state.model.models,
  isModelFetched: state.model.modelFetched,
  isParamsFetched: state.model.paramsFetched,
});

class Editor extends React.Component {
  componentDidMount() {
    const { panelObject } = this.props;
    if (!panelObject.itemId) {
      this.props.newModel(panelObject.panelSlug);
    } else {
      this.props.fetchModel(panelObject.panelSlug, panelObject.itemId);
    }
    this.props.fetchModelParams(panelObject.panelSlug);
  }

  render() {
    const {
      panelObject, models, isModelFetched, isParamsFetched,
    } = this.props;
    if (panelObject.settings && panelObject.settings.linking) {
      // Setup editor to allow for adding linked models and loop through and render all models
      if (!isModelFetched || !isParamsFetched) {
        return (
          <div>Loading...</div>
        );
      }

      return (
        <div>
          {models.map((model, index) => <EditorPanel key={model.object.id} modelIndex={index} model={model} />)}
        </div>
      );
    }

    return <EditorPanel />;
  }
}

Editor.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    newModel,
    fetchModel,
    fetchModelParams,
  },
)(Editor);
