import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import {
  updateMatchingQuestion,
  saveMatchingQuestions,
  deleteMatchingQuestion,
  getMatchingQuestion,
  destroyCurrentQuestion,
  setUnapproved,
} from '../../../actions/qbank/matching';
import MatchingForm from '../../../components/Qbank/Matching/Form';

const MatchingEditContainer = (props) => {
  const questionId = props.match.params.id;
  const { currentQuestion } = props;
  useEffect(() => {
    props.reset();
  }, [currentQuestion]);

  useEffect(() => {
    props.getQuestion(questionId);
  }, [questionId]);

  useEffect(() => {
    return () => {
      props.destroy();
      props.destroyQuestion();
    }
  }, [])

  return (
    <MatchingForm
      {...props}
    />
  );
};

MatchingEditContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  getQuestion: PropTypes.func.isRequired,
  qbank: PropTypes.shape({
    currentQuestion: PropTypes.shape({}),
  }),
  initialize: PropTypes.func.isRequired,
};

MatchingEditContainer.defaultProps = {
  qbank: {},
};

const formDecoratedComponent = reduxForm({
  form: 'matching_question',
  enableReinitialize: true,
})(MatchingEditContainer);

const formSelector = formValueSelector('matching_question');

const mapStateToProps = state => {
  return ({
    questionData: {
      questions: formSelector(state, 'questions'),
      explanation: formSelector(state, 'explanation'),
      teachingPoint: formSelector(state, 'teachingPoint'),
      track: formSelector(state, 'track'),
      topic: formSelector(state, 'topic'),
      status: formSelector(state, 'status'),
      vignette: formSelector(state, 'vignette'),
      answers: formSelector(state, 'answers'),
    },
    currentQuestion: state.qbank?.currentQuestion,
    initialValues: {
      /**
       * Anything that goes in this object will be attempted to preload into
       * Redux form. The question data is coming from `getMatchingQuestion`
       * and saved in `reducers/qbankReducer`
       */
      ...(state.qbank && state.qbank?.currentQuestion),
    },
  })
};

export default connect(
  mapStateToProps,
  {
    /**
     * Should be an action for saving existing, rather than creating new. Maybe this can be the same action.
     */
    onSubmitQuestion: updateMatchingQuestion,
    onSaveQuestions: saveMatchingQuestions,
    onDeleteQuestion: deleteMatchingQuestion,
    getQuestion: getMatchingQuestion,
    destroyQuestion: destroyCurrentQuestion,
    addClickHandler: setUnapproved,
  },
)(formDecoratedComponent);
