import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { MainPage } from './styles';

class Home extends React.Component {
  render() {
    return (
      <MainPage>
        <h1>Welcome!</h1>
      </MainPage>
    );
  }
}

export default withRouter(connect()(Home));
