import styled from 'styled-components';

export const Label = styled.label`
  color: ${props => props.theme.color.form.label};
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const LabelError = styled.span`
  color: ${props => props.theme.color.brand.error};
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 6px;
  display: inline-block;
`;
