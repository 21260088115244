import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { fetchTotalAverageLTV, fetchFirstPurchaseLTV } from '../../actions/analyticActions';
import { CenterRow } from './styles';

const propTypes = {
  fetchTotalAverageLTV: PropTypes.func.isRequired,
  fetchFirstPurchaseLTV: PropTypes.func.isRequired,
  panelObject: PropTypes.shape({
    component: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  analytic: PropTypes.shape({
    totalAverageLtv: PropTypes.number,
    firstPurchaseLtv: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  analytic: state.analytic,
  panelObject: state.navigation.panelObject,
});

class CompanyRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  componentDidMount() {
    this.getTotalAverageLTV();
    this.getFirstPurchaseLTV();
  }

  getTotalAverageLTV() {
    this.props.fetchTotalAverageLTV(this.props.panelObject.component, {
      from: this.state.startDate.format('YYYY-MM-DD'),
      to: this.state.endDate.format('YYYY-MM-DD'),
    });
  }

  getFirstPurchaseLTV() {
    this.props.fetchFirstPurchaseLTV(this.props.panelObject.component, {
      from: this.state.startDate.format('YYYY-MM-DD'),
      to: this.state.endDate.format('YYYY-MM-DD'),
    });
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    }, () => {
      this.getTotalAverageLTV();
      this.getFirstPurchaseLTV();
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    }, () => {
      this.getTotalAverageLTV();
      this.getFirstPurchaseLTV();
    });
  }

  render() {
    return (
      <div>

        <CenterRow>
          <h1>{this.props.panelObject.name}</h1>
          <h3>Average LTV: {this.props.analytic.totalAverageLtv}</h3>
          <h3>First Purchase LTV: {this.props.analytic.firstPurchaseLtv}</h3>
          <div>
            From: <DatePicker
              dropdownMode="select"
              selected={this.state.startDate}
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChangeStart}
              showMonthDropdown
              scrollableYearDropdown
              showYearDropdown
            />
          </div>
          <div>
            To: <DatePicker
              dropdownMode="select"
              selected={this.state.endDate}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChangeEnd}
              showMonthDropdown
              scrollableYearDropdown
              showYearDropdown
            />
          </div>
        </CenterRow>

      </div>
    );
  }
}

CompanyRevenue.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    fetchTotalAverageLTV,
    fetchFirstPurchaseLTV,
  },
)(CompanyRevenue);
