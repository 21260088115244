/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { SummaryGrid, SummaryItem } from './caseStyles';


const summarizeReviewOfSystem = ros => (
  <React.Fragment>
    <strong>Review of System</strong>
    <p>{ros}</p>
  </React.Fragment>
);

const summarizePastMedical = pastMedical => (
  <React.Fragment>
    <strong>Past Medical</strong>
    { pastMedical.map((item, i) => (
        item.history && item.history.length ?
          <div key={i}>
            <strong>{item.title}</strong>
            <ul>{item.history.map((bullet, j) => (<li key={j}>{bullet}</li>))}</ul>
          </div>
      : null))
    }
  </React.Fragment>
);

const summarizeMedsAllergies = (meds, allergies) => (
  <React.Fragment>
    <strong>Meds/Allergies</strong>
    <ul>
      {meds && meds.length ? meds.map((bullet, i) => (<li key={i}>{bullet}</li>)) : null}
      {allergies && allergies.length ? allergies.map((bullet, i) => (<li key={i}>{bullet}</li>)) : null}
    </ul>
  </React.Fragment>
);

const summarizePhysicalExam = physicalExam => (
  <React.Fragment>
    <strong>Physical Exam</strong>
    <ul>
      {physicalExam && physicalExam.details && physicalExam.details
        .filter(detail => detail.body)
        .map((detail, i) => (<li key={i}><strong>{detail.title}</strong> {detail.body}</li>))
      }
    </ul>
  </React.Fragment>
);

const renderSummary = (summary, summarizeFormData, ...args) => (
  summary.title && summary.body ?
    <React.Fragment>
      <strong>{summary.title}</strong>
      <div dangerouslySetInnerHTML={{ __html: summary.body }} />
    </React.Fragment>
    : summarizeFormData(...args)
);

const CaseSummaryPreview = ({
  summary, reviewOfSystem, pastMedical,
  meds, allergies, physicalExam,
}) => (
  <Tab.Container id="summary" defaultActiveKey={0}>
    {summary && summary.length === 4 ?
      <SummaryGrid>
        <SummaryItem>{renderSummary(summary[0], summarizeReviewOfSystem, reviewOfSystem)}</SummaryItem>
        <SummaryItem>{renderSummary(summary[1], summarizePastMedical, pastMedical)}</SummaryItem>
        <SummaryItem>{renderSummary(summary[2], summarizeMedsAllergies, meds, allergies)}</SummaryItem>
        <SummaryItem>{renderSummary(summary[3], summarizePhysicalExam, physicalExam)}</SummaryItem>
      </SummaryGrid>
      :
      <SummaryGrid>
        <SummaryItem>{summarizeReviewOfSystem(reviewOfSystem)}</SummaryItem>
        <SummaryItem>{summarizePastMedical(pastMedical)}</SummaryItem>
        <SummaryItem>{summarizeMedsAllergies(meds, allergies)}</SummaryItem>
        <SummaryItem>{summarizePhysicalExam(physicalExam)}</SummaryItem>
      </SummaryGrid>
    }
  </Tab.Container>
);

CaseSummaryPreview.propTypes = {
  summary: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  })),
  reviewOfSystem: PropTypes.string,
  pastMedical: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    history: PropTypes.arrayOf(PropTypes.string),
  })),
  meds: PropTypes.arrayOf(PropTypes.string),
  allergies: PropTypes.arrayOf(PropTypes.string),
  physicalExam: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
    })),
  }),
};

CaseSummaryPreview.defaultProps = {
  summary: null,
  reviewOfSystem: null,
  pastMedical: [],
  meds: [],
  allergies: [],
  physicalExam: {},
};

export default CaseSummaryPreview;
