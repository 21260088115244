import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import {
  Div,
  Textarea,
  Buttons,
} from './statusUpdateStyles';

const propTypes = {
  optionalMessage: PropTypes.string.isRequired,
  onChangeOptionalMessage: PropTypes.func.isRequired,
  confirmStatusChange: PropTypes.func.isRequired,
  cancelStatusChange: PropTypes.func.isRequired,
};

const defaultProps = {
};

class StatusUpdate extends React.Component {
  render() {
    const {
      optionalMessage,
      onChangeOptionalMessage,
      confirmStatusChange,
      cancelStatusChange,
    } = this.props;

    return (
      <Div>
        <Textarea
          placeholder="Write your feedback (optional).
          Once you select an action, your message will be sent and the status of this question will change."
          onChange={e => onChangeOptionalMessage(e)}
          value={optionalMessage}
        />
        <Buttons>
          <Button
            bsStyle="primary"
            onClick={confirmStatusChange}
          >
            Update
          </Button>
          <Button
            onClick={cancelStatusChange}
          >
            Cancel
          </Button>
        </Buttons>
      </Div>
    );
  }
}

StatusUpdate.propTypes = propTypes;
StatusUpdate.defaultProps = defaultProps;

export default connect(
  null,
  {},
)(StatusUpdate);
