import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Button,
  CommentDiv,
  Comment,
  Header,
  CreatedAt,
} from './commentsStyles';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  panelSlug: PropTypes.string.isRequired,
  comments: PropTypes.instanceOf(Array).isRequired,
  postComment: PropTypes.func.isRequired,
  modelIndex: PropTypes.number,
};

const defaultProps = {
  // modelIndex is only needed for linked models like Question
  modelIndex: null,
};

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newComment: '',
    };
  }

  onSendComment(event) {
    event.preventDefault();
    const { newComment } = this.state;
    this.props.postComment({
      unique: this.props.id,
      type: this.props.panelSlug,
      message: newComment,
      modelIndex: this.props.modelIndex,
    });
    this.setState({
      ...this.state,
      newComment: '',
    });
  }

  updateComment(value) {
    this.setState({
      ...this.state,
      newComment: value,
    });
  }

  render() {
    const { comments } = this.props;

    return (
      <div>
        <label>Feedback</label>
        { comments && comments.length > 0 ? comments.map(comment => (
          <Comment key={comment.id} isNew={comment.isNew}>
            <CreatedAt>{comment.prettyCreatedAt}</CreatedAt>
            {/* Comments have headers in the database which should override the name header if not null */}
            { comment.header === null ?
              <Header>{comment.user.first_name} {comment.user.last_name}</Header> :
              <Header>{comment.header}</Header>
            }
            { comment.message && <p>{`"${comment.message}"`}</p> }
          </Comment>
                    )) : (<p><i>No feedback yet</i></p>)}
        <CommentDiv>
          <Input
            type="text"
            style={{ flex: 1, marginRight: '10px' }}
            onChange={event => this.updateComment(event.target.value)}
            value={this.state.newComment}
          />
          <Button onClick={event => this.onSendComment(event)}>Send</Button>
        </CommentDiv>
      </div>
    );
  }
}

Comments.propTypes = propTypes;
Comments.defaultProps = defaultProps;

export default Comments;
