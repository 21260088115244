import styled, { css } from 'styled-components';

export const ButtonStyles = css`
  background-color: ${props => props.theme.color.brand.main};
  box-shadow: none;
  border: none;
  outline: 0;
  color: white;
  border-radius: 50px;
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const CenteredButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
