import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { AnswerContainer, DeleteButton } from './Answers.styles';
import FroalaEditor from '../../UI/reduxFormElements/FroalaEditor/FroalaEditor';
import Radio from '../../UI/reduxFormElements/Radio/Radio';
import { hasCorrectAnswer } from '../../../validation';

const Answer = ({
  answer,
  index,
  fields,
  questionKey,
  noRadio,
}) => {
  const handleDelete = (e) => {
    e.preventDefault();
    fields.remove(index);
  };

  return (
    <AnswerContainer>
      <Field
        name={`${answer}.value`}
        component={FroalaEditor}
      />
      {!noRadio &&
        <Field
          name={`${questionKey}.correctanswer`}
          component={Radio}
          type="radio"
          value={`answers[${index}]`}
          validate={hasCorrectAnswer}
        />
      }
      <DeleteButton onClick={handleDelete} />
    </AnswerContainer>
  );
};

Answer.propTypes = {
  answer: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.shape({}).isRequired,
  noRadio: PropTypes.bool,
  questionKey: PropTypes.string,
};

Answer.defaultProps = {
  noRadio: false,
  questionKey: null,
};

export default Answer;
