import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { MenuItem, DropdownButton } from 'react-bootstrap';
import { cloneModel, deleteModel, updateModel } from '../../actions/modelActions';
import AdminForm from '../Form';
import Preview from '../Preview';
import FormMessage from './formMessage';
import LinkingControls from './linkingControls.js';
import { MainPage, SubPanel, Panel, EditorBar } from './editorPanelStyles';

const propTypes = {
  modelIndex: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  model: PropTypes.instanceOf(Object).isRequired,
  params: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  panelObject: PropTypes.instanceOf(Object).isRequired,
  isNavFetched: PropTypes.bool.isRequired,
  isParamsFetched: PropTypes.bool.isRequired,
  isModelFetched: PropTypes.bool.isRequired,
  cloneModel: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
  updateModel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const isLinked = state.navigation.panelObject.settings && state.navigation.panelObject.settings.linking;
  const { modelIndex } = props;
  return {
    message: isLinked ? state.model.models[modelIndex].message : state.model.message,
    model: isLinked ? state.model.models[modelIndex].object : state.model.object,
    params: state.model.params,
    user: state.user.user,
    panelObject: state.navigation.panelObject,
    isNavFetched: state.navigation.fetched,
    isParamsFetched: state.model.paramsFetched,
    isModelFetched: state.model.modelFetched,
  };
};

class EditorPanel extends React.Component {
  onOptionSelect(eventKey) {
    const { panelObject, model } = this.props;

    switch (eventKey) {
      case 'new':
        // eslint-disable-next-line no-console
        console.error('New options menu action is not implemented!');
        break;
      case 'clone':
        swal({
          title: `Cloning ${panelObject.panelSingularName}`,
          text: 'Are you sure you would like to clone?',
          buttons: {
            cancel: {
              text: 'Cancel',
              closeModal: true,
              visible: true,
            },
            clone: {
              text: 'Submit',
              closeModal: false,
              visible: true,
            },
          },
        })
          .then((value) => {
            if (value !== null) {
              swal(`Cloning ${panelObject.panelSingularName}`);
              this.props.cloneModel(panelObject.panelSlug, model.id);
            }
          });
        break;
      case 'delete':
        swal({
          title: `Deleting ${panelObject.panelSingularName}`,
          text: 'Are you sure you would like to delete?',
          icon: 'warning',
          buttons: {
            cancel: {
              text: 'No',
              closeModal: true,
              visible: true,
            },
            confirm: {
              text: 'Yes',
              closeModal: false,
              visible: true,
            },
          },
        })
          .then((value) => {
            if (value !== null) {
              swal(`Deleting ${panelObject.panelSingularName}`);
              this.props.deleteModel(panelObject.panelSlug, model.id);
            }
          });
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(`${eventKey} not implemented! Add logic in EditorPanel/index.js`);
    }
  }

  submitForm(event) {
    event.preventDefault();
    const {
      user,
      model,
      modelIndex,
      panelObject,
    } = this.props;
    this.props.updateModel(user, model, panelObject.panelSlug, model.id, modelIndex);
  }

  render() {
    const {
      model,
      isModelFetched,
      isParamsFetched,
      isNavFetched,
      params,
      message,
      panelObject,
    } = this.props;

    if (isModelFetched && isParamsFetched && isNavFetched && model.id) {
      const title = (panelObject.itemId === 'new' || !panelObject.itemId
        ? `New ${panelObject.panelSingularName}`
        : `${panelObject.panelSingularName} ${model.id}`);

      return (
        <MainPage>
          <h1>{title}</h1>
          <EditorBar>
            <FormMessage message={message} />
            {panelObject.itemId && panelObject.settings && panelObject.settings.options &&
            <DropdownButton
              id="editorDropdownButton"
              bsStyle="default"
              bsSize="small"
              title="options"
              onSelect={event => this.onOptionSelect(event)}
            >
              {panelObject.settings.options.map(item => (
                <MenuItem
                  disabled={item.disabled}
                  eventKey={item.action}
                  key={item.action}
                >{item.title}
                </MenuItem>
              ))}
            </DropdownButton>}
            <LinkingControls modelIndex={this.props.modelIndex} />
          </EditorBar>
          <Panel>
            <SubPanel>
              <AdminForm
                onSubmit={event => this.submitForm(event)}
                params={params}
                model={this.props.model}
                modelIndex={this.props.modelIndex}
              />
              <br />
            </SubPanel>
            <SubPanel>
              <Preview
                model={this.props.model}
              />
            </SubPanel>
          </Panel>
        </MainPage>
      );
    }

    return (<div>Loading... Please wait...</div>);
  }
}

EditorPanel.propTypes = propTypes;
EditorPanel.defaultProps = {
  modelIndex: null,
};

export default connect(
  mapStateToProps,
  {
    cloneModel,
    deleteModel,
    updateModel,
  },
)(EditorPanel);
