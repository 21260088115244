import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import components from './previewComponents';

const propTypes = {
  panelObject: PropTypes.instanceOf(Object).isRequired,
  model: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  panelObject: state.navigation.panelObject,
});

class Previewer extends React.Component {
  render() {
    const { panelObject, model } = this.props;

    const Component = components(panelObject.panelSlug);

    // Component can be null if this model doesn't return a preview.
    // If you want a preview be sure to add a preview component to previewComponents
    // and hook in up in previewComponent/index.js
    if (!Component) {
      return null;
    }

    return (
      <div>
        <h2>{panelObject.panelSingularName} {model.id}</h2>
        <Component
          model={model}
        />
      </div>
    );
  }
}

Previewer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {},
)(Previewer);
