import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { fetchUser as fetchUserAction } from '../../../actions/userActions';
import { logout as logoutAction } from '../../../actions/authActions';

import NavDropdown from './NavDropdown';
import { NavContainer } from './Navigation.styles';

const Navigation = ({
  fetchUser,
  logout,
  userName,
  permissions,
}) => {
  useEffect(() => {
    fetchUser();
  }, []);

  const [openItem, setOpenItem] = useState(null);
  const handleDropownClick = (name) => {
    if (name !== openItem) {
      return setOpenItem(name);
    }
    return setOpenItem(null);
  };

  const handleLogoutClick = () => logout();

  return (
    <NavContainer>
      <div className="logo">Admin</div>
      <div className="nav-items">
        <NavDropdown
          label="Questions"
          name="questions"
          currentlyOpen={openItem}
          onClick={handleDropownClick}
          access={['questions']}
          permissions={permissions}
        >
          <NavLink to="/onlinemeded/questions/overview">Overview</NavLink>
          <hr />
          <NavLink to="/onlinemeded/questions/editor">New Default</NavLink>
          <NavLink to="/qbank/embedded/new">New Embedded</NavLink>
          <NavLink to="/qbank/matching/new">New Matching</NavLink>
        </NavDropdown>
        <NavDropdown
          label="Auditions"
          name="auditions"
          currentlyOpen={openItem}
          onClick={handleDropownClick}
          access={['auditions']}
          permissions={permissions}
        >
          <NavLink to="/onlinemeded/auditions/overview">Submissions</NavLink>
        </NavDropdown>
        <NavDropdown
          label="Cases"
          name="cases"
          currentlyOpen={openItem}
          onClick={handleDropownClick}
          access={['cases']}
          permissions={permissions}
        >
          <NavLink to="/onlinemeded/cases/editor">New</NavLink>
          <NavLink to="/onlinemeded/cases/overview">Overview</NavLink>
        </NavDropdown>
        <NavDropdown
          label="Analytics"
          name="analytics"
          currentlyOpen={openItem}
          onClick={handleDropownClick}
          access={['admin-users']}
          permissions={permissions}
        >
          <NavLink to="/onlinemeded/analytics/subscriptions">Subscriptions</NavLink>
          <NavLink to="/onlinemeded/analytics/orders">Orders</NavLink>
        </NavDropdown>
        <NavDropdown
          label="Admin Users"
          name="admin-users"
          currentlyOpen={openItem}
          onClick={handleDropownClick}
          access={['admin-users']}
          permissions={permissions}
        >
          <NavLink to="/admin-users/editor">New</NavLink>
          <NavLink to="/admin-users/overview">Overview</NavLink>
        </NavDropdown>
      </div>
      <div className="user">
        <span className="glyphicon glyphicon-user" />
        {userName}
      </div>
      <button className="btn btn-danger" onClick={handleLogoutClick}>
        <span className="glyphicon glyphicon-log-out" />
        Logout
      </button>
    </NavContainer>
  );
};

const mapStateToProps = state => ({
  userName: state.user.user.fullName,
  permissions: state.user.user.permissions,
});

export default connect(
  mapStateToProps,
  {
    fetchUser: fetchUserAction,
    logout: logoutAction,
  },
)(Navigation);
