import styled, { css } from 'styled-components';

import { InputPlaceholderStyles } from '../Input/styles';

export const DropdownContainer = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.form.border};
  border-radius: 3px;
  outline: none;
  cursor: pointer;
`;

export const ValueField = styled.input`
  height: 100%;
  width: 100%;
  outline: none;
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 0 ${props => props.theme.color.form.input};
  border: none;

  padding: 16px 20px;

  
  &::-webkit-input-placeholder {
    ${InputPlaceholderStyles}
  }
  &::-moz-placeholder {
    ${InputPlaceholderStyles}  
  }
`;

export const InputArrow = styled.div`
  position: absolute;
  top: calc(50% - 2px);
  right: 20px;

  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-top: 7px solid ${props => props.theme.color.form.border};
`;

export const ItemContainer = styled.ul`
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 0;
  visibility: hidden;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;

  max-height: 400px;
  overflow: scroll;

  border: 1px solid ${props => props.theme.color.form.border};
  border-radius: 3px;

  ${props => props.active && css`
    visibility: visible; 
  `}
`;

export const DropdownItemContainer = styled.li`
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  padding: 16px 20px;

  &:hover {
    background-color: ${props => props.theme.color.form.highlight};
  }
`;
