import styled, { css } from 'styled-components';

export const InputPlaceholderStyles = css`
  font-style: italic;
  color: ${props => props.theme.color.form.placeholder};
`;

export const StyledInput = styled.input`
  height: 40px;
  padding: ${props => props.theme.padding.input};
  display: block;
  width: 100%;
  color: ${props => props.theme.color.form.input};

  &::-webkit-input-placeholder {
    ${InputPlaceholderStyles}
  }
  &::-moz-placeholder {
    ${InputPlaceholderStyles}  
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;
