import styled from 'styled-components';

export const Answers = styled.div`
    padding-left: 40px;
`;

export const Image = styled.img`
    cursor: pointer;
    max-width; 90%;
`;
