import styled from 'styled-components';

export const MainPage = styled.div`
    background: white;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 0 10px;

    border-radius: 4px;
    border: 1px solid #d3e0e9;
`;

export const Panel = styled.div`
    display: flex;
`;

export const SubPanel = styled.div`
    padding: 20px;
    width: 50%;
`;

export const CaseSubPanel = styled.div`
    padding: 20px;
    width: 100%;
`;

export const EditorBar = styled.div`
    display: flex;
    flex-direction: row;

    .save-text, .button, .btn, .dropdown {
        margin-left: 15px;
    }
`;

export const CaseEditorPanel = styled.div`
    display: flex;
    
    #case-editor-tabs-pane-2 fieldset.field.field-array.field-array-of-object > div:nth-child(2) .btn-add::after {
        content: ' Title';
    }

    #case-editor-tabs-pane-2 > form > div > fieldset > div > fieldset > div:nth-child(3) > p > .btn-add::after {
        content: ' Section';
    }

    #case-editor-tabs-pane-3 fieldset.field.field-array.field-array-of-object > div:nth-child(2) .btn-add::after {
        content: ' Link';
    }

    #case-editor-tabs-pane-3 > form > div > fieldset > div > fieldset > div:nth-child(3) > p > .btn-add::after {
        content: ' Question';
    }
`;
