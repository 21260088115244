import question from './question';
import flashcard from './flashcard';
import adminUser from './adminUser';

const matrix = {
  questions: question,
  flashcards: flashcard,
  'admin-users': adminUser,
};

export default function (key) {
  if (matrix[key]) {
    return matrix[key]();
  }
  const msg = 'Model doesn\'t exist! ' +
   `Please add model file to models directory and update models/index.js to include the new model for ${key}`;
  // eslint-disable-next-line no-console
  console.error(msg);
  return undefined;
}
