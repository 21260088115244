/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const propTypes = {
  formData: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

class Vitals extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.formData };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formData !== this.props.formData) {
      this.setState({
        temp: nextProps.formData.temp || '',
        bp: nextProps.formData.bp || '',
        hr: nextProps.formData.hr || '',
        rr: nextProps.formData.rr || '',
        o2: nextProps.formData.o2 || '',
        weight: nextProps.formData.weight || '',
      });
    }
  }

  onChange(name) {
    return (event) => {
      this.setState({
        [name]: event.target.value,
      }, () => this.props.onChange(this.state));
    };
  }

  render() {
    const {
      temp, bp, hr, rr, o2, weight,
    } = this.state;
    return (
      <div>
        <label>{this.props.schema.title}</label>
        <Row>
          <Col md={4}>Temp</Col>
          <Col md={4}>BP</Col>
          <Col md={4}>HR</Col>
        </Row>

        <Row>
          <Col md={4}>
            <input type="text" className="form-control" value={temp} onChange={this.onChange('temp')} />
          </Col>
          <Col md={4}>
            <input type="text" className="form-control" value={bp} onChange={this.onChange('bp')} />
          </Col>
          <Col md={4}>
            <input type="text" className="form-control" value={hr} onChange={this.onChange('hr')} />
          </Col>
        </Row>

        <Row>
          <Col md={4}>RR</Col>
          <Col md={4}>O2</Col>
          <Col md={4}>Weight</Col>
        </Row>

        <Row>
          <Col md={4}>
            <input type="text" className="form-control" value={rr} onChange={this.onChange('rr')} />
          </Col>
          <Col md={4}>
            <input type="text" className="form-control" value={o2} onChange={this.onChange('o2')} />
          </Col>
          <Col md={4}>
            <input type="text" className="form-control" value={weight} onChange={this.onChange('weight')} />
          </Col>
        </Row>
      </div>
    );
  }
}

Vitals.propTypes = propTypes;

export default Vitals;
