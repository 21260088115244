import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { Div } from './caseStyles';

const propTypes = {
  questions: PropTypes.instanceOf(Array),
};

const defaultProps = {
  questions: [],
};

class CaseQuestionPreview extends React.PureComponent {
  render() {
    const questions = this.props.questions || [];
    return (
      <Div>
        {
          questions.map((question, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Panel id="questions-panel" key={i}>
              <Panel.Heading>
                <Panel.Title toggle>{question.prompt}</Panel.Title>
              </Panel.Heading>

              <Panel.Collapse>
                <Panel.Body>
                  {/* eslint-disable-next-line react/no-danger */}
                  <p dangerouslySetInnerHTML={{ __html: question.longAnswer }} />
                </Panel.Body>
              </Panel.Collapse>
            </Panel>))
        }
      </Div>
    );
  }
}

CaseQuestionPreview.propTypes = propTypes;
CaseQuestionPreview.defaultProps = defaultProps;

export default CaseQuestionPreview;
