import React from 'react';
import PropTypes from 'prop-types';

class CheckboxList extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.updateModel = this.updateModel.bind(this);
    this.state = {
      [this.props.name]: this.props.value,
    };
  }

  onChange(e) {
    const updated = this.state[this.props.name].map((optIn) => {
      const optOut = Object.assign({}, optIn);
      optOut.enabled = optIn.name === e.target.name ? e.target.checked : optIn.enabled;
      return optOut;
    });
    this.setState({
      [this.props.name]: updated,
    }, this.updateModel);
  }

  updateModel() {
    this.props.onChange({ ...this.state });
  }

  render() {
    const options = this.state[this.props.name];
    if (!options || !options.length) {
      return '';
    }
    const { label } = this.props;
    return (
      <div>
        <label>{label}</label>
        <ul style={{ listStyleType: 'none', width: '100%' }}>
          {options.map(opt => (
            <li key={opt.name}>
              <input
                type="checkbox"
                id={`${label}_${opt.id}`}
                name={opt.name}
                onChange={this.onChange}
                checked={opt.enabled ? 'CHECKED' : ''} /> {opt.name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

CheckboxList.propTypes = {
  model: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
  })).isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckboxList;
