import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItemContainer } from './styles';

const DropdownItem = ({
  name,
  value,
  onClick,
}) => {
  const handleClick = () => {
    onClick(value);
  };

  return (
    <DropdownItemContainer onClick={handleClick}>
      {name}
    </DropdownItemContainer>
  );
};

DropdownItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DropdownItem;
