import axios from 'axios/index';
import {
  FETCH_DATA_LIST,
  FETCH_DATA_LIST_SUCCESS,
  FETCH_DATA_LIST_ERROR,
} from '../constants';

export function fetchDataList(panelSlug, params) {
  function formatQuery(paramsIn) {
    const paramsOut = paramsIn || {
      page: 0,
      pageSize: 20,
    };
    const filtered = paramsOut.filtered || [];
    const sorted = paramsOut.sorted || [];
    const attributes = {};
    let filterId = '';
    filtered.forEach((filter) => {
      filterId = filter.id;
      if (filter.id === 'category_name') {
        attributes.category = filter.value;
      } else if (filter.id === 'topic_name') {
        attributes.topic = filter.value;
      } else {
        attributes[filterId] = filter.value;
      }
    });
    sorted.forEach((sort) => {
      attributes.sorted = sort.id || '';
      if (sort.id === 'category_name') {
        attributes.sorted = 'categories.name';
      }
      if (sort.id === 'topic_name') {
        attributes.sorted = 'topics.name';
      }
      attributes.dir = sort.desc ? 'desc' : 'asc';
    });
    attributes.page = paramsOut.page + 1;
    attributes.pageSize = paramsOut.pageSize;
    return { params: attributes };
  }

  const fetchParams = formatQuery(params);

  return (dispatch, getState) => {
    const endpoint = `${getState().navigation.basePropertyApiPath}/${panelSlug}`;
    dispatch({ type: FETCH_DATA_LIST });
    axios.get(endpoint, fetchParams)
      .then(
        (response) => {
          dispatch({
            type: FETCH_DATA_LIST_SUCCESS,
            payload: response.data,
            params,
          });
        },
        (err) => {
          dispatch({ type: FETCH_DATA_LIST_ERROR, payload: err });
        },
      );
  };
}
