import styled from 'styled-components';

export const Report = styled.div`
    margin-bottom: 20px;
    border-bottom: solid 1px #eee;
`;

export const TrueSpan = styled.span`
    color: green;
`;

export const FalseSpan = styled.span`
    color: red;
`;

export const SelectedDiv = styled.div`
    background: #f1f1f1;
    padding: 15px 0;
    display: flex;
    justify-content: flex-end;
`;

export const SelectedSpan = styled.div`
    height: 36px;
    line-height: 36px;
    margin-right: 18px;
`;
