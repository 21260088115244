import styled from 'styled-components';

export const Field = styled.div`
  margin: 30px 0;
`;

export const InvalidDiv = styled.div`
  margin: 10px 0;

  span {
    color: red;
  }
`;
