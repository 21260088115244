import React from 'react';
import { connect } from 'react-redux';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Div } from './styles';

import QuestionModeration from './questionModeration';
import StatusUpdate from './statusUpdate';

import { updateStatus, changeModel } from '../../../../actions/modelActions';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  options: PropTypes.instanceOf(Array).isRequired,
  updateStatus: PropTypes.func.isRequired,
  changeModel: PropTypes.func.isRequired,
  model: PropTypes.instanceOf(Object).isRequired,
  modelIndex: PropTypes.number,
};

const defaultProps = {
  value: null,
  disabled: true,
  modelIndex: null,
};

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <span>EDIT</span>
    </components.DropdownIndicator>
  )
);

const DisabledDropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <div />
    </components.DropdownIndicator>
  )
);

const customStyles = {
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
};

class Status extends React.Component {
  constructor() {
    super();
    this.state = {
      pendingStatus: null,
      optionalMessage: '',
    };

    this.confirmStatusChange = this.confirmStatusChange.bind(this);
    this.cancelStatusChange = this.cancelStatusChange.bind(this);
    this.onSubmitStatus = this.onSubmitStatus.bind(this);
    this.onChangeOptionalMessage = this.onChangeOptionalMessage.bind(this);
  }

  onChangeStatus(statusObject) {
    this.updatePendingStatus(statusObject.value);
  }

  onSubmitStatus(status) {
    if (this.state.pendingStatus === status) {
      // Pending status is the same as status then the user has confirmed to change model status.
      this.confirmStatusChange();
    } else {
      this.updatePendingStatus(status);
    }
  }

  onChangeOptionalMessage(e) {
    e.preventDefault();
    this.setState({
      optionalMessage: e.target.value,
    });
  }

  updatePendingStatus(status) {
    this.setState({ pendingStatus: status });
    this.props.changeModel({ status }, this.props.modelIndex);
  }

  confirmStatusChange() {
    this.props.updateStatus(this.state.pendingStatus, this.state.optionalMessage, this.props.modelIndex);
    this.setState({
      pendingStatus: null,
      optionalMessage: '',
    });
  }

  cancelStatusChange() {
    this.setState({
      pendingStatus: null,
      optionalMessage: '',
    });
  }

  render() {
    const {
      value,
      disabled,
      options,
      model,
    } = this.props;
    // With React Select 2.0 we need to set the value prop to the option object
    // rather than just the value of the option object.
    // We get the select option object below.
    const selectedOption = options[options.findIndex((option) => {
      const testValue = this.state.pendingStatus ? this.state.pendingStatus : value;
      return option.value === testValue;
    })] || options[0];

    const isSelectedOptionUnreviewed = selectedOption.value === 'Unreviewed';
    const isCurrentStatusUnreviewed = value !== 'Approved' && value !== 'Reviewed';
    const isStatusChangePending = this.state.pendingStatus !== null && value !== this.state.pendingStatus;
    const isNewModel = model.id === 'new';

    return (
      <Div>
        { (isCurrentStatusUnreviewed || model.isStatusUpdated) && !isNewModel &&
          <QuestionModeration
            model={model}
            value={value}
            pendingStatus={this.state.pendingStatus}
            optionalMessage={this.state.optionalMessage}
            onSubmitStatus={this.onSubmitStatus}
            onChangeOptionalMessage={this.onChangeOptionalMessage}
          />
        }
        <label>Status</label>
        <ReactSelect
          styles={customStyles}
          components={isSelectedOptionUnreviewed && !isStatusChangePending
            ? { DropdownIndicator: DisabledDropdownIndicator }
            : { DropdownIndicator }}
          value={selectedOption}
          onChange={e => this.onChangeStatus(e)}
          isDisabled={isNewModel || disabled || (isSelectedOptionUnreviewed && !isStatusChangePending)}
          options={options}
        />
        {
          !isCurrentStatusUnreviewed && isStatusChangePending &&
          <StatusUpdate
            optionalMessage={this.state.optionalMessage}
            onChangeOptionalMessage={this.onChangeOptionalMessage}
            confirmStatusChange={this.confirmStatusChange}
            cancelStatusChange={this.cancelStatusChange}
          />
        }
      </Div>
    );
  }
}

Status.propTypes = propTypes;
Status.defaultProps = defaultProps;

export default connect(
  null,
  {
    updateStatus,
    changeModel,
  },
)(Status);
