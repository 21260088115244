import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Button } from 'react-bootstrap';

import {
  SelectedDiv,
  SelectedSpan,
} from './errorReportsStyles';
import columnDefinition from './errorReportsStructures';

import { resolveErrorReports } from '../../../actions/modelActions';
import { createTab } from '../../../actions/windowActions';

import { CheckCircle } from './checkCircle';

const propTypes = {
  value: PropTypes.instanceOf(Array).isRequired,
  panelObject: PropTypes.instanceOf(Object).isRequired,
  createTab: PropTypes.func.isRequired,
  resolveErrorReports: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  panelObject: state.navigation.panelObject,
});

class ErrorReports extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedReportIds: [],
      selectAll: false,
    };
    this.page = 0;
    this.pageSize = 10;
    this.reportIdsOnCurrentPage = this.reportIdsOnCurrentPage.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.setSelectAll = this.setSelectAll.bind(this);
  }

  onPageChange(newPage) {
    this.page = newPage;
    this.setSelectAll();
  }

  onPageSizeChange(newPageSize) {
    this.pageSize = newPageSize;
    this.setSelectAll();
  }

  setSelectAll() {
    const idsOnPage = this.reportIdsOnCurrentPage();
    const selectAll = idsOnPage.every(id => this.state.selectedReportIds.includes(id));
    this.setState({
      selectAll,
    });
  }

  selectReport(report) {
    const selectedReportIds = Object.assign([], this.state.selectedReportIds);
    const reportIndex = selectedReportIds.indexOf(report.id);
    if (reportIndex > -1) {
      selectedReportIds.splice(reportIndex, 1);
    } else {
      selectedReportIds.push(report.id);
    }

    this.setState({
      selectedReportIds,
    });
  }

  resolveSelected() {
    this.props.resolveErrorReports(this.state.selectedReportIds);
    this.setState({
      selectedReportIds: [],
    });
  }

  toggleSelectAll() {
    let selected = this.state.selectedReportIds.slice();
    const idsOnPage = this.reportIdsOnCurrentPage();
    if (this.state.selectAll) {
      selected = selected.filter(id => !idsOnPage.includes(id));
    } else {
      selected = [...new Set(selected.concat(idsOnPage))];
    }
    this.setState({
      selectedReportIds: selected,
      selectAll: !this.state.selectAll,
    });
  }

  reportIdsOnCurrentPage() {
    const begin = this.page * this.pageSize;
    const end = begin + this.pageSize;
    const { sortedData } = this.selectTable.getResolvedState();
    return sortedData.slice(begin, end).map(report => report.id);
  }

  render() {
    // Getting map of reports with selected property.
    const reports = this.props.value.map((report) => {
      const temp = report;
      temp.selected = this.state.selectedReportIds.includes(report.id);
      return temp;
    });

    const columns = columnDefinition(this.props.panelObject.panelSlug);
    if (this.props.panelObject.panelSlug === 'questions') {
      columns[4].Filter = () => (
        <CheckCircle
          checked={this.state.selectAll}
          onChange={this.toggleSelectAll}
        />
      );
    }

    return (
      <div>
        { reports && reports.length > 0 ? (
          <ReactTable
            ref={(reactTable) => { this.selectTable = reactTable; }}
            filterable
            defaultPageSize={this.pageSize}
            data={reports}
            columns={columns}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value)}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            getTrProps={(state, row) => ({
              onClick: (event) => {
                if (event.target.className !== 'reportSelect' && event.target.className !== 'reportSelectImage') {
                  this.props.createTab('error_report', row.original);
                } else {
                  this.selectReport(row.original);
                }
              },
              style: {
                cursor: 'pointer',
              },
            })}
          />
        ) : (<p>Nice! No errors yet!</p>) }
        { this.state.selectedReportIds.length > 0 &&
          <SelectedDiv>
            <SelectedSpan>{this.state.selectedReportIds.length} reports selected</SelectedSpan>
            <Button
              bsStyle="primary"
              onClick={() => this.resolveSelected()}
            >
              Resolve Selected
            </Button>
          </SelectedDiv>
        }
      </div>
    );
  }
}

ErrorReports.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    createTab,
    resolveErrorReports,
  },
)(ErrorReports);
