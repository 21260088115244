import {
  FETCH_DATA_LIST,
  FETCH_DATA_LIST_SUCCESS,
  FETCH_DATA_LIST_ERROR,
  UPDATE_PANEL_OBJECT,
} from '../constants';

export default function reducer(state = {
  data: {},
  params: {
    pages: -1,
    pageSize: 5,
    loading: true,
  },
  fetching: false,
  fetched: false,
  error: null,
}, action) {
  switch (action.type) {
    case FETCH_DATA_LIST: {
      return {
        ...state,
        fetching: true,
        data: {},
        params: { ...state.params, loading: true },
      };
    }
    case FETCH_DATA_LIST_ERROR: {
      return { ...state, fetching: false, error: action.payload };
    }
    case FETCH_DATA_LIST_SUCCESS: {
      const params = {
        pages: action.payload.last_page,
        pageSize: action.payload.per_page,
        loading: false,
      };
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data,
        params,
      };
    }

    // clear any data that may be incompatible with new display state
    case UPDATE_PANEL_OBJECT: {
      return {
        ...state,
        data: {},
      };
    }
  }

  return state;
}
