import styled from 'styled-components';

export const Div = styled.div`
  label {
    display: block;
  }
`;

export const Select = styled.select`

`;
