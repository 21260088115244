import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import swal from 'sweetalert';

import Dropdown from '../../UI/reduxFormElements/Dropdown/Dropdown';
import FroalaEditor from '../../UI/reduxFormElements/FroalaEditor/FroalaEditor';
import { Button } from '../../UI/Button/Button';
import { required } from '../../../validation';
import { SpacingContainer, CenteredButton, SaveQuestionButton, DeleteQuestionButton } from './Form.styles';

const MatchingQuestion = ({
  question,
  index,
  fields,
  onSubmitQuestion,
  onDeleteQuestion,
  answers,
}) => {
  /**
   * If the question is the first one, there is no parent to check for ID existence on
   * However, if index is greater than 0, check if the parent to the current question has an ID saved.
   * If not, then we won't have a linked_id to send to tie the question to,
   * and it would end up like an orphaned question with no meta data / explanation / answer choices.
   */
  const isParentSaved = index === 0 || fields.getAll()[index - 1].id;
  const handleSaveClick = (e) => {
    e.preventDefault();
    if (!isParentSaved) {
      return swal({
        title: 'Error',
        text: 'Please save parent question before saving this one.',
        icon: 'warning',
      });
    }
    return onSubmitQuestion(index);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    if (onDeleteQuestion && fields.get(index).id) {
      onDeleteQuestion(index, fields);
    }
  };

  return (
    <Fragment>
      <div style={{ display: 'none' }}>
        <Field
          name="id"
          component="input"
        />
      </div>
      <SpacingContainer>
        <Field
          name={`${question}.interrogatory`}
          component={FroalaEditor}
          label="Interrogatory"
          validate={required}
        />
        <Field
          name={`${question}.correct_answer`}
          component={Dropdown}
          data={answers && answers.map((answer, idx) => ({
            label: answer.value && answer.value.replace(/(<([^>]+)>)/ig, ''),
            value: answer.value,
            id: idx,
          }))}
          placeholder="Select an Answer"
          label="Correct Answer"
        />
      </SpacingContainer>
      <CenteredButton>
        <DeleteQuestionButton onClick={handleDeleteClick}>
          {index === 0 ?
            <div>Delete Question</div>
          :
            <div>Delete Linked Question</div>
          }
        </DeleteQuestionButton>
      </CenteredButton>
    </Fragment>
  );
};

MatchingQuestion.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
  })),
  question: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.shape({}).isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  params: PropTypes.shape({
    physician_tasks: PropTypes.array,
  }).isRequired,
};

MatchingQuestion.defaultProps = {
  answers: null,
};

const MatchingQuestionFormRepeatables = ({
  fields,
  onSubmitQuestion,
  onDeleteQuestion,
  params,
  addClickHandler,
  answers,
}) => {
  const canAddQuestions = fields.length === 0 || Boolean(fields.get(0).id);
  const handleAddField = (e) => {
    e.preventDefault();
    if (!canAddQuestions) {
      return swal({
        title: 'Error',
        text: 'Please save parent question before adding more prompts.',
        icon: 'warning',
      });
    }
    addClickHandler();

    return fields.push({});
  };

  return (
    <Fragment>
      {fields.map((question, index, allFields) => (
        <MatchingQuestion
          key={question}
          question={question}
          index={index}
          fields={allFields}
          onSubmitQuestion={onSubmitQuestion}
          onDeleteQuestion={onDeleteQuestion}
          params={params}
          answers={answers}
        />
      ))}
      <hr />
      <CenteredButton>
        <Button onClick={handleAddField}>Add linked question</Button>
      </CenteredButton>
    </Fragment>
  );
};

MatchingQuestionFormRepeatables.propTypes = {
  addClickHandler: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
  })),
  fields: PropTypes.shape({}).isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  params: PropTypes.shape({
    physician_tasks: PropTypes.array,
  }).isRequired,
};

MatchingQuestionFormRepeatables.defaultProps = {
  answers: null,
};

export default MatchingQuestionFormRepeatables;
