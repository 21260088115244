import React from 'react';
import { connect } from 'react-redux';
import {
  Tab,
  Tabs,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import {
  Div,
  Table,
  Row,
  Header,
  Data,
  AcceptDenyTab,
  OptionalMessage,
  OptionalMessageHeader,
  ReviewButtons,
  OptionalMessageSpan,
} from './auditionDetailsStyles';
import {
  approveAudition,
  denyAudition,
} from '../../actions/auditionActions';

const propTypes = {
  model: PropTypes.instanceOf(Object).isRequired,
  approveAudition: PropTypes.func.isRequired,
  denyAudition: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  model: state.model.object,
  panelObject: state.navigation.panelObject,
});

class AuditionDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      optionalMessage: '',
    };
  }

  onAccept() {
    const {
      model,
    } = this.props;

    swal({
      title: 'Accepting Audition',
      text: 'Are you sure?',
      buttons: {
        cancel: {
          text: 'No',
          closeModal: true,
          visible: true,
        },
        confirm: {
          text: 'Yes!',
          closeModal: false,
          visible: true,
        },
      },
    })
      .then((value) => {
        if (value !== null) {
          this.props.approveAudition(model.id, this.state.optionalMessage);
        }
      });
  }

  onDeny() {
    const {
      model,
    } = this.props;

    swal({
      title: 'Denying Audition',
      text: 'Are you sure?',
      buttons: {
        cancel: {
          text: 'No',
          closeModal: true,
          visible: true,
        },
        confirm: {
          text: 'Yes!',
          closeModal: false,
          visible: true,
        },
      },
    })
      .then((value) => {
        if (value !== null) {
          this.props.denyAudition(model.id, this.state.optionalMessage);
        }
      });
  }

  onMessageChange(event) {
    this.setState({
      optionalMessage: event.target.value,
    });
  }

  renderOverviewTab() {
    const { model } = this.props;
    const overviewColumns = {
      name: 'Name',
      email: 'Email',
      payment_method: 'Payment Method',
      payment_email: 'Payment Email',
      submitter_type: 'Submitter Type',
    };

    return (
      <Table>
        { Object.keys(overviewColumns).map(key => (
          <Row key={key} >
            <Header>{overviewColumns[key]}</Header>
            <Data>{model[key]}</Data>
          </Row>
        ))}
      </Table>
    );
  }

  renderAcceptDenyTab() {
    const {
      model,
    } = this.props;

    return (
      <AcceptDenyTab>
        <OptionalMessageHeader>Optional Message</OptionalMessageHeader>
        <OptionalMessageSpan>This will be presented at the start of the acceptence or denial email</OptionalMessageSpan>
        <OptionalMessage
          value={this.state.optionalMessage}
          onChange={e => this.onMessageChange(e)}
          placeholder="This is optional!" />
        { model.status === 'Submitted' &&
        <ReviewButtons>
          <Button
            bsStyle="success"
            onClick={() => this.onAccept()}
          >Accept
          </Button>
          <Button
            bsStyle="danger"
            onClick={() => this.onDeny()}
          >Deny
          </Button>
        </ReviewButtons>
        }
      </AcceptDenyTab>
    );
  }

  render() {
    const {
      model,
    } = this.props;

    if (!model) {
      return null;
    }

    return (
      <Div>
        <Tabs id="audition-details-panel">
          <Tab
            eventKey={0}
            key="tab_Overview"
            title="Overview"
          >
            {this.renderOverviewTab()}
          </Tab>
          <Tab
            eventKey={1}
            key="tab_Accept/Deny"
            title="Accept/Deny"
            disabled={model.status !== 'Submitted'}
          >
            {this.renderAcceptDenyTab()}
          </Tab>
        </Tabs>
      </Div>
    );
  }
}

AuditionDetails.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    approveAudition,
    denyAudition,
  },
)(AuditionDetails);
