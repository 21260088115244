import axios from 'axios/index';
import swal from 'sweetalert';
import history from '../history';
import * as constants from '../constants';

export function approveAudition(auditionId, optionalMessage) {
  return (dispatch, getState) => {
    const { basePath, basePropertyApiPath } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/auditions/${auditionId}/approve`;
    dispatch({ type: constants.AUDITION_APPROVE });
    axios.post(endpoint, {
      optionalMessage,
    })
      .then(
        (response) => {
          dispatch({
            type: constants.AUDITION_APPROVE_SUCCESS,
            payload: response.data,
          });
          swal({
            title: 'Audition Approved',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Confirm',
                closeModal: true,
                visible: true,
              },
              redirect: {
                text: 'Back To Submissions',
                closeModal: true,
                visible: true,
                value: 'redirect',
              },
            },
          })
            .then((value) => {
              if (value === 'redirect') {
                history.push(`${basePath}/auditions/overview`);
              }
            });
        },
        (err) => {
          dispatch({
            type: constants.AUDITION_APPROVE_ERROR,
            payload: err,
          });
          swal({
            title: 'Error!',
            text: err.message,
            icon: 'error',
          });
        },
      );
  };
}

export function denyAudition(auditionId, optionalMessage) {
  return (dispatch, getState) => {
    const { basePath, basePropertyApiPath } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/auditions/${auditionId}/deny`;
    dispatch({ type: constants.AUDITION_DENY });
    axios.post(endpoint, {
      optionalMessage,
    })
      .then(
        (response) => {
          dispatch({
            type: constants.AUDITION_DENY_SUCCESS,
            payload: response.data,
          });
          swal({
            title: 'Audition Denied',
            icon: 'success',
            buttons: {
              confirm: {
                text: 'Confirm',
                closeModal: true,
                visible: true,
              },
              redirect: {
                text: 'Back To Submissions',
                closeModal: true,
                visible: true,
                value: 'redirect',
              },
            },
          })
            .then((value) => {
              if (value === 'redirect') {
                history.push(`${basePath}/auditions/overview`);
              }
            });
        },
        (err) => {
          dispatch({
            type: constants.AUDITION_DENY_ERROR,
            payload: err,
          });
          swal({
            title: 'Error!',
            text: err.message,
            icon: 'error',
          });
        },
      );
  };
}
