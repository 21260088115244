import styled from 'styled-components';

export const Div = styled.div`

`;

export const TextAreaStyled = styled.textarea`
    width: 100%;
    max-width: 100%;
    min-width: 100%;

    height: 80px;
`;
