import styled from 'styled-components';

const containerPadding = 20;
export const Container = styled.div`
    width: ${containerPadding + 376}px;
    height: ${containerPadding + 436}px;
    padding: ${containerPadding}px;

    background-color: #f6f6f6;
`;

export const Flashcard = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    transition: 1s;
    transform-style: preserve-3d;

    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    font-family: 'Lato', sans-serif;

    ${props => (props.flipped ? 'transform: rotateY(180deg)' : null)}
`;

export const Topic = styled.h2`
    text-align: center;

    font-size: 18px;
    font-weight: 300;
    margin-top: 6px;
    margin-bottom: 12px;
`;

export const Lesson = styled.h3`
    text-align: center;

    font-size: 18px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 27px;
`;

export const Question = styled.h4`
    text-align: center;

    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 25px;
`;

export const Footer = styled.div`
    text-align: center;
    padding: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding-bottom: 20px;
`;

export const Button = styled.button`
    width: 110px;
    line-height: 1.3;
    font-size: 12px;
    font-weight: 700;
    padding: 7px 14px;
    color: #FFFFFF;
    background-color: #03BBE4;
    border: 0 none;
    border-radius: 20px;
    outline: none;

    &:hover {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    &:active {
        box-shadow: none;
        transform: translateY(1px);
    }
`;

export const Answer = styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 300;
`;

export const Image = styled.img`
    width: 100%;
    margin-top: 10px;
`;

export const Front = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px;
    transform: rotateY(0deg);

    z-index: 2;
    backface-visibility: hidden;
`;

export const Back = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;

    transform: rotateY(180deg);
    backface-visibility: hidden;
`;
