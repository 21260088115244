/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  PhysicalExamWrapper,
  PhysicalExam,
  VitalsGrid,
  VitalBlock,
} from './caseStyles';

const propTypes = {
  physicalExam: PropTypes.instanceOf(Object),
};

const defaultProps = {
  physicalExam: {
    vitals: {
      temp: '',
      bpLue: '',
      bpRue: '',
      hr: '',
      rr: '',
      o2: '',
      weight: '',
    },
  },
};

class CasePhysicalExamPreview extends React.PureComponent {
  render() {
    const {
      vitals,
      notes,
      details,
      specialized_exam_details,
    } = this.props.physicalExam || {};
    return (
      <div>
        <strong>Vitals</strong>
        <VitalsGrid>
          <VitalBlock>
            {`Temp ${vitals.temp}`}
          </VitalBlock>
          {
            vitals.bp &&
            <VitalBlock>
              {`BP ${vitals.bp}`}
            </VitalBlock>
          }
          <VitalBlock>
            {`HR ${vitals.hr}`}
          </VitalBlock>
          <VitalBlock>
            {`RR ${vitals.rr}`}
          </VitalBlock>
          <VitalBlock>
            {`Sat ${vitals.o2}`}
          </VitalBlock>
          <VitalBlock>
            {`Weight ${vitals.weight}`}
          </VitalBlock>
          {
            notes && notes.map(note => (
              <VitalBlock>
                {note}
              </VitalBlock>
            ))
          }
        </VitalsGrid>
        <PhysicalExamWrapper>
          <PhysicalExam>
            {details && details.map(detail => (
              <div key={detail.title}>
                <strong>{detail.title}</strong>
                <div>{detail.body}</div>
              </div>
            ))}
          </PhysicalExam>
          <PhysicalExam>
            {specialized_exam_details && specialized_exam_details.map(detail => (
              <div key={detail.title}>
                <strong>{detail.title}</strong>
                <div>{detail.body}</div>
              </div>
            ))}
          </PhysicalExam>
        </PhysicalExamWrapper>
      </div>
    );
  }
}

CasePhysicalExamPreview.propTypes = propTypes;
CasePhysicalExamPreview.defaultProps = defaultProps;

export default CasePhysicalExamPreview;
