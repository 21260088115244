import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Answer from './Answer';

import { AddAnswer, ButtonContainer } from './Answers.styles';

const Answers = ({ fields, questionKey, noRadio }) => {
  const handleAddAnswer = (e) => {
    e.preventDefault();
    if (fields.length < 8) {
      fields.push({});
    }
  };

  useEffect(() => {
    /**
     * Show at least 1 answer by default when
     * mounting this component.
     */
    if (!fields.length) fields.push({});
  }, []);

  return (
    <div>
      {fields.map((answer, index, all) => (
        <Answer
          key={answer}
          answer={answer}
          index={index}
          fields={all}
          questionKey={questionKey}
          noRadio={noRadio}
        />
      ))}
      <ButtonContainer>
        <AddAnswer onClick={handleAddAnswer}>+ Add answer</AddAnswer>
      </ButtonContainer>
    </div>
  );
};

Answers.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  questionKey: PropTypes.string,
  noRadio: PropTypes.bool,
};

Answers.defaultProps = {
  noRadio: false,
  questionKey: null,
};

export default Answers;
