/**
 * Helper functions in this file are from
 * 30s of Code. Do not change.
 *
 * https://github.com/30-seconds/30-seconds-of-code
 */

/* eslint-disable no-return-assign */
export const deepClone = (obj) => {
  const clone = Object.assign({}, obj);
  Object.keys(clone).forEach(key => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]));
  return Array.isArray(obj) ? (clone.length = obj.length) && Array.from(clone) : clone;
};

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const slugify = (stringIn, optIn) => {
  let slug = String(stringIn);

  const defaults = {
    delimiter: '-',
    limit: undefined,
    lowercase: true,
    replacements: {},
  };

  const opt = {
    ...defaults,
    ...optIn,
  };

  // Replace non-alphanumeric characters with our delimiter
  const alnum = RegExp('[^a-z0-9]+', 'ig');
  slug = slug.replace(alnum, opt.delimiter);

  // Remove duplicate delimiters
  slug = slug.replace(RegExp(`[${opt.delimiter}]{2,}`, 'g'), opt.delimiter);

  // Truncate slug to max. characters
  slug = slug.substring(0, opt.limit);

  // Remove delimiter from ends
  slug = slug.replace(RegExp(`(^'${opt.delimiter}|${opt.delimiter}$)`, 'g'), '');

  return opt.lowercase ? slug.toLowerCase() : slug;
};

/**
 * Filters out any characters except for numbers in a string.
 * @param {String} stringIn string that should be filtered.
 */
export const getIntegerFromString = stringIn => stringIn.replace(/[^0-9]/g, '');
