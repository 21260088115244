import models from '../models';
import * as constants from '../constants';

export default function reducer(state = {
  object: {},
  message: '',
  params: {},
  paramsFetching: false,
  paramsFetched: false,
  modelFetching: false,
  modelFetched: false,
  error: null,
  models: [],
}, action) {
  switch (action.type) {
    case 'FETCH_DATA': {
      return {
        ...state,
        modelFetched: false,
        modelFetching: true,
        message: {
          contents: 'Loading Model',
          color: '#eef442',
        },
      };
    }
    case 'FETCH_DATA_REJECTED': {
      return {
        ...state,
        modelFetching: false,
        error: action.payload,
        message: {
          contents: 'Update Failed',
          color: '#E02B45',
        },
      };
    }
    case 'FETCH_DATA_UNAUTHORIZED': {
      return {
        ...state,
        modelFetching: false,
        error: action.payload,
        message: {
          contents: 'Unauthorized',
          color: '#E02B45',
        },
      };
    }
    case 'FETCH_DATA_FULFILLED': {
      // If payload is an array then models are linked and we need to setup multiple model states in redux store
      if (action.payload.length) {
        const newModels = action.payload.map(model => ({
          object: model,
          message: {
            contents: 'Loaded',
            color: '#22A84F',
          },
        }));
        return {
          ...state,
          modelFetching: false,
          modelFetched: true,
          models: newModels,
        };
      }

      // Single model state
      return {
        ...state,
        modelFetching: false,
        modelFetched: true,
        object: action.payload,
        message: {
          contents: 'Loaded',
          color: '#22A84F',
        },
      };
    }
    case 'NEW_MODEL': {
      const newModel = {
        object: models(action.payload),
        message: {
          contents: 'New Model',
          color: '#00A3B7',
        },
      };

      if (action.linking) {
        if (action.fieldsToOverride && action.fieldsToOverride.length > 0) {
          // Overriding newModel values with values of currentModel.
          // This is to update the new linked question with some of the original model's data.
          action.fieldsToOverride.forEach((field) => {
            // Updating the new model field's data with that of the existing model.
            newModel.object.data[field.column_name] = action.currentModel.data[field.column_name];
          });
        }

        let newModels = [];
        if (action.modelIndex !== null) {
          newModels = Object.assign([], state.models);
          newModels.splice(action.modelIndex + 1, 0, newModel);
        } else {
          newModels = [newModel];
        }

        return {
          ...state,
          models: newModels,
          modelFetched: true,
        };
      }

      return {
        ...state,
        message: {
          contents: 'New Model',
          color: '#00A3B7',
        },
        modelFetched: true,
        object: models(action.payload),
      };
    }
    case 'FETCH_PARAMS': {
      return {
        ...state,
        paramsFetched: false,
        paramsFetching: true,
      };
    }
    case 'FETCH_PARAMS_REJECTED': {
      return {
        ...state,
        paramsFetching: false,
        error: action.payload,
      };
    }
    case 'FETCH_PARAMS_FULFILLED': {
      return {
        ...state,
        paramsFetching: false,
        paramsFetched: true,
        params: action.payload,
      };
    }
    case 'UPDATE_MODEL': {
      // If modelIndex exists then linking is setup for this type of model and we need to update the models array
      if (action.modelIndex !== null && typeof action.modelIndex === 'number') {
        const newModels = Object.assign([], state.models);
        newModels[action.modelIndex].message = {
          contents: 'Updating',
          color: '#FFBF36',
        };
        return {
          ...state,
          models: newModels,
          modelFetching: false,
          error: action.payload,
        };
      }

      // Single model update
      return {
        ...state,
        message: {
          contents: 'Updating',
          color: '#FFBF36',
        },
        modelFetching: true,
      };
    }
    case 'UPDATE_MODEL_REJECTED': {
      // If modelIndex exists then linking is setup for this type of model and we need to update the models array
      if (action.modelIndex !== null && typeof action.modelIndex === 'number') {
        const newModels = Object.assign([], state.models);
        newModels[action.modelIndex].message = {
          contents: 'Update Failed',
          color: '#E02B45',
        };
        return {
          ...state,
          models: newModels,
          modelFetching: false,
          error: action.payload,
        };
      }

      // Single model update
      return {
        ...state,
        message: {
          contents: 'Update Failed',
          color: '#E02B45',
        },
        modelFetching: false,
        error: action.payload,
      };
    }
    case 'UPDATE_MODEL_FULFILLED': {
      // If modelIndex exists then linking is setup for this type of model and we need to update the models array
      if (action.modelIndex !== null && typeof action.modelIndex === 'number') {
        const newModels = Object.assign([], state.models);
        newModels[action.modelIndex] = {
          message: {
            contents: 'Updated!',
            color: '#22A84F',
          },
          object: action.payload,
        };
        return {
          ...state,
          models: newModels,
          modelFetching: false,
        };
      }

      // Single model update
      return {
        ...state,
        message: {
          contents: 'Updated!',
          color: '#22A84F',
        },
        object: action.payload,
        modelFetching: false,
      };
    }
    case 'CHANGE_MODEL': {
      const model = Object.assign({}, action.modelIndex !== null
        ? state.models[action.modelIndex].object
        : state.object);
      const keys = Object.keys(action.payload);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === 'id') {
          throw new Error('Modifying ID of model is not allowed');
        }
        model.data[keys[i]].data = action.payload[keys[i]];
      }
      if (action.modelIndex !== null) {
        const newModels = Object.assign([], state.models);
        newModels[action.modelIndex] = {
          object: model,
          message: {
            contents: 'Unsaved Changes',
            color: '#ff4c34',
          },
        };
        return {
          ...state,
          models: newModels,
        };
      }

      return {
        ...state,
        object: model,
        message: {
          contents: 'Unsaved Changes',
          color: '#ff4c34',
        },
      };
    }
    case 'CLONE_MODEL': {
      return state;
    }
    case 'CLONE_MODEL_FULFILLED': {
      if (action.linking) {
        return {
          ...state,
          models: [{
            object: action.payload.question,
            message: {
              contents: 'Cloned!',
              color: '#33ff8f',
            },
          }],
        };
      }

      return {
        ...state,
        object: action.payload.question,
        message: {
          contents: 'Cloned!',
          color: '#33ff8f',
        },
      };
    }
    case 'CLONE_MODEL_REJECTED': {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.MODEL_POST_COMMENT_SUCCESS: {
      if (action.meta.modelType === 'cases') {
        return { ...state };
      }
      let newModel = Object.assign({}, state.object);
      if (typeof (action.meta.modelIndex) === 'number') {
        newModel = Object.assign({}, state.models[action.meta.modelIndex].object);
      }
      const comments = newModel.comments.slice();
      comments.push(action.payload.comment);
      newModel.comments = comments;
      const topModel = { ...state };
      if (typeof (action.meta.modelIndex) === 'number') {
        topModel.models[action.meta.modelIndex].object = newModel;
      } else {
        topModel.object = newModel;
      }
      return {
        ...topModel,
      };
    }
    case constants.MODEL_POST_COMMENT_ERROR: {
      return state;
    }

    case 'DELETE_MODEL': {
      return state;
    }
    case 'DELETE_MODEL_FULFILLED': {
      // If redirect_id is not null we need to splice the last half from the redirect_id as that relationship is lost
      if (action.redirect_id) {
        const newModels = Object.assign([], state.models);
        newModels.splice(newModels.findIndex(model => model.object.id === action.redirect_id) + 1);
        return {
          ...state,
          models: newModels,
        };
      }
      return {
        ...state,
      };
    }
    case 'DELETE_MODEL_REJECTED': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'REORDER_MODEL_FULFILLED': {
      const { modelIndex, direction } = action;
      const newModels = Object.assign([], state.models);
      const temp = newModels.splice(modelIndex, 1)[0];
      if (direction === 'up') {
        newModels.splice(modelIndex - 1, 0, temp);
      } else {
        newModels.splice(modelIndex + 1, 0, temp);
      }

      return {
        ...state,
        models: newModels,
      };
    }
    case constants.AUDITION_APPROVE_SUCCESS: {
      return {
        object: action.payload,
      };
    }
    case constants.AUDITION_DENY_SUCCESS: {
      return {
        object: action.payload,
      };
    }
    case constants.DECREMENT_NOTIFICATION: {
      // TODO: Will need to update this to work with multiple models if models like questions
      // ever need notifications.
      const model = Object.assign({}, state.object);
      model.justViewed = false;
      return {
        ...state,
        object: model,
      };
    }
    case constants.RESOLVE_ERROR_REPORT: {
      return {
        ...state,
      };
    }
    case constants.RESOLVE_ERROR_REPORT_SUCCESS: {
      const errorReportIds = action.payload;
      // Multiple models update
      if (state.models.length > 0) {
        const newModels = Object.assign([], state.models).map((model) => {
          const newModel = Object.assign({}, model);
          // Filtering out resolved error reports for linked models
          newModel.object.data.error_reports.data =
            model.object.data.error_reports.data.filter(errorReport => !errorReportIds.includes(errorReport.id));
          return newModel;
        });
        return {
          ...state,
          models: newModels,
          modelFetching: false,
        };
      }

      // Single model update
      const newModel = Object.assign({}, state.object);
      // Filtering out resolved error reports for model
      newModel.error_reports = newModel.error_reports.filter(errorReport => !errorReportIds.includes(errorReport.id));

      return {
        ...state,
        object: newModel,
      };
    }
    case constants.RESOLVE_ERROR_REPORT_ERROR: {
      return {
        ...state,
      };
    }
    case constants.UPDATE_MODEL_STATUS: {
      // This feature is only available to questions so we're checking multiple models
      // only since questions are linked.
      // TODO: This will need to be updating in the future to work with non linking models.
      const newModels = Object.assign([], state.models);
      const model = Object.assign({}, newModels[action.meta.modelIndex]);
      model.object.isStatusUpdating = true;
      model.object.isStatusUpdated = false;
      newModels[action.meta.modelIndex] = model;
      return {
        ...state,
        models: newModels,
      };
    }
    case constants.UPDATE_MODEL_STATUS_SUCCESS: {
      // See TODO in UPDATE_MODEL_STATUS
      const newModels = Object.assign([], state.models);
      const model = Object.assign({}, newModels[action.meta.modelIndex]);
      model.object.data.status.data = action.payload;
      model.object.isStatusUpdating = false;
      model.object.isStatusUpdated = true;
      newModels[action.meta.modelIndex] = model;
      return {
        ...state,
        models: newModels,
      };
    }
    case constants.UPDATE_MODEL_STATUS_ERROR: {
      // See TODO in UPDATE_MODEL_STATUS
      const newModels = Object.assign([], state.models);
      const model = Object.assign({}, newModels[action.modelIndex]);
      model.object.isStatusUpdating = false;
      model.object.isStatusUpdated = false;
      newModels[action.modelIndex] = model;
      return {
        ...state,
        models: newModels,
      };
    }
    case 'CLEAR_MODEL_DATA': {
      return {
        ...state,
        models: [],
        object: {},
      };
    }
  }

  return state;
}
