import React from 'react';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

export default property => ([
  {
    Header: 'ID',
    accessor: 'id',
    width: 50,
    Cell: id => (<Link to={`/${property}/cases/editor/${id.value}`}>{id.value}</Link>),
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['id'] }),
    filterAll: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['title'] }),
    filterAll: true,
  },
  {
    Header: 'Faculty Mode Title',
    accessor: 'faculty_title',
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['faculty_title'] }),
    filterAll: true,
  },
  {
    Header: 'Slug',
    accessor: 'slug',
  },
  {
    Header: 'Status',
    accessor: 'status.description',
    width: 120,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    width: 140,
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
    width: 140,
  },
]);
