import { caseDefaults, caseSchema, metaSchema, uiSchema } from '../schema/caseSchema_0.x.0';
import * as constants from '../constants';
import { deepClone } from '../utils.js';
import { buildSchema } from '../schema/schemaDefinitionBuilder';

export default function reducer(state = {
  documentId: null,
  document: deepClone(caseDefaults.document),
  meta: {
    slug: '',
    categories: [],
    topics: [],
  },
  schema: deepClone(caseSchema),
  metaSchema: deepClone(metaSchema),
  uiSchema,
  comments: [],
  errorReports: [],
  uploads: {},
  uploadProgress: 0,
  message: {
    color: constants.BG_SUCCESS,
    contents: '',
    subcontent: '',
  },
}, action) {
  switch (action.type) {
    case constants.NEW_DOCUMENT: {
      const document = deepClone(caseDefaults.document);
      const schema = deepClone(caseSchema);
      return {
        ...state,
        documentId: 0,
        meta: {
          slug: '',
          categories: [],
          topics: [],
        },
        document: {
          ...document,
          loaded: true,
        },
        documentStatus: 'Not Reviewed',
        schema,
        reelDxVideo: {},
        comments: [],
        errorReports: [],
        saved: true,
        message: {
          color: constants.BG_SUCCESS,
          contents: 'New',
          subcontent: '',
        },
      };
    }

    case constants.LOAD_DOCUMENT: {
      const document = { loaded: false };
      return {
        ...state,
        documentId: 0,
        document,
        documentStatus: '',
        comments: [],
        errorReports: [],
        saved: true,
        message: {
          color: constants.BG_WHITE,
          contents: '',
          subcontent: '',
        },
      };
    }

    case constants.LOAD_DOCUMENT_SUCCESS: {
      const defaultSchema = deepClone(caseSchema);
      const schema = buildSchema(defaultSchema, action.payload.document.formData);
      const topicIds = action.payload.topics ? action.payload.topics.map(topic => topic.id) : [];
      const categoryIds = action.payload.categories ?
        action.payload.categories.map(category => category.id) : [];

      const {
        id: documentId,
        slug,
        free,
        document,
        status,
      } = action.payload;

      // prevent inadvertent title change to featured free case hardcoded in frontend
      let uiSchemaOverride;
      if (free && slug === 'cardiology-1') {
        uiSchemaOverride = deepClone(uiSchema);
        uiSchemaOverride.hpi.title = {
          ...uiSchemaOverride.hpi.title,
          'ui:readonly': true,
          'ui:help':
            'WARNING: Changing \'Cardiology 1\' will interfere with the FREE DEMO CASE on the user facing frontend.' +
            ' If you are sure you want to change this value, set it to NOT FREE on the Meta tab, save, then reload.',
        };
      }

      return {
        ...state,
        documentId,
        meta: {
          slug,
          free: !!free,
          categoryIds,
          topicIds,
        },
        document: {
          ...document,
          loaded: true,
        },
        documentStatus: status ? status.description : 'Status',
        schema,
        uiSchema: uiSchemaOverride || uiSchema,
        saved: true,
        message: {
          color: constants.BG_SUCCESS,
          contents: 'Loaded',
          subcontent: '',
        },
      };
    }

    case constants.LOAD_DOCUMENT_ERROR: {
      return {
        ...state,
        document: null,
        message: {
          color: constants.BG_SUCCESS,
          contents: action.payload,
          subcontent: action.subcontent,
        },
      };
    }

    case constants.SAVE_DOCUMENT_SUCCESS: {
      const schema = buildSchema(state.schema, action.payload.document.formData);
      return {
        ...state,
        documentId: action.payload.id,
        document: action.payload.document,
        documentStatus: action.payload.documentStatus,
        meta: {
          slug: action.payload.slug,
          categoryIds: action.payload.categories,
          topicIds: action.payload.topics,
          free: action.payload.free,
        },
        schema,
        saved: true,
        message: {
          color: constants.BG_SUCCESS,
          contents: 'Saved',
        },
      };
    }

    case constants.SAVE_DOCUMENT_ERROR: {
      return {
        ...state,
        document: action.payload.document,
        message: {
          color: constants.BG_DANGER,
          contents: 'Error Saving',
          subcontent: action.payload.subcontent,
          errors: action.payload.errors,
        },
      };
    }

    case constants.FETCH_LESSONS_SUCCESS: {
      const topicList = action.payload || [];
      let categories = [];
      let topics = [];
      topicList.forEach((topic) => {
        if (topic.id && topic.category && topic.category.id) {
          topics[topic.id] = topic;
          categories[topic.category.id] = topic.category;
        }
      });

      // Dustyn requested temporary filtering of categories that are redundant for frontend filtering purposes
      // https://onlinemeded.atlassian.net/browse/DEV-1534
      categories = categories.filter(cat => cat.id && cat.id !== 41 && cat.id !== 43 && cat.id !== 44);
      categories = categories.sort((x, y) => x.id - y.id);
      const categoryIds = categories.map(cat => cat.id);
      const categoryNames = categories.map(cat => `${cat.id} - ${cat.name}`);
      topics = topics.filter(topic => topic.id);
      topics = topics.sort((x, y) => {
        // sort by category_id first
        const sort = x.category_id - y.category_id;
        if (sort !== 0) {
          return sort;
        }
        // sort by topic sort order second
        return x.sort - y.sort;
      });
      const topicIds = topics.map(topic => topic.id);
      const topicNames = topics.map(topic => `${topic.category.name} - ${topic.name}`);

      const newMetaSchema = deepClone(state.metaSchema);
      newMetaSchema.loaded = true;

      // topics (legacy categories)
      newMetaSchema.data.definitions.categories = {
        type: 'number',
        enum: categoryIds,
        default: categoryIds[0],
        enumNames: categoryNames,
      };
      // lessons (legacy topics)
      newMetaSchema.data.definitions.topics = {
        type: 'number',
        enum: topicIds,
        default: topicIds[0],
        enumNames: topicNames,
      };

      return {
        ...state,
        metaSchema: newMetaSchema,
      };
    }

    case constants.RESET_SUMMARY: {
      // deepClone does not preserve empty arrays, causes jrsf crash with some data.
      const document = Object.assign({}, action.payload);
      document.formData = Object.assign({}, action.payload.formData);
      document.formData.summary = deepClone(caseDefaults.formData.summary);
      return {
        ...state,
        document: {
          ...document,
          loaded: true,
        },
        message: {
          color: constants.BG_DANGER,
          contents: 'Summary Reset to Defaults',
        },
      };
    }

    case constants.FETCH_REEL_DX_VIDEO_SUCCESS: {
      // eslint-disable-next-line camelcase
      const { id, video_url, image_url } = action.payload;
      return {
        ...state,
        reelDxVideo: {
          reelDxId: id,
          videoUrl: video_url,
          posterImageUrl: image_url,
        },
        message: {
          contents: 'Unsaved Changes',
          color: constants.BG_DANGER,
        },
      };
    }

    case constants.UPLOAD_IMAGE: {
      return {
        ...state,
        uploadProgress: 1,
      };
    }

    case constants.UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadProgress: action.payload,
      };
    }

    case constants.UPLOAD_IMAGE_SUCCESS: {
      const uploads = {
        ...state.uploads,
        ...action.payload.upload,
      };
      return {
        ...state,
        documentId: action.payload.id,
        uploads,
        uploadProgress: 0,
      };
    }

    case constants.UPLOAD_IMAGE_ERROR: {
      return {
        ...state,
        message: {
          color: constants.BG_DANGER,
          contents: 'Error Uploading Image',
          subcontent: action.payload.subcontent,
          errors: action.payload.errors,
        },
        uploadProgress: 0,
      };
    }

    case constants.CASE_POST_COMMENT_SUCCESS: {
      if (action.modelType !== 'cases') {
        return { ...state };
      }
      const comments = state.comments.slice();
      comments.push(action.payload.comment);
      return {
        ...state,
        comments,
      };
    }

    case constants.CASE_POST_COMMENT_ERROR: {
      return {
        ...state,
        message: {
          color: constants.BG_DANGER,
          contents: 'Error',
          subcontent: action.payload.subcontent,
          errors: action.payload.errors,
        },
      };
    }

    case constants.CLONE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        documentId: action.payload.id,
        document: action.payload.document,
        documentStatus: 'Cloned',
        comments: action.payload.comments,
        errorReports: action.payload.errorReports,
        saved: true,
        message: {
          color: constants.BG_SUCCESS,
          contents: 'Cloned',
          subcontent: '',
        },
      };
    }

    case constants.CLONE_DOCUMENT_ERROR: {
      return {
        ...state,
        message: {
          color: constants.BG_SUCCESS,
          contents: 'Error Cloning',
          subcontent: action.payload.message,
        },
      };
    }

    case constants.UNSAVED_CHANGES_WARNING: {
      // only change our state if this event is turning on the unsaved warning
      if (action.payload) {
        return {
          ...state,
          saved: false,
          message: {
            contents: 'Unsaved Changes',
            color: constants.BG_DANGER,
          },
        };
      }
    }
  }

  return state;
}
