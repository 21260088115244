import React from 'react';
import moment from 'moment';
import { TrueSpan, FalseSpan } from './errorReportsStyles';

const questionsStructure = [
  {
    Header: 'ID',
    accessor: 'id',
    width: 50,
  },
  {
    Header: 'Reason',
    accessor: 'reason',
    Cell: row => (<div style={{
      whiteSpace: 'normal',
      height: '3em',
      lineHeight: '1.5em',
      overflow: 'hidden',
    }}>{row.value}</div>),
  },
  {
    Header: 'Error Type',
    accessor: 'sub_reason',
    width: 100,
  },
  {
    Header: 'Date Reported',
    accessor: 'created_at',
    Cell: item => moment(item.value).format('MM/DD/YY'),
    width: 150,
  },
  {
    Header: 'Select',
    Cell: row => (
      <div
        className="reportSelect"
        style={{
          width: '25px',
          height: '25px',
          margin: 'auto',
        }}
      >
        <img
          className="reportSelectImage"
          style={{
            width: '100%',
            height: '100%',
          }}
          src={
            row.original.selected
              ? '/images/errorReports/circle_checked.svg'
              : '/images/errorReports/circle_unchecked.svg'
          }
        />
      </div>
    ),
    width: 75,
  },
];

const flashcardsStructure = [
  {
    Header: 'ID',
    accessor: 'id',
    width: 50,
  },
  {
    Header: 'Reason',
    accessor: 'reason',
  },
  {
    Header: 'Error Type',
    accessor: 'sub_reason',
    width: 100,
  },
  {
    Header: 'Resolved',
    accessor: 'is_resolved',
    // not sure if value is always a number
    // eslint-disable-next-line eqeqeq
    Cell: item => (item.value == 1 ? (<TrueSpan>TRUE</TrueSpan>) : (<FalseSpan>FALSE</FalseSpan>)),
    width: 70,
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: item => moment(item.value).fromNow(),
    width: 100,
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
    Cell: item => moment(item.value).fromNow(),
    width: 100,
  },
];

const casesStructure = [
  {
    Header: 'ID',
    accessor: 'id',
    width: 50,
  },
  {
    Header: 'Reason',
    accessor: 'reason',
  },
  {
    Header: 'Error Type',
    accessor: 'sub_reason',
    width: 100,
  },
  {
    Header: 'Resolved',
    accessor: 'is_resolved',
    // not sure if value is always a number
    // eslint-disable-next-line eqeqeq
    Cell: item => (item.value == 1 ? (<TrueSpan>TRUE</TrueSpan>) : (<FalseSpan>FALSE</FalseSpan>)),
    width: 70,
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: item => moment(item.value).fromNow(),
    width: 100,
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
    Cell: item => moment(item.value).fromNow(),
    width: 100,
  },
];

const matrix = {
  questions: questionsStructure,
  flashcards: flashcardsStructure,
  cases: casesStructure,
};

export default function (key) {
  const result = matrix[key];
  if (!result) {
    const msg = `Error Report Structure does not exist! Please add structure to errorReportsStructures.js for ${key}`;
    throw new Error(msg);
  }

  return result;
}
