import axios from 'axios/index';

export function fetchUser() {
  return (dispatch) => {
    dispatch({ type: 'FETCH_USER' });
    axios.get('/api/v1/adminProduct/user')
      .then(
        (response) => {
          dispatch({ type: 'FETCH_USER_FULFILLED', payload: response.data });
        },
        (err) => {
          dispatch({ type: 'FETCH_USER_REJECTED', payload: err });
        },
      );
  };
}
