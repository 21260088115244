import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import axios from 'axios';
import swal from 'sweetalert';
import history from '~/history';

import Checkbox from '~/components/UI/reduxFormElements/Checkbox/Checkbox';
import Dropdown from '~/components/UI/reduxFormElements/Dropdown/Dropdown';
import FroalaEditor from '~/components/UI/reduxFormElements/FroalaEditor/FroalaEditor';
import { required } from '~/validation';

import EmbeddedQuestionFormRepeatables from './EmbeddedQuestionFormRepeatables';
import EmbeddedQuestionPreview from './Preview';

import {
  NewQuestionForm,
  SplitScreen,
  SpacingContainer,
  QuestionHeader,
  PreviewContainer,
  DeleteQuestionButton,
  BoldHr,
  SummaryHeader,
  SaveQuestionButton,
} from './Form.styles';

const EmbeddedForm = ({
  addClickHandler,
  questionData,
  onSubmitQuestion,
  onSaveQuestions,
  onDeleteQuestion,
  match,
  dirty,
}) => {
  const isNewQuestion = !match.params.id;

  const [params, setParams] = useState({
    topics: [],
    lessons: [],
    physician_tasks: [],
    status: [],
    tracks: [],
  });

  useEffect(() => {
    axios.get('/api/v1/propertyApi/onlinemeded/questions/params')
      .then(res => setParams(res.data))
      .catch((e) => {
        console.log(e);
        return swal({
          title: 'Error',
          text: 'Something went wrong getting question meta-information. Please try again.',
          icon: 'warning',
        });
      });
  }, []);

  const handleSaveAllClick = (e) => {
    e.preventDefault();

    /**
     * User is saving a new question.
     */
    if (isNewQuestion) {
      onSubmitQuestion(0);
    }

    /**
     * User has edited one of the questions in the set,
     * and is saving or updating all.
     */
    if (dirty) {
      onSaveQuestions();
    }
  };

  const handleDeleteAllClick = (e) => {
    e.preventDefault();
    let swalTitle = `Deleting question #${match.params.id}`;

    if (questionData.questions && questionData.questions.length > 0) {
      swalTitle = `Deleting questions${questionData.questions.map(question => ` #${question.id}`)}`;
    }

    return swal({
      title: swalTitle,
      text: 'Are you sure you would like to delete all of these questions?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No',
          visible: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
    })
      .then((value) => {
        if (value !== null) {
          axios.delete(`/api/v1/propertyApi/onlinemeded/questions/${match.params.id}/all`)
            .catch((error) => {
              console.log(error);
              swal({
                title: 'Something went wrong',
                icon: 'warning',
              });
            });
          swal({
            title: 'Questions deleted successfully',
            icon: 'success',
          });

          history.push('/onlinemeded/questions/overview');
        }
      });
  };

  return (
    <Fragment>
      <QuestionHeader>
        <div className="question-info">
          <h1>{isNewQuestion ? 'New Question' : `Question #${match.params.id}`}</h1>
        </div>
      </QuestionHeader>
      <SplitScreen>
        <NewQuestionForm
          className="question-form"
        >
          <SpacingContainer>
            <Field
              name="status"
              component={Dropdown}
              data={params.status}
              placeholder="Select a status"
              label="Status"
              validate={required}
            />
            <Field
              name="track"
              component={Dropdown}
              data={params.tracks}
              placeholder="Select a Track"
              label="Track"
              validate={required}
            />
            <Field
              name="topic"
              component={Dropdown}
              data={params.topics}
              placeholder="Select a Topic"
              label="Topic"
              validate={required}
              filterBy={{
                track_id: questionData.track,
              }}
            />
            <Field
              name="lesson"
              component={Dropdown}
              data={params.lessons}
              placeholder="Select a Lesson"
              label="Lesson"
              validate={required}
              filterBy={{
                category_id: questionData.topic,
              }}
            />
            <Field
              name="showOnChallenge"
              component={Checkbox}
              label="Show on Challenge"
            />
            <Field
              name="showOnQbanks"
              component={Checkbox}
              label="Show on Qbanks"
              disabled
            />
            <SaveQuestionButton onClick={handleSaveAllClick} saveDisabled={!isNewQuestion ? dirty : true}>
              Save
            </SaveQuestionButton>
            <DeleteQuestionButton onClick={handleDeleteAllClick}>
              Delete All
            </DeleteQuestionButton>
            {!dirty && !isNewQuestion &&
              <p>Please make edits to the form to save</p>
            }
          </SpacingContainer>
          <FieldArray
            name="questions"
            component={EmbeddedQuestionFormRepeatables}
            onSubmitQuestion={onSubmitQuestion}
            onDeleteQuestion={onDeleteQuestion}
            addClickHandler={addClickHandler}
            params={params}
          />
          <BoldHr />
          <SummaryHeader>
            Summary
          </SummaryHeader>
          <SpacingContainer>
            <Field
              name="explanation"
              component={FroalaEditor}
              label="Explanation"
              validate={required}
            />
            <Field
              name="teachingPoint"
              component={FroalaEditor}
              label="Teaching Point"
            />
          </SpacingContainer>
        </NewQuestionForm>
        <PreviewContainer>
          <EmbeddedQuestionPreview {...questionData} />
        </PreviewContainer>
      </SplitScreen>
    </Fragment>
  );
};

export const questionType = PropTypes.shape({
  vignette: PropTypes.string,
  interrogatory: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.string,
  })),
});

EmbeddedForm.propTypes = {
  addClickHandler: PropTypes.func.isRequired,
  questionData: questionType,
  onSubmitQuestion: PropTypes.func.isRequired,
  onSaveQuestions: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  params: PropTypes.shape({
    topics: PropTypes.array,
    lessons: PropTypes.array,
    physician_tasks: PropTypes.array,
    status: PropTypes.array,
    tracks: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  dirty: PropTypes.bool.isRequired,
};

EmbeddedForm.defaultProps = {
  questionData: {},
  params: {
    topics: [],
    lessons: [],
    physician_tasks: [],
    status: [],
    tracks: [],
  },
  match: {
    params: {},
  },
};

export default EmbeddedForm;
