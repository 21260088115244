import styled from 'styled-components';

export const MainPage = styled.div`
  background: white;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 0 10px;

  border-radius: 4px;
  border: 1px solid #d3e0e9;
`;

export const Header1 = styled.h1`
  margin-left: 25px;
`;

export const ApprovedMessage = styled.span`
  background-color: #2ab27b;
  margin-left: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

export const DeniedMessage = styled.span`
  background-color: #bf5329;
  margin-left: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
`;

export const Panel = styled.div`
  display: flex;
`;

export const SubPanel = styled.div`
  padding: 20px;
  width: 50%;
`;

export const EditorBar = styled.div`
  display: flex;
  flex-direction: row;

  .dropdown {
    margin-left: 20px;
  }
`;
