import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* froala BEGIN */
import 'froala-editor/js/froala_editor.pkgd.min.js';
import '../../../../froala_editor/js/plugins/video';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/special_characters.min.css';
import 'font-awesome/css/font-awesome.css';
import Editor from 'react-froala-wysiwyg';
/* froala END */

import { Label, LabelError } from '../sharedStyles';
import { FROALA_KEY } from '../../../../constants';

const toolbarButtons = [
  'fullscreen', 'bold', 'italic', 'underline', 'subscript', 'superscript', '|',
  'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-',
  'specialCharacters', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertTable', '|',
  'fontAwesome', 'specialCharacters', 'clearFormatting', '|',
  'spellChecker', 'help', 'html', '|', 'undo', 'redo',
];

const FroalaEditor = ({
  apiToken,
  input,
  meta,
  label,
}) => {

  if (!apiToken) {
    return (
      <p>Loading...</p>
    )
  }

  const froalaConfig = {
    key: FROALA_KEY,
    toolbarButtons,
    imageUploadURL: `/api/v1/froala/file/qbank?api_token=${apiToken}`,
    // Support up to 11MB that way content team can actually upload 10MB files
    imageMaxSize: 11 * 1024 * 1024,
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    videoUpload: false,
    wordPasteModal: false,
    wordPasteKeepFormatting: false,
  };
  return (
    <div>
      {label && (
        <Label htmlFor={input.name}>{label}</Label>
      )}
      {meta.error && (
        <LabelError>{meta.error}</LabelError>
      )}
      <Editor
        config={froalaConfig}
        onModelChange={input.onChange}
        model={input.value}
      />
    </div>
  );
};

FroalaEditor.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  apiToken: PropTypes.string,
};

FroalaEditor.defaultProps = {
  label: null,
  apiToken: null,
};

const mapStateToProps = state => ({
  apiToken: state.user.user.api_token,
});

export default connect(mapStateToProps)(FroalaEditor);
