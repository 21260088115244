import axios from 'axios';
import swal from 'sweetalert';

export function uploadFile(file) {
  return (dispatch, getState) => {
    // TODO: decouple this from navigation state
    const { panelObject } = getState().navigation;
    const data = new FormData();

    data.append('file', file);
    data.append('name', 'What up');

    dispatch({
      type: 'UPLOAD_FILE',
    });
    axios.post(`api/v1/propertyApi/${panelObject.panelSlug}/upload`, data)
      .then(
        () => {
          swal({
            title: 'Uploaded successful!',
            icon: 'success',
          });
        },
        (err) => {
          swal({
            title: 'Error occurred while uploading',
            text: err.message,
            icon: 'error',
          });
        },
      );
  };
}
