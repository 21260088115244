import React from 'react';
import { connect } from 'react-redux';
import ReactJWPlayer from 'react-jw-player';
import { Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Div } from './caseStyles';
import { caseDefaults } from '../../../schema/caseSchema_0.x.0';
import CaseEmrPreview from './caseEmrPreview';
import CaseQuestionPreview from './caseQuestionPreview';
import CaseResourcePreview from './caseResourcePreview';
import CasePhysicalExamPreview from './casePhysicalExamPreview';
import CaseSummaryPreview from './caseSummaryPreview';

const propTypes = {
  document: PropTypes.instanceOf(Object),
  videoLibraryId: PropTypes.string.isRequired,
};

const defaultProps = {
  document: caseDefaults.document,
};

function listItems(items) {
  const arry = items || [];
  if (!Array.isArray(arry)) return '';
  // eslint-disable-next-line react/no-array-index-key
  return arry.map((bullet, i) => (<li key={i}>{bullet}</li>));
}

class CasePreview extends React.PureComponent {
  render() {
    const formData = this.props.document.formData || {};
    const pastMedical = formData.pastMedical || [];
    const video = formData.video || {};

    return (
      <Div>
        <h2>{formData.title}</h2>
        <p><b>Patient:</b> {formData.oneLiner}</p>
        <p><b>Chief Complaint:</b> {formData.complaint}</p>
        <p><small>{video.disclaimer}</small></p>
        {video.videoUrl ? (
          <ReactJWPlayer
            playerId="acquire_video_jwp"
            playerScript={`https://content.jwplatform.com/libraries/${this.props.videoLibraryId}.js`}
            file={video.videoUrl}
            customProps={{ playbackRateControls: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] }}
            image={video.imageUrl}
          />) : ''
        }

        <h3>History of Present Illness</h3>
        {formData.intro}

        <Tabs defaultActiveKey={1} id="tabs_hpi">
          <Tab eventKey={1} title="REVIEW OF SYSTEM">{formData.reviewOfSystem}</Tab>
          <Tab eventKey={2} title="PAST MEDICAL">
            {
              pastMedical.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <h4>{item.title}</h4>
                  <ul>{ listItems(item.history) }</ul>
                </div>
                ))
            }
          </Tab>
          <Tab eventKey={3} title="MEDS / ALLERGIES">
            <div><ul>{ listItems(formData.meds) }</ul></div>
            <div><ul>{ listItems(formData.allergies) }</ul></div>
          </Tab>
          <Tab eventKey={4} title="PHYSICAL EXAM">
            <CasePhysicalExamPreview physicalExam={formData.physicalExam} />
          </Tab>
          <Tab eventKey={5} title="SUMMARY">
            <CaseSummaryPreview
              summary={formData.summary}
              reviewOfSystem={formData.reviewOfSystem}
              pastMedical={formData.pastMedical}
              meds={formData.meds}
              allergies={formData.allergies}
              physicalExam={formData.physicalExam}
            />
          </Tab>
        </Tabs>

        <h3>EMR</h3>
        <CaseEmrPreview emrs={formData.emrs} />

        <h3>Questions</h3>
        <CaseQuestionPreview questions={formData.questions} />

        <h3>Key Points</h3>
        <ul>{ listItems(formData.keyPoints) }</ul>

        <CaseResourcePreview resources={formData.resources} />

      </Div>
    );
  }
}

CasePreview.propTypes = propTypes;
CasePreview.defaultProps = defaultProps;

export default connect()(CasePreview);
