import styled from 'styled-components';

import { CenteredButton } from '../../UI/Button/Button';

export const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  > * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  
  > div:first-of-type {
    width: 100%;
  }
`;

export const DeleteButton = styled.button`
  outline: 0;
  border: none;
  height: 40px;
  width: 40px;
  position: relative;
  display: block;
  background: transparent;

  &:after, &:before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 8px);
    height: 2px;
    width: 16px;
    transform: rotate(45deg);
    background: ${props => props.theme.color.form.border};
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const AddAnswer = styled.button`
  outline: 0;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 12px;
  padding: 6px 10px;
`;

export const ButtonContainer = styled(CenteredButton)`
  margin-top: 20px;
`;
