import React from 'react';
import PropTypes from 'prop-types';
import { Div, Input } from './plainTextStyles';

class PlainText extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.props.onChange({
      [this.props.name]: e.target.value,
    });
  }

  render() {
    const { value, label, disabled } = this.props;
    return (
      <Div>
        <label>{label}</label><br />
        <Input
          value={value}
          onChange={this.onChange}
          disabled={disabled}
        />
      </Div>
    );
  }
}

PlainText.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

PlainText.defaultProps = {
  value: '',
};

export default PlainText;
