import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem, Modal, Tab, Tabs, Button } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import SweetAlert from 'react-bootstrap-sweetalert';
// TODO replace with SweetAlert from react-bootstrap-sweetalert
import swal from 'sweetalert';
import { CaseEditorPanel, EditorBar, MainPage, CaseSubPanel } from './editorPanelStyles';
import CasePreviewer from '../Preview/case/casePreview';

import {
  cloneCase,
  deleteCase,
  fetchReelDxVideo,
  loadDocument,
  newDocument,
  resetSummary,
  postComment,
  saveDocument,
} from '../../actions/caseActions';
import { unsavedChanges } from '../../actions/navigationActions';
import FormMessage from './formMessage';
import ImageUploader from './case/imageUploader';
import ReelDxVideo from './case/reelDxVideo';
import Vitals from './case/vitals';
import LessonLink from './case/lesson';
import * as constants from '../../constants';
import { caseDefaults, widgets } from '../../schema/caseSchema_0.x.0';
import { debounce, deepClone } from '../../utils';

const propTypes = {
  apiToken: PropTypes.string.isRequired,
  documentId: PropTypes.number,
  meta: PropTypes.shape({
    slug: PropTypes.string,
    category_id: PropTypes.number,
    topics: PropTypes.arrayOf(PropTypes.number),
  }),
  saved: PropTypes.bool,
  document: PropTypes.instanceOf(Object),
  documentStatus: PropTypes.string,
  uploadProgress: PropTypes.number,
  uploads: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  metaSchema: PropTypes.instanceOf(Object).isRequired,
  uiSchema: PropTypes.instanceOf(Object).isRequired,
  panelObject: PropTypes.instanceOf(Object).isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
  saveDocument: PropTypes.func.isRequired,
  loadDocument: PropTypes.func.isRequired,
  newDocument: PropTypes.func.isRequired,
  resetSummary: PropTypes.func.isRequired,
  cloneCase: PropTypes.func.isRequired,
  deleteCase: PropTypes.func.isRequired,
  unsavedChanges: PropTypes.func.isRequired,
  videoLibraryId: PropTypes.string.isRequired,
  reelDxVideo: PropTypes.shape({
    id: PropTypes.number,
    videoUrl: PropTypes.string,
    posterImageUrl: PropTypes.string,
  }),
};

const defaultProps = {
  document: deepClone(caseDefaults.document),
  documentId: 0,
  meta: {
    slug: '',
    category_id: null,
    topics: [],
  },
  documentStatus: 'Status',
  reelDxVideo: {
    videoUrl: '',
    posterImageUrl: '',
  },
  uploadProgress: 0,
  saved: false,
};

const mapStateToProps = state => ({
  apiToken: state.user.user.api_token,
  documentId: state.navigation.id || 0,
  saved: state.cases.saved,
  meta: state.cases.meta,
  document: state.cases.document,
  documentStatus: state.cases.documentStatus,
  uploadProgress: state.cases.uploadProgress,
  schema: state.cases.schema,
  metaSchema: state.cases.metaSchema,
  uiSchema: state.cases.uiSchema,
  comments: state.cases.comments,
  errorReports: state.cases.errorReports,
  message: state.cases.message,
  panelObject: state.navigation.panelObject,
  videoLibraryId: state.navigation.videoLibraryId,
  uploads: state.cases.uploads,
  reelDxVideo: state.cases.reelDxVideo,
});

// number of react-json-schema forms that must be individually validated and combined before saving
const NUM_FORMS = 8;

class CaseEditor extends React.Component {
  constructor(props) {
    super(props);
    this.onFormDataChange = this.onFormDataChange.bind(this);
    this.onFormDataChange = debounce(this.onFormDataChange, 250);
    this.onMetaDataChange = this.onMetaDataChange.bind(this);
    this.onSaveAndValidate = this.onSaveAndValidate.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormValidated = this.onFormValidated.bind(this);
    this.onDismissValidationErrors = this.onDismissValidationErrors.bind(this);
    this.onTogglePreview = this.onTogglePreview.bind(this);
    this.onResetSummary = this.onResetSummary.bind(this);
    this.state = {
      activeTabKey: 0,
      errors: [],
      showValidationErrors: false,
      preview: false,
    };
    this.metaData = props.meta;
    this.document = props.document;
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.keysDown = {};
  }

  componentDidMount() {
    if (this.props.documentId) {
      this.props.loadDocument();
    } else {
      this.props.newDocument();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.document !== nextProps.document) {
      this.document = nextProps.document;
    }
    if (this.props.meta !== nextProps.meta) {
      this.metaData = nextProps.meta;
    }
  }

  onFormError(errors, eventKey) {
    const newErrors = [...this.state.errors, ...errors];
    this.setState({
      activeTabKey: eventKey,
      errors: newErrors,
      showValidationErrors: true,
    });
  }

  onTabSelect(key) {
    this.setState({ activeTabKey: key });
  }

  // TODO: move into its own component and share with editorPanel
  onOptionSelect(eventKey) {
    const { panelObject } = this.props;

    switch (eventKey) {
      case 'new':
        // eslint-disable-next-line no-console
        console.error('New options menu action is not implemented!');
        break;
      case 'clone':
        this.clone(panelObject, this.props.documentId);
        break;
      case 'delete':
        this.delete(panelObject, this.props.documentId);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(`${eventKey} not implemented! Add logic in caseEditor.js`);
    }
  }

  onMetaDataChange(form) {
    if (this.props.metaSchema.loaded) {
      this.metaData = form.formData;
      if (this.props.saved && document.activeElement) {
        this.props.unsavedChanges(true);
      }
    }
  }

  onFormDataChange(form) {
    this.document = { ...this.document, formData: form.formData };
    // guard against change events that are not generated by a form element
    if (this.props.saved && document.activeElement && document.activeElement.form) {
      this.props.unsavedChanges(true);
    }
  }

  onDismissValidationErrors() {
    this.setState({ showValidationErrors: false });
  }

  onChangeStatus(status) {
    this.setState({
      status,
    }, () => this.onSaveAndValidate());
  }

  onTogglePreview() {
    this.setState(prevState => ({
      preview: !prevState.preview,
    }));
  }

  onResetSummary() {
    this.props.resetSummary(this.document);
  }

  onSaveAndValidate() {
    this.setState({
      errors: [],
      formsValidated: 0,
    }, () => {
      // submitting each form seems to be the only way to force react-jsonschema-form validation
      this.metaSubmitButton.click();
      this.hpiSubmitButton.click();
      this.emrSubmitButton.click();
      this.questionsSubmitButton.click();
      this.kprSubmitButton.click();
      this.hpMinerSubmitButton.click();
      this.sticksSchemaSubmitButton.click();
      this.quizSubmitButton.click();
    });
  }

  onFormValidated() {
    this.setState(
      prevState => ({ formsValidated: prevState.formsValidated + 1 }),
      () => {
        if (this.state.errors.length === 0 && this.state.formsValidated === NUM_FORMS) {
          this.props.saveDocument(this.props.documentId, this.document.formData, this.state.status, this.metaData);
        }
      },
    );
  }

  delete(panelObject, documentId) {
    swal({
      title: `Deleting ${panelObject.panelSingularName}`,
      text: 'Are you sure you would like to delete?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No',
          closeModal: true,
          visible: true,
        },
        confirm: {
          text: 'Yes',
          closeModal: true,
          visible: true,
        },
      },
    })
      .then((value) => {
        if (value !== null) {
          this.props.deleteCase(documentId);
        }
      });
  }

  clone(panelObject, documentId) {
    swal({
      title: `Cloning ${panelObject.panelSingularName}`,
      text: 'Are you sure you would like to clone?',
      buttons: {
        cancel: {
          text: 'Cancel',
          closeModal: true,
          visible: true,
        },
        clone: {
          text: 'Submit',
          closeModal: false,
          visible: true,
        },
      },
    })
      .then((value) => {
        if (value !== null) {
          swal(`Cloning ${panelObject.panelSingularName}`);
          this.props.cloneCase(documentId);
        }
      });
  }

  handleKeyDown(e) {
    this.keysDown[e.keyCode] = e.type === 'keydown';
    // Check if ctrl + s are pressed down
    if (this.keysDown[17] && this.keysDown[83]) {
      this.onSaveAndValidate();
    }
  }

  handleKeyUp(e) {
    delete this.keysDown[e.keyCode];
  }

  renderValidationErrors() {
    if (!this.state.showValidationErrors) {
      return '';
    }
    return (
      <SweetAlert
        title="Missing Required Fields"
        type="warning"
        style={{ width: '800px', height: '500px', overflow: 'auto' }}
        onConfirm={this.onDismissValidationErrors}>
        <ul style={{ textAlign: 'left' }}>
          {/* eslint-disable-next-line react/no-array-index-key */}
          { this.state.errors.map((e, i) => (<li key={i}>{ e.message ? `${e.property} ${e.message}` : e.stack }</li>))}
        </ul>
      </SweetAlert>
    );
  }

  renderStatusDropdown() {
    // TODO: load these from content_statuses table
    const statuses = ['Not Reviewed', 'In Process', 'Approved'];
    return (
      <DropdownButton
        id="statusDropdown"
        bsStyle="default"
        title={this.props.documentStatus}
        onSelect={event => this.onChangeStatus(event)}>
        {statuses.map(status => <MenuItem eventKey={status} key={status}>{status}</MenuItem>)}
      </DropdownButton>
    );
  }

  renderOptionsDropdown() {
    const { panelObject } = this.props;
    const hasMenuOptions = panelObject.itemId && panelObject.settings &&
      panelObject.settings.options && panelObject.settings.options.length > 0;
    if (!hasMenuOptions) {
      return '';
    }
    return (
      <DropdownButton
        id="editorDropdownButton"
        bsStyle="default"
        title="Actions"
        onSelect={this.onOptionSelect}
      >
        {panelObject.settings.options.map(item => (
          <MenuItem
            disabled={item.disabled}
            eventKey={item.action}
            key={item.action}
          >{item.title}
          </MenuItem>
        ))}
      </DropdownButton>
    );
  }

  renderPreviewToggle() {
    return (
      <Button
        id="togglePreview"
        bsStyle="default"
        onClick={this.onTogglePreview}>Preview: {this.state.preview ? 'On' : 'Off'}
      </Button>
    );
  }

  renderUploadProgress() {
    if (!this.props.uploadProgress) {
      return null;
    }
    return (
      <Modal show>
        <Modal.Header>
          <Modal.Title>
            {this.props.uploadProgress === 100 ? 'Confirming Upload...' : `Uploading ${this.props.uploadProgress}%`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: `${this.props.uploadProgress}%`, backgroundColor: constants.BG_SUCCESS }}>&nbsp;</div>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    if (!(this.props.metaSchema.loaded && this.props.document.loaded)) {
      return (<div><h1>Loading...</h1></div>);
    }
    if (!this.document) {
      return (
        <div>
          <h1>404 Not Found</h1>
          <p>{this.props.message ? this.props.message.contents : ''}</p>
        </div>
      );
    }

    if (Object.keys(this.document).length === 0) {
      return null;
    }

    const { schema, metaSchema, uiSchema } = this.props;
    const hpiFields = {
      vitals: Vitals,
      imageUploader: ImageUploader,
      videoFields: ReelDxVideo,
    };
    const emrFields = {
      imageUploader: ImageUploader,
    };
    const questionFields = {
      lessonLink: LessonLink,
    };
    const formContext = {
      apiToken: this.props.apiToken,
      uploads: this.props.uploads,
      documentId: this.props.documentId,
      videoInputs: {
        videoUrl: this.props.reelDxVideo.videoUrl,
        posterImageUrl: this.props.reelDxVideo.posterImageUrl,
      },
    };

    const errorHandler = tabIndex => errors => this.onFormError(errors, tabIndex);

    const title = this.document && this.document.formData && this.props.meta.slug
      ? `${this.document.formData.title} (${this.props.meta.slug})`
      : 'New Case';

    let eventKey = 0;

    return (
      <MainPage onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp}>
        { this.renderUploadProgress() }
        { this.renderValidationErrors() }
        <h1>{title}</h1>
        <EditorBar>
          <FormMessage message={this.props.message} />

          <Button
            data-cy="case-form-submit"
            bsStyle={this.props.saved ? 'default' : 'primary'}
            onClick={this.onSaveAndValidate}>Save
          </Button>
          { this.renderStatusDropdown() }
          { this.renderOptionsDropdown() }
          { this.renderPreviewToggle() }
          {title === 'New Case' &&
            <span className="save-text">You can save at any time by pressing <strong><i>ctrl + s</i></strong></span>
          }
        </EditorBar>

        <CaseEditorPanel>
          <CaseSubPanel>
            <Tabs
              id="case-editor-tabs"
              defaultActiveKey={0}
              activeKey={this.state.activeTabKey}
              onSelect={this.onTabSelect}>

              <Tab eventKey={eventKey} key={eventKey} title="Meta">
                <Form
                  // DEV-1881 - fix topics/lessons not loading when case is opened via ctrl+click from overview
                  // https://github.com/mozilla-services/react-jsonschema-form/issues/446
                  safeRenderCompletion
                  schema={metaSchema.data}
                  uiSchema={metaSchema.ui}
                  formData={this.metaData}
                  onChange={this.onMetaDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="cases-meta-form-submit"
                    ref={(btn) => { this.metaSubmitButton = btn; }}
                    className="hidden" />
                </Form>
              </Tab>

              <Tab eventKey={eventKey} key={eventKey} title="HPI">
                <Form
                  schema={schema.hpiSchema}
                  uiSchema={uiSchema.hpi}
                  fields={hpiFields}
                  widgets={widgets}
                  formData={this.document.formData}
                  formContext={formContext}
                  onChange={this.onFormDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="cases-hpi-form-submit"
                    ref={(btn) => { this.hpiSubmitButton = btn; }}
                    className="hidden" />
                </Form>
                <button onClick={this.onResetSummary}>Reset Summary</button>
              </Tab>

              <Tab eventKey={eventKey} key={eventKey} title="EMR">
                <Form
                  schema={schema.emrSchema}
                  uiSchema={uiSchema.emr}
                  fields={emrFields}
                  widgets={widgets}
                  formData={this.document.formData}
                  formContext={formContext}
                  onChange={this.onFormDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="cases-emr-form-submit"
                    ref={(btn) => { this.emrSubmitButton = btn; }}
                    className="hidden" />
                </Form>
              </Tab>

              <Tab eventKey={eventKey} key={eventKey} title="Questions">
                <Form
                  schema={schema.questionSchema}
                  uiSchema={uiSchema.question}
                  fields={questionFields}
                  widgets={widgets}
                  formData={this.document.formData}
                  formContext={formContext}
                  onChange={this.onFormDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="cases-questions-form-submit"
                    ref={(btn) => { this.questionsSubmitButton = btn; }}
                    className="hidden" />
                </Form>
              </Tab>

              <Tab eventKey={eventKey} key={eventKey} title="KP&R">
                <Form
                  schema={schema.keyPointsAndResourcesSchema}
                  uiSchema={uiSchema.keyPoints_resources}
                  widgets={widgets}
                  formData={this.document.formData}
                  onChange={this.onFormDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="cases-kpr-form-submit"
                    ref={(btn) => { this.kprSubmitButton = btn; }}
                    className="hidden" />
                </Form>
              </Tab>

              <Tab eventKey={eventKey} key={eventKey} title="H&P Miner">
                <Form
                  schema={schema.hpMinerSchema}
                  uiSchema={uiSchema.hpMiner}
                  widgets={widgets}
                  formData={this.document.formData}
                  onChange={this.onFormDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="cases-hpminer-form-submit"
                    ref={(btn) => { this.hpMinerSubmitButton = btn; }}
                    className="hidden" />
                </Form>
              </Tab>

              <Tab eventKey={eventKey} key={eventKey} title="Lab Sticks">
                <Form
                  schema={schema.labSticksSchema}
                  uiSchema={uiSchema.labSticksUI}
                  widgets={widgets}
                  formData={this.document.formData}
                  onChange={this.onFormDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="sticks-schema-form-submit"
                    ref={(btn) => { this.sticksSchemaSubmitButton = btn; }}
                    className="hidden" />
                </Form>
              </Tab>

              <Tab eventKey={eventKey} key={eventKey} title="Quiz">
                <Form
                  schema={schema.quizSchema}
                  uiSchema={uiSchema.quiz}
                  widgets={widgets}
                  formData={this.document.formData}
                  onChange={this.onFormDataChange}
                  noHtml5Validate
                  onSubmit={this.onFormValidated}
                  onError={errorHandler(eventKey++)}>
                  <button
                    data-cy="quiz-schema-form-submit"
                    ref={(btn) => { this.quizSubmitButton = btn; }}
                    className="hidden" />
                </Form>
              </Tab>

            </Tabs>
          </CaseSubPanel>

          <CaseSubPanel style={{ display: this.state.preview ? 'block' : 'none' }}>
            <CasePreviewer
              document={this.document}
              videoLibraryId={this.props.videoLibraryId}
            />
          </CaseSubPanel>

        </CaseEditorPanel>
      </MainPage>
    );
  }
}

CaseEditor.propTypes = propTypes;
CaseEditor.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  {
    cloneCase,
    deleteCase,
    fetchReelDxVideo,
    loadDocument,
    newDocument,
    resetSummary,
    postComment,
    saveDocument,
    unsavedChanges,
  },
)(CaseEditor);
