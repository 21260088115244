import * as constants from '../constants';

function buildNotificationObject(panel) {
  return {
    panelId: panel.id,
    panelName: panel.name,
    panelSlug: panel.slug,
    count: panel.notifications,
  };
}

export default function reducer(state = {
  notificationObjects: {},
}, action) {
  switch (action.type) {
    case constants.FETCH_NAVIGATION_SUCCESS: {
      const notificationObjects = Object.assign({}, state.notificationObjects);
      const { property } = action.payload;
      const { panels } = action.payload.properties[property];
      for (let i = 0; i < panels.length; i++) {
        if (panels[i].notifications) {
          notificationObjects[panels[i].slug] = buildNotificationObject(panels[i]);
        }
      }

      return {
        ...state,
        notificationObjects,
      };
    }
    case constants.DECREMENT_NOTIFICATION: {
      const notificationObjects = Object.assign({}, state.notificationObjects);
      notificationObjects[action.payload].count--;
      return {
        ...state,
        notificationObjects,
      };
    }
  }

  return state;
}
