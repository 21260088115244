export const FROALA_KEY = process.env.MIX_FROALA_KEY || 'RA3E3B14B10iB6E5E4H5I2H3A7B6D6E5C-11nlomkcjmmooF4bC-22h==';

export const CASE_POST_COMMENT = 'CASE_POST_COMMENT';
export const CASE_POST_COMMENT_SUCCESS = 'CASE_POST_COMMENT_SUCCESS';
export const CASE_POST_COMMENT_ERROR = 'CASE_POST_COMMENT_ERROR';

export const CHANGE_PROPERTY = 'CHANGE_PROPERTY';

export const CLONE_DOCUMENT = 'CLONE_DOCUMENT';
export const CLONE_DOCUMENT_SUCCESS = 'CLONE_DOCUMENT_SUCCESS';
export const CLONE_DOCUMENT_ERROR = 'CLONE_DOCUMENT_ERROR';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

export const FETCH_DATA_LIST = 'FETCH_DATA_LIST';
export const FETCH_DATA_LIST_SUCCESS = 'FETCH_DATA_LIST_SUCCESS';
export const FETCH_DATA_LIST_ERROR = 'FETCH_DATA_LIST_ERROR';

export const FETCH_LESSONS = 'FETCH_LESSONS';
export const FETCH_LESSONS_SUCCESS = 'FETCH_LESSONS_SUCCESS';
export const FETCH_LESSONS_ERROR = 'FETCH_LESSONS_ERROR';

export const FETCH_NAVIGATION = 'FETCH_NAVIGATION';
export const FETCH_NAVIGATION_SUCCESS = 'FETCH_NAVIGATION_SUCCESS';
export const FETCH_NAVIGATION_ERROR = 'FETCH_NAVIGATION_ERROR';

export const FETCH_SINGLE = 'FETCH_SINGLE';
export const FETCH_SINGLE_SUCCESS = 'FETCH_SINGLE_SUCCESS';
export const FETCH_SINGLE_ERROR = 'FETCH_SINGLE_ERROR';

export const FETCH_MONTHLY = 'FETCH_MONTHLY';
export const FETCH_MONTHLY_SUCCESS = 'FETCH_MONTHLY_SUCCESS';
export const FETCH_MONTHLY_ERROR = 'FETCH_MONTHLY_ERROR';

export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'FETCH_ALL_SUCCESS';
export const FETCH_ALL_ERROR = 'FETCH_ALL_ERROR';

export const FETCH_MRR = 'FETCH_MRR';
export const FETCH_MRR_SUCCESS = 'FETCH_MRR_SUCCESS';
export const FETCH_MRR_ERROR = 'FETCH_MRR_ERROR';

export const FETCH_TOTAL_AVERAGE_LTV = 'FETCH_TOTAL_AVERAGE_LTV';
export const FETCH_TOTAL_AVERAGE_LTV_SUCCESS = 'FETCH_TOTAL_AVERAGE_LTV_SUCCESS';
export const FETCH_TOTAL_AVERAGE_LTV_ERROR = 'FETCH_TOTAL_AVERAGE_LTV_ERROR';

export const FETCH_FIRST_PURCHASE_LTV = 'FETCH_FIRST_PURCHASE_LTV';
export const FETCH_FIRST_PURCHASE_LTV_SUCCESS = 'FETCH_FIRST_PURCHASE_LTV_SUCCESS';
export const FETCH_FIRST_PURCHASE_LTV_ERROR = 'FETCH_FIRST_PURCHASE_LTV_ERROR';

export const FETCH_REEL_DX_VIDEO = 'FETCH_REEL_DX_VIDEO';
export const FETCH_REEL_DX_VIDEO_SUCCESS = 'FETCH_REEL_DX_VIDEO_SUCCESS';
export const FETCH_REEL_DX_VIDEO_ERROR = 'FETCH_REEL_DX_VIDEO_ERROR';

export const NEW_DOCUMENT = 'NEW_DOCUMENT';
export const RESET_SUMMARY = 'RESET_SUMMARY';

export const LOAD_DOCUMENT = 'LOAD_DOCUMENT';
export const LOAD_DOCUMENT_SUCCESS = 'LOAD_DOCUMENT_SUCCESS';
export const LOAD_DOCUMENT_ERROR = 'LOAD_DOCUMENT_ERROR';

export const MODEL_POST_COMMENT = 'MODEL_POST_COMMENT';
export const MODEL_POST_COMMENT_SUCCESS = 'MODEL_POST_COMMENT_SUCCESS';
export const MODEL_POST_COMMENT_ERROR = 'MODEL_POST_COMMENT_ERROR';

export const NEW_MODEL = 'NEW_MODEL';
export const NEW_MODEL_SUCCESS = 'NEW_MODEL_SUCCESS';
export const NEW_MODEL_ERROR = 'NEW_MODEL_ERROR';

export const RESOLVE_ERROR_REPORT = 'RESOLVE_ERROR_REPORT';
export const RESOLVE_ERROR_REPORT_SUCCESS = 'RESOLVE_ERROR_REPORT_SUCCESS';
export const RESOLVE_ERROR_REPORT_ERROR = 'RESOLVE_ERROR_REPORT_ERROR';

export const SAVE_DOCUMENT = 'SAVE_DOCUMENT';
export const SAVE_DOCUMENT_SUCCESS = 'SAVE_DOCUMENT_SUCCESS';
export const SAVE_DOCUMENT_ERROR = 'SAVE_DOCUMENT_ERROR';

export const UPDATE_NAVIGATION_PARAMETERS = 'UPDATE_NAVIGATION_PARAMETERS';

export const UPDATE_PANEL_OBJECT = 'UPDATE_PANEL_OBJECT';

export const UPDATE_MODEL_STATUS = 'UPDATE_MODEL_STATUS';
export const UPDATE_MODEL_STATUS_SUCCESS = 'UPDATE_MODEL_STATUS_SUCCESS';
export const UPDATE_MODEL_STATUS_ERROR = 'UPDATE_MODEL_STATUS_ERROR';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

export const UNSAVED_CHANGES_WARNING = 'UNSAVED_CHANGES_WARNING';

export const BG_SUCCESS = '#22A84F';
export const BG_DANGER = '#ff0000';
export const BG_WHITE = '#ffffff';

export const DECREMENT_NOTIFICATION = 'DECREMENT_NOTIFICATION';

export const AUDITION_APPROVE = 'AUDITION_APPROVE';
export const AUDITION_APPROVE_SUCCESS = 'AUDITION_APPROVE_SUCCESS';
export const AUDITION_APPROVE_ERROR = 'AUDITION_APPROVE_ERROR';

export const AUDITION_DENY = 'AUDITION_DENY';
export const AUDITION_DENY_SUCCESS = 'AUDITION_DENY_SUCCESS';
export const AUDITION_DENY_ERROR = 'AUDITION_DENY_ERROR';

/**
 * Actions for Redux Form reducer plugins
 */
export const QBANK_FORM_SAVE_ID_TO_QUESTION = 'QBANK_FORM_SAVE_ID_TO_QUESTION';
export const QBANK_FORM_SAVE_ID_TO_QUESTION_MATCHING = 'QBANK_FORM_SAVE_ID_TO_QUESTION_MATCHING';
export const QBANK_SAVE_QUESTION_TO_STORE = 'QBANK_SAVE_QUESTION_TO_STORE';
export const DESTROY_CURRENT_QUESTION_IN_STORE = 'DESTROY_CURRENT_QUESTION_IN_STORE';
