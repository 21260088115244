import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
/* froala BEGIN */
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/special_characters.min.css';
import 'font-awesome/css/font-awesome.css';
import FroalaEditor from 'react-froala-wysiwyg';
/* froala END */

import { Div } from './textStyles';
import { FROALA_KEY } from '../../../constants';

const propTypes = {
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  apiToken: PropTypes.string,
};

const defaultProps = {
  model: { id: 0 },
  value: '',
  label: '',
  disabled: false,
  apiToken: null,
};

// TODO: pass this via context once we upgrade to react 16
const mapStateToProps = state => ({
  apiToken: state.user.user.api_token,
});

const toolbarButtons = ['fullscreen', 'bold', 'italic', 'underline', 'subscript', 'superscript', '|',
  'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-',
  'specialCharacters', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertTable', '|',
  'fontAwesome', 'specialCharacters', 'clearFormatting', '|',
  'spellChecker', 'help', 'html', '|', 'undo', 'redo'];

class Text extends React.Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const dropdownList = document.getElementsByClassName('ql-picker-label');
    for (let i = 0; i < dropdownList.length; i++) {
      dropdownList[i].setAttribute('tabIndex', -1);
    }
    const list = document.getElementsByClassName('ql-formats');
    for (let i = 0; i < list.length; i++) {
      const buttonList = list[i].getElementsByTagName('BUTTON');
      for (let j = 0; j < buttonList.length; j++) {
        buttonList[j].setAttribute('tabIndex', -1);
      }
    }
  }

  onChange(value) {
    this.props.onChange({
      [this.props.name]: value,
    });
  }

  render() {
    const {
      value,
      label,
      disabled,
      model,
    } = this.props;

    const froalaConfig = {
      key: FROALA_KEY,
      toolbarButtons,
      imageUploadURL: `/api/v1/froala/file/qbank?api_token=${this.props.apiToken}`,
      // Support up to 11MB that way content team can actually upload 10MB files
      imageMaxSize: 11 * 1024 * 1024,
      imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
      imageUploadParams: { model_id: model.id },
      videoUpload: false,
      wordPasteModal: false,
      wordPasteKeepFormatting: false,
    };
    return (
      <Div>
        <label>{label}</label><br />
        { disabled
        /* eslint-disable-next-line react/no-danger */
        ? <p dangerouslySetInnerHTML={{ __html: value }} />
        : <FroalaEditor
          config={froalaConfig}
          onModelChange={this.onChange}
          model={value} />
        }
      </Div>
    );
  }
}

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default connect(mapStateToProps)(Text);
