import styled from 'styled-components';

export const Main = styled.div`
    position: fixed;
    bottom: 0;
    background: white;
    width: 90%;
    ${props => (props.isMinimized ? 'height: 50px' : `height: ${props.height}px`)};
    ${props => (props.isResizing ? 'transition: none' : 'transition: height 0.5s ease-out')};
    margin-left: 5%;
    box-shadow: 0px 0px 5px 1px #ccc;
`;

export const Toolbar = styled.div`
    width: 100%;
    display: inline-block;
    border-bottom: solid 1px #ddd;
    background: #fbfbfb;
`;

export const Tabs = styled.ul`
    margin-bottom: 0;
    margin-top: 5px;
    margin-left: 5px;
    padding-left: 0;
    list-style: none;

    display: flex;
`;

export const Tab = styled.li`
    float: left;
    background: #fbfbfb;
    position: relative;
    margin-bottom: -1px;

    border-radius: 4px 4px 0 0;
    border: 1px solid transparent;
    border-bottom-color: #ddd;

    padding: 10px 15px;

    &:hover {
        background: #f0f0f0;
        cursor: pointer;
    }

    &:focus {
        outline: 0
    }

    ${(props) => {
    if (props.isSelected) {
      return `
                border: solid 1px #ddd;
                border-bottom-color: transparent;
                background: #fff;
            `;
    }
    return '';
  }}

    a {
        position: relative;

        color: #3097D1;
        text-decoration: none;
        background-color: transparent;
    }

    span {
        margin-left: 15px
        padding: 5px;
        color: #888;

        &:hover {
            color: #000;
            border-radius: 4px;
            background: #ccc;
        }
    }
`;

export const Controls = styled.div`
    position: absolute;
    top: 14px;
    right: 16px;

    a:hover {
        cursor: pointer;
    }
`;

export const Panel = styled.div`
    margin: 35px;
`;

export const ResizeBar = styled.div`
    position: absolute;
    background: transparent;
    height: 5px;
    width: 100%;
    cursor: ns-resize;
`;

export const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    &:focus {
        outline: none;
    }
`;
