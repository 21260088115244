export default {
  color: {
    brand: {
      main: '#00BBE5',
      dark: '#002D37',
      error: '#FD7F7C',
      titleBlack: '#333333',
    },
    form: {
      label: '#595959',
      placeholder: '#999999',
      input: '#595959',
      border: '#CCCCCC',
      disabled: '#F6F6F6',
      highlight: '#F9F9F9',
    },
  },
  size: {
    input: 40,
  },
  padding: {
    input: '14px 20px',
  },
};
