import styled from 'styled-components';

export const Wrapper = styled.div`
  .navbar-brand {
    margin-top: 5px;
  }
  .dropdown {
    margin-top: 5px;
  }
`;

export const NavTitle = styled.div`
  display: inline-block;

  .badge {
    background-color: #bf5329;
    margin-left: 5px;
  }
`;
