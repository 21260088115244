import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis,
  Tooltip, CartesianGrid, BarChart, Bar, LabelList, Label,
} from 'recharts';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { fetchAll, fetchSingle } from '../../actions/analyticActions';
import { CenterRow, TooltipDiv, FloatRight, NotFoundDiv, PaddingBottom } from './styles';
import Loading from '../Loading';

const mapStateToProps = state => ({
  analytic: state.analytic,
  panelObject: state.navigation.panelObject,
});

class Order extends React.Component {
  static tooltip(data) {
    if (data && data.payload && typeof data.payload[0] !== 'undefined') {
      const { payload } = data.payload[0];
      const revenue = payload.revenue &&
        payload.revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return (
        <TooltipDiv>
          {payload.name && <strong>{payload.name}</strong>}
          <li>Sold: <FloatRight>{payload.count && payload.count.toLocaleString('en-US')}</FloatRight></li>
          <li>Revenue: <FloatRight>{revenue}</FloatRight></li>
          {payload.date && <li>Date: <FloatRight>{payload.date}</FloatRight></li>}
        </TooltipDiv>
      );
    }
    return null;
  }


  /* TODO: uncomment once monthly is fixed
  static renderBars(data) {
    // Unique plan keys.
    // We need to keep track of all unique keys to be able to render
    // the bars correctly.
    const barKeys = [];

    // Looping through the data then the keys of each data item.
    // We look for unique keys based on barKeys and add them if they are unique.
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'name' && !barKeys.includes(key)) {
          barKeys.push(key);
        }
      });
    });

    const result = [];
    // Looping through unique data keys and render a Bar component for each.
    barKeys.forEach((key) => {
      const color = '#000000'.replace(/0/g, () => (Math.floor((Math.random() * 16))).toString(16));
      result.push((
        <Bar
          key={`${key}`}
          dataKey={key}
          stackId="a"
          fill={color}
        />
      ));
    });

    return result;
  }
  */

  constructor() {
    super();
    this.state = {
      startDate: moment().subtract(5, 'days'),
      endDate: moment(),
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.getSingle = this.getSingle.bind(this);
    this.renderBarChart = this.renderBarChart.bind(this);
    this.renderLineChart = this.renderLineChart.bind(this);
  }

  componentDidMount() {
    this.getAll();
    this.getSingle('random');
  }

  getAll() {
    this.props.fetchAll(this.props.panelObject.component, {
      from: this.state.startDate.format('YYYY-MM-DD'),
      to: this.state.endDate.format('YYYY-MM-DD'),
    });
  }

  getSingle(event) {
    const slug = event !== 'random' ? event.target.value : event;
    this.props.fetchSingle(this.props.panelObject.component, slug, {
      from: this.state.startDate.format('YYYY-MM-DD'),
      to: this.state.endDate.format('YYYY-MM-DD'),
    });
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    }, () => {
      this.getAll();
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    }, () => {
      this.getAll();
    });
  }

  renderBarChart() {
    if (this.props.analytic.all.length === 0) {
      return <NotFoundDiv>Nothing to display</NotFoundDiv>;
    }
    return (
      <BarChart data={this.props.analytic.all} margin={{ top: 25 }} >
        <XAxis dataKey="count" tick={{ fill: '#28a745' }} height={50}>
          <Label value="Order Type" position="insideBottom" />
        </XAxis>
        <YAxis>
          <Label value="Sold" position="insideLeft" angle={-90} />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={Order.tooltip} />
        <Bar dataKey="count" fill="#17a2b8" barGap={5}>
          <LabelList dataKey="name" position="top" />
        </Bar>
      </BarChart>
    );
  }

  renderLineChart() {
    if (this.props.analytic.single.length === 0) {
      return (<NotFoundDiv>Nothing to display</NotFoundDiv>);
    }
    return (
      <LineChart data={this.props.analytic.single}>
        <XAxis dataKey="date" height={100} tick={{ angle: -45 }} textAnchor="end" />
        <YAxis>
          <Label value="Sold" position="insideLeft" angle={-90} />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={Order.tooltip} />
        <Line type="monotone" dataKey="count" fill="#17a2b8" stroke="#17a2b8">
          <LabelList dataKey="name" position="top" />
        </Line>
      </LineChart>
    );
  }

  render() {
    const revenue = this.props.analytic.revenue &&
      this.props.analytic.revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    return (
      <div>

        {/* ALL */}
        <CenterRow>
          <h1>{this.props.panelObject.name}</h1>
          <h3>Sold: {this.props.analytic.sold && this.props.analytic.sold.toLocaleString('en-US')}</h3>
          <h3>Revenue: {revenue}</h3>
          <div>
            From: <DatePicker
              dropdownMode="select"
              selected={this.state.startDate}
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChangeStart}
              showMonthDropdown
              scrollableYearDropdown
              showYearDropdown
            />
          </div>
          <div>
            To: <DatePicker
              dropdownMode="select"
              selected={this.state.endDate}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChangeEnd}
              showMonthDropdown
              scrollableYearDropdown
              showYearDropdown
            />
          </div>
        </CenterRow>
        <CenterRow>
          <ResponsiveContainer width="100%" height={500}>
            {this.props.analytic.fetchingAll
              ? <Loading />
              : this.renderBarChart()
            }
          </ResponsiveContainer>
        </CenterRow>
        <PaddingBottom />
        {/* Single */}
        <CenterRow>
          <FormGroup controlId="formControlsSelect">
            <ControlLabel>Order:</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              onChange={this.getSingle}
            >
              {this.props.analytic.all &&
               this.props.analytic.all.map(order => <option key={order.slug} value={order.slug}>{order.name}</option>)}
            </FormControl>
            Showing: {this.props.analytic.singleName}
          </FormGroup>
        </CenterRow>
        <CenterRow>
          <ResponsiveContainer width="100%" height={500}>
            {this.props.analytic.fetchingSingle
              ? <Loading />
              : this.renderLineChart()
            }
          </ResponsiveContainer>
        </CenterRow>

        {/* Monthly - Commenting out until fixed  */}
        {/* <CenterRow>
          <h1>Last 12 Months</h1>
        </CenterRow>
        <CenterRow>
          <ResponsiveContainer width="100%" height={500}>
            {this.props.analytic.fetchingMonthly ?
              <Loading />
              : this.props.analytic.monthly.length > 0 ?
                <BarChart data={this.props.analytic.monthly} margin={{ top: 25 }}>
                  <XAxis type="category" dataKey="name" />
                  <YAxis type="number" />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  {this.renderBars(this.props.analytic.monthly)}
                </BarChart>
              : <NotFoundDiv>Nothing to display</NotFoundDiv>
            }
          </ResponsiveContainer>
        </CenterRow> */}

      </div>
    );
  }
}

Order.propTypes = {
  fetchAll: PropTypes.func.isRequired,
  fetchSingle: PropTypes.func.isRequired,
  panelObject: PropTypes.shape({
    component: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  analytic: PropTypes.shape({
    all: PropTypes.array.isRequired,
    single: PropTypes.array.isRequired,
    singleName: PropTypes.string.isRequired,
    monthly: PropTypes.array.isRequired,
    sold: PropTypes.number,
    revenue: PropTypes.number,
    fetchingAll: PropTypes.bool.isRequired,
    fetchingSingle: PropTypes.bool.isRequired,
    fetchingMonthly: PropTypes.bool.isRequired,
  }),
};

Order.defaultProps = {
  analytic: {
    sold: 0,
    revenue: 0,
  },
};

export default connect(
  mapStateToProps,
  {
    fetchAll,
    fetchSingle,
  },
)(Order);
