import styled from 'styled-components';

export const Div = styled.div`
  label {
    display: block;

    input {
      margin-right: 10px;
    }
  }

  * img {
    display: block;
  }
`;

export const AnswersList = styled.div`
  margin: 10px 0 20px 0;
`;

export const Explanation = styled.p`
  table {
    border: 0;
    border-collapse: collapse;
    empty-cells: show;
    max-width: 100%;

    thead {
      display: table-header-group;
    }

    tbody {
      display: table-row-group;
    }

    tbody, thead {
      vertical-align: middle;
      border-color: inherit;
    }
  }

  th {
    background: #e6e6e6;
  }

  td {
    border: 1px solid #ddd;
  }
`;
