import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { fetchUser } from '../../actions/userActions';
import { fetchDataList } from '../../actions/overviewTableActions';

import structure from './structures';
import {
  MainPage,
  TextLink,
} from './styles';

const mapStateToProps = state => ({
  user: state.user.user,
  items: state.dataList.data,
  isDataFetched: state.dataList.fetched,
  params: state.dataList.params,
  navigation: state.navigation.data,
  panelObject: state.navigation.panelObject,
  isNavFetched: state.navigation.fetched,
});

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.updateTable = this.updateTable.bind(this);
    this.fetchRecords = this.fetchRecords.bind(this);
    this.fetchColumnStructure = this.fetchColumnStructure.bind(this);
    this.fetchColumnStructureOptions = this.fetchColumnStructureOptions.bind(this);
    this.onSelectedStructureChange = this.onSelectedStructureChange.bind(this);
    this.state = {
      filterContents: {},
      selectedColumnStructureKey: this.fetchDefaultColumnStructureKey(),
    };
  }

  componentDidUpdate(prevProps) {
    const {
      isDataFetched,
      match: {
        params: {
          panelSlug,
        },
      },
    } = this.props;
    if (isDataFetched && prevProps.panelObject.panelSlug !== panelSlug) {
      this.fetchRecords();
    }
  }

  onSelectedStructureChange(e) {
    this.setState({ selectedColumnStructureKey: e.target.value });
  }

  hasMultipleColumnStructures() {
    return Array.isArray(structure(this.props.panelObject.panelSlug, this.props.match.params.property)) === false;
  }

  fetchColumnStructure() {
    const overviewColumnStructure = structure(this.props.panelObject.panelSlug, this.props.match.params.property);
    // Multiple overview views, will render dropdown for user to choose a view from.
    if (this.hasMultipleColumnStructures() &&
        this.state.selectedColumnStructureKey) {
      if (overviewColumnStructure[this.state.selectedColumnStructureKey]) {
        return overviewColumnStructure[this.state.selectedColumnStructureKey];
      }

      // selectedColumnStructureKey doesn't exist in columnStructure,
      // set to default for this columnStructure object.
      this.setState({ selectedColumnStructureKey: this.fetchDefaultColumnStructureKey() });
      return [];
    }

    // Not multiple column structure, return column structure as before.
    return overviewColumnStructure;
  }

  fetchColumnStructureOptions() {
    if (this.hasMultipleColumnStructures()) {
      return Object.keys(structure(
        this.props.panelObject.panelSlug,
        this.props.match.params.property,
      )).filter(value => value !== 'default');
    }

    return false;
  }

  fetchDefaultColumnStructureKey() {
    const overviewColumnStructure = structure(this.props.panelObject.panelSlug, this.props.match.params.property);
    if (this.hasMultipleColumnStructures()) {
      // Checking if overview structure object has structure with the default key
      if (overviewColumnStructure[overviewColumnStructure.default]) {
        return overviewColumnStructure.default;
      }

      const errorMessage = `Assigned default '${overviewColumnStructure.default}' doesn't exist in column structure` +
      `for ${this.props.panelObject.panelSlug}. Please ensure default is set to an existing custom column structure` +
      `for ${this.props.panelObject.panelSlug}.`;
      throw new Error(errorMessage);
    }

    return '';
  }

  fetchRecords(tableState = {}) {
    this.props.fetchDataList(this.props.match.params.panelSlug, tableState);
  }

  updateTable(tableState) {
    // TODO: what's this delay about?
    const delay = 1000;
    if (tableState.filtered && tableState.filtered.length > 0 && tableState.filtered !== this.state.filterContents) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.fetchRecords(tableState), delay);
    } else {
      this.fetchRecords(tableState);
    }
  }

  panelUrl(panelObject) {
    return `/${this.props.match.params.property}/${panelObject.panelSlug}/editor`;
  }

  /* eslint-disable no-param-reassign */
  render() {
    const {
      items, isDataFetched, params, panelObject,
    } = this.props;
    let renderedItems = [];

    if (isDataFetched && Array.isArray(items)) {
      // Loop over items and replace approved by id with first and last name of actual person
      renderedItems = items.map((item) => {
        if (item.approved_by_user) {
          item.approved_by = `${item.approved_by_user.first_name} ${item.approved_by_user.last_name}`;
        } else {
          item.approved_by = '';
        }

        if (!item.created_by_name) {
          item.created_by_name = `"Legacy ${panelObject.panelSingularName}"`;
        }

        return item;
      });
    }
    /* eslint-enable no-param-reassign */

    return (
      <MainPage>
        <h1>{panelObject.panelName} Overview</h1>
        <div style={{ textAlign: 'right' }}>
          { panelObject.panelName === 'Questions' &&
            <TextLink>
              <a href="/api/v1/propertyApi/error-reports">Download Error Report</a>
            </TextLink>
          }
          { panelObject.settings && panelObject.settings.can_create_models &&
            <Link to={this.panelUrl(panelObject)}>
              <button className="btn btn-primary">
                New {panelObject.panelSingularName}
              </button>
            </Link>
          }
        </div>
        <br />
        {this.fetchColumnStructureOptions() &&
          <div>
            <label style={{ paddingRight: '10px' }}>View:</label>
            <select onChange={this.onSelectedStructureChange} value={this.state.selectedColumnStructureKey}>
              {this.fetchColumnStructureOptions().map(value => (<option>{value}</option>))}
            </select>
          </div>
        }
        <ReactTable
          filterable
          data={renderedItems}
          columns={this.fetchColumnStructure()}
          manual
          pages={params.pages}
          loading={params.loading} // Display the loading overlay when we need it
          onFetchData={this.updateTable}
          className="-striped -highlight"
          property={this.props.match.params.property}
        />
      </MainPage>
    );
  }
}

Overview.propTypes = {
  fetchDataList: PropTypes.func.isRequired,
  panelObject: PropTypes.shape({
    panelSlug: PropTypes.string,
    panelName: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      property: PropTypes.string,
      panelSlug: PropTypes.string,
      component: PropTypes.string,
    }),
  }).isRequired,
  isDataFetched: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    loading: PropTypes.bool,
    from: PropTypes.number,
    to: PropTypes.number,
    total: PropTypes.number,
    totalErrors: PropTypes.number,
    newQuestions: PropTypes.number,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default connect(
  mapStateToProps,
  {
    fetchUser,
    fetchDataList,
  },
)(Overview);
