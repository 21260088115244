import styled from 'styled-components';

export const Div = styled.div`
  margin: 30px 0;
`;

export const Textarea = styled.textarea`
  width: 93%;
  max-width: 100%;
  min-width: 200px;
  height: 100px;
  max-height: 200px;
  min-height: 50px;
`;

export const Buttons = styled.div`
  margin: 15px 0;

  button {
    margin-right: 20px;
    width: 110px;
  }
`;

export const StatusChangedMessage = styled.p`

`;
