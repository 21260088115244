import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../history';

import AdminNav from './Navigation';
import AdminNavV2 from './Navigation/v2/Navigation';
import Home from './Home';
import Panel from './Panel';
import Window from './Window';

import Qbank from './Qbank/Qbank';

class App extends React.Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <div>
            <Route render={(routeProps) => {
              if (!routeProps.location.pathname.startsWith('/qbank')) {
                return <AdminNav currentLocation={routeProps.location.pathname} />;
              }
              return (
                <AdminNavV2 />
              );
            }} />
            <Switch>
              <Route path="/qbank" component={Qbank} />
              <Route exact path="/" component={Home} />
              <Route exact path="/:property/:panelSlug/:component/:id?" component={Panel} />
            </Switch>
            <Window />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
