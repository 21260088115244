import { QBANK_FORM_SAVE_ID_TO_QUESTION } from '../../../constants';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case QBANK_FORM_SAVE_ID_TO_QUESTION: {
      return {
        ...state,
        values: {
          ...state.values,
          /**
           * Constructing a new array from the old one, only modifying the question that was saved
           */
          questions: [
            ...state.values.questions.slice(0, action.payload.questionIndex),
            {
              /**
               * Question that was saved.
               */
              ...state.values.questions[action.payload.questionIndex],
              id: action.payload.questionId,
            },
            ...state.values.questions.slice(action.payload.questionIndex + 1, state.values.questions.length),
          ],
        },
      };
    }
  }

  return state;
}
