export default function () {
  return {
    id: 'new',
    comments: [],
    data: {
      track_id: {
        data: 2,
        available: true,
      },
      category_id: {
        data: null,
        available: true,
      },
      topic_id: {
        data: null,
        available: true,
      },
      physician_tasks: {
        data: null,
        available: true,
      },
      educational_objective: {
        data: null,
        available: true,
      },
      vignette: {
        data: null,
        available: true,
      },
      interrogatory: {
        data: null,
        available: true,
      },
      Answer1: {
        data: null,
        available: true,
      },
      Answer2: {
        data: null,
        available: true,
      },
      Answer3: {
        data: null,
        available: true,
      },
      Answer4: {
        data: null,
        available: true,
      },
      Answer5: {
        data: null,
        available: true,
      },
      Answer6: {
        data: null,
        available: true,
      },
      Answer7: {
        data: null,
        available: true,
      },
      Answer8: {
        data: null,
        available: true,
      },
      correctanswer: {
        data: null,
        available: true,
      },
      explanation: {
        data: null,
        available: true,
      },
      teachingpoint: {
        data: null,
        available: true,
      },
      source: {
        data: null,
        available: true,
      },
      status: {
        data: 'Unreviewed',
        available: true,
      },
      reviewed_by: {
        data: null,
        available: true,
      },
      reviewed_at: {
        data: null,
        available: true,
      },
      created_by: {
        data: null,
        available: false,
      },
      approved_by: {
        data: null,
        available: true,
      },
      approved_at: {
        data: null,
        available: true,
      },
      created_at: {
        data: null,
        available: true,
      },
      updated_at: {
        data: null,
        available: true,
      },
      // question_type_id: {
      //   data: null,
      //   available: true,
      // },
      show_on_challenge: {
        data: false,
        available: true,
      },
      show_on_qbanks: {
        data: false,
        available: true,
      },
    },
  };
}
