import 'react-select/dist/react-select.js';

import Text from './text';
import TextArea from './textArea';
import PlainText from './plainText';
import Input from './input';
import Select from './select';
import Status from './status';
import Comments from './comments';
import ErrorReports from './errorReports';
import Checkbox from './checkbox';
import CheckboxList from './checkboxList';

const matrix = {
  text: Text,
  plainText: PlainText,
  textArea: TextArea,
  input: Input,
  select: Select,
  status: Status,
  comments: Comments,
  errorReports: ErrorReports,
  checkbox: Checkbox,
  checkboxList: CheckboxList,
};

export default function (key) {
  const result = matrix[key];
  if (!result) {
    const msg = 'Field component does not exist! ' +
      'Please add field component to Form/fieldComponents ' +
      'and include the new field component in Form/fieldComponents/index.js';
    throw new Error(msg);
  }

  return result;
}
