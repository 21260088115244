import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Flashcard, Topic, Lesson, Question, Footer,
  Button, Answer, Image, Front, Back, Container,
} from './flashcardStyles';

const props = state => ({
  params: state.model.params,
});

class FlashcardPreviewer extends React.Component {
  constructor() {
    super();
    this.state = {
      flipped: false,
    };
    this.onToggleClick = this.onToggleClick.bind(this);
  }
  onToggleClick() {
    this.setState({
      flipped: !this.state.flipped,
    });
  }
  getParamLabel(listName, id) {
    const { params } = this.props;

    for (let i = 0; i < params[listName].length; i++) {
      if (params[listName][i].id === id) {
        return params[listName][i].label;
      }
    }
    return null;
  }

  render() {
    const { data } = this.props.model.data;

    return (
      <Container>
        <Flashcard flipped={this.state.flipped}>
          <Front>
            <Topic>{this.getParamLabel('topics', data.category_id.data)}</Topic>
            <Lesson>{this.getParamLabel('lessons', data.topic_id.data)}</Lesson>
            <Question>{data.question.data}</Question>
            { data.imageurl.data ? <Image src={data.imageurl.data} /> : null }
            <Footer>
              <Button onClick={this.onToggleClick}>See Answer</Button>
            </Footer>
          </Front>
          <Back>
            <Answer>{data.answer.data}</Answer>
            <Footer>
              <Button onClick={this.onToggleClick}>See Question</Button>
            </Footer>
          </Back>
        </Flashcard>
      </Container>
    );
  }
}

FlashcardPreviewer.propTypes = {
  model: PropTypes.instanceOf(PropTypes.object).isRequired,
  params: PropTypes.instanceOf(PropTypes.object).isRequired,
};

export default connect(
  props,
  {},
)(FlashcardPreviewer);
