import React from 'react';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

export default property => ([
  {
    Header: 'ID',
    accessor: 'id',
    width: 50,
    Cell: id => (<Link to={`/${property}/admin-users/editor/${id.value}`}>{id.value}</Link>),
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['id'] }),
    filterAll: true,
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
    width: 200,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
    width: 200,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 500,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
  },
]);
