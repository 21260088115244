import Questions from './questions';
import Flashcards from './flashcards';
import AdminUsers from './adminUsers';
import Auditions from './auditions';
import Cases from './cases';

const matrix = {
  questions: Questions,
  flashcards: Flashcards,
  'admin-users': AdminUsers,
  auditions: Auditions,
  cases: Cases,
};

export default function (key, property) {
  const result = matrix[key](property);
  if (!result) {
    // eslint-disable-next-line no-console
    console.error('Overview Structure does not exist! Please add overview structure to Overview/structures/index.js');
  }

  return result;
}
