import React from 'react';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

export default property => ([
  {
    Header: 'ID',
    accessor: 'id',
    width: 50,
    Cell: (id) => {
      if (id.original.question_type_id === 2) return (<Link to={`/qbank/embedded/${id.value}`}>{id.value}</Link>);
      if (id.original.question_type_id === 3) return (<Link to={`/qbank/matching/${id.value}`}>{id.value}</Link>);
      return (<Link to={`/${property}/questions/editor/${id.value}`}>{id.value}</Link>);
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['id'] }),
    filterAll: true,
  },
  {
    Header: 'Type',
    accessor: 'question_type',
    Cell: (item) => {
      if (item.value) {
        return item.value.name;
      }
      return 'Default';
    },
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Topic',
    accessor: 'category_name',
    width: 100,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Lesson',
    accessor: 'topic_name',
    width: 200,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Created By',
    accessor: 'created_by_name',
  },
  {
    Header: 'User Tags',
    accessor: 'trust_level',
    width: 125,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
  },
  {
    Header: 'Error Reports',
    accessor: 'error_reports_count',
    Cell: item => (
      <div
        style={item.value > 0 ? { color: 'red' } : { color: 'green' }}
      >
        {item.value}
      </div>
    ),
    filterable: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Approved By',
    accessor: 'approved_by',
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
  },
]);
