/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis,
  Tooltip, CartesianGrid, BarChart, Bar, LabelList, Label,
} from 'recharts';
import Loading from '../Loading';
import { fetchAll, fetchSingle, fetchMonthly, fetchMrr } from '../../actions/analyticActions';
import { CenterRow, TooltipDiv, FloatRight, NotFoundDiv, PaddingBottom } from './styles';

const mapStateToProps = state => ({
  analytic: state.analytic,
  panelObject: state.navigation.panelObject,
});

class Subscription extends React.Component {
  static tooltip(data) {
    if (data && data.payload && typeof data.payload[0] !== 'undefined') {
      const { payload } = data.payload[0];
      const revenue = payload.revenue &&
        payload.revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return (
        <TooltipDiv>
          {payload.name && <strong>{payload.name}</strong>}
          <li>Sold: <FloatRight>{payload.count && payload.count.toLocaleString('en-US')}</FloatRight></li>
          <li>Revenue: <FloatRight>{revenue}</FloatRight></li>
          {payload.date && <li>Date: <FloatRight>{payload.date}</FloatRight></li>}
        </TooltipDiv>
      );
    }
    return null;
  }
  static monthlyTooltip(data) {
    if (data && data.payload && typeof data.payload[0] !== 'undefined') {
      return (
        <TooltipDiv>
          {data.payload.map(item => (
            <li key={item.name} style={{ color: item.fill }}>{item.name}:&nbsp;
              <strong>{item.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong>
            </li>
          ))}
        </TooltipDiv>
      );
    }
    return null;
  }
  static renderBars(data) {
    // Unique plan keys.
    // We need to keep track of all unique keys to be able to render
    // the bars correctly.
    const barKeys = [];
    // Looping through the data then the keys of each data item.
    // We look for unique keys based on barKeys and add them if they are unique.
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'name' && !barKeys.includes(key)) {
          barKeys.push(key);
        }
      });
    });
    const result = [];
    // Looping through unique data keys and render a Bar component for each.
    barKeys.forEach((key) => {
      const color = '#000000'.replace(/0/g, () => (Math.floor(Math.random() * 16).toString(16)));
      result.push((
        <Bar
          key={`${key}`}
          dataKey={key}
          stackId="a"
          fill={color}
        />
      ));
    });
    return result;
  }

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(5, 'days'),
      endDate: moment(),
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.getSingle = this.getSingle.bind(this);
  }

  componentDidMount() {
    this.getAll();
    this.getSingle('random');
    this.getMrr();
    this.getMonthly();
  }

  getAll() {
    this.props.fetchAll(this.props.panelObject.component, {
      from: this.state.startDate.format('YYYY-MM-DD'),
      to: this.state.endDate.format('YYYY-MM-DD'),
    });
  }

  getSingle(event) {
    const slug = event !== 'random' ? event.target.value : event;
    this.props.fetchSingle(this.props.panelObject.component, slug, {
      from: this.state.startDate.format('YYYY-MM-DD'),
      to: this.state.endDate.format('YYYY-MM-DD'),
    });
  }

  getMonthly() {
    this.props.fetchMonthly(this.props.panelObject.component);
  }

  getMrr() {
    this.props.fetchMrr(this.props.panelObject.component);
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    }, () => {
      this.getAll();
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    }, () => {
      this.getAll();
    });
  }
  render() {
    const revenue = this.props.analytic.revenue &&
      this.props.analytic.revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return (
      <div>
        {/* ALL */}
        <CenterRow>
          <h1>{this.props.panelObject.name}</h1>
          <h3>Sold: {this.props.analytic.sold && this.props.analytic.sold.toLocaleString('en-US')}</h3>
          <h3>Revenue: {revenue}</h3>
          <div>
            From: <DatePicker
              dropdownMode="select"
              selected={this.state.startDate}
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChangeStart}
              minDate={moment('2018-05-16')}
              showMonthDropdown
              scrollableYearDropdown
              showYearDropdown
            />
          </div>
          <div>
            To: <DatePicker
              dropdownMode="select"
              selected={this.state.endDate}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChangeEnd}
              minDate={moment('2018-05-16')}
              showMonthDropdown
              scrollableYearDropdown
              showYearDropdown
            />
          </div>
        </CenterRow>
        <CenterRow>
          <ResponsiveContainer width="100%" height={500}>
            {this.props.analytic.fetchingAll ?
              <Loading />
              : this.props.analytic.all.length > 0 ?
                <BarChart data={this.props.analytic.all} margin={{ top: 25 }}>
                  <XAxis dataKey="count" tick={{ fill: '#28a745' }} height={50}>
                    <Label value="Subscription Type" position="insideBottom" />
                  </XAxis>
                  <YAxis>
                    <Label value="Sold" position="insideLeft" angle={-90} />
                  </YAxis>
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip content={Subscription.tooltip} />
                  <Bar dataKey="count" fill="#17a2b8" barGap={5}>
                    <LabelList dataKey="name" position="top" />
                  </Bar>
                </BarChart>
              : <NotFoundDiv>Nothing to display</NotFoundDiv>
            }
          </ResponsiveContainer>
        </CenterRow>
        <PaddingBottom />

        {/* Single */}
        <CenterRow>
          <FormGroup controlId="formControlsSelect">
            <ControlLabel>Subscription:</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              onChange={this.getSingle}
            >
              {this.props.analytic.all &&
               this.props.analytic.all.map(subscription =>
                 <option key={subscription.slug} value={subscription.slug}>{subscription.name}</option>)
              }
            </FormControl>
            Showing: {this.props.analytic.singleName}
          </FormGroup>
        </CenterRow>
        <CenterRow>
          <ResponsiveContainer width="100%" height={500}>
            {this.props.analytic.fetchingSingle ?
              <Loading />
              : this.props.analytic.single.length > 0 ?
                <LineChart data={this.props.analytic.single} margin={{ top: 25 }}>
                  <XAxis dataKey="date" height={100} tick={{ angle: -45 }} textAnchor="end" />
                  <YAxis>
                    <Label value="Sold" position="insideLeft" angle={-90} />
                  </YAxis>
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip content={Subscription.tooltip} />
                  <Line type="monotone" dataKey="count" fill="#17a2b8" stroke="#17a2b8">
                    <LabelList dataKey="name" position="top" />
                  </Line>
                </LineChart>
              : <NotFoundDiv>Nothing to display</NotFoundDiv>
            }
          </ResponsiveContainer>
        </CenterRow>

        {/* Monthly Recurring Revenue */}
        <CenterRow>
          <h1>Monthly Recurring Revenue</h1>
        </CenterRow>
        <CenterRow>
          <ResponsiveContainer width="100%" height={500}>
            {this.props.analytic.fetchingMrr ?
              <Loading />
              : this.props.analytic.mrr.length > 0 ?
                <LineChart data={this.props.analytic.mrr} margin={{ top: 25, right: 25 }}>
                  <XAxis dataKey="month" height={100} tick={{ angle: -45 }} textAnchor="end" />
                  <YAxis>
                    <Label value="Sold" position="insideLeft" angle={-90} />
                  </YAxis>
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip content={Subscription.tooltip} />
                  <Line type="monotone" dataKey="count" fill="#17a2b8" stroke="#17a2b8">
                    <LabelList dataKey="count" position="top" />
                  </Line>
                </LineChart>
              : <NotFoundDiv>Nothing to display</NotFoundDiv>
            }
          </ResponsiveContainer>
        </CenterRow>

        {/* Monthly */}
        <CenterRow>
          <h1>Last 12 Months</h1>
        </CenterRow>
        <CenterRow>
          <ResponsiveContainer width="100%" height={500}>
            {this.props.analytic.fetchingMonthly ?
              <Loading />
              : this.props.analytic.monthly.length > 0 ?
                <BarChart data={this.props.analytic.monthly} margin={{ top: 25 }}>
                  <XAxis type="category" dataKey="name" />
                  <YAxis type="number" />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip content={Subscription.monthlyTooltip} />
                  {Subscription.renderBars(this.props.analytic.monthly)}
                </BarChart>
              : <NotFoundDiv>Nothing to display</NotFoundDiv>
            }
          </ResponsiveContainer>
        </CenterRow>

      </div>
    );
  }
}

Subscription.propTypes = {
  fetchAll: PropTypes.func.isRequired,
  fetchSingle: PropTypes.func.isRequired,
  fetchMonthly: PropTypes.func.isRequired,
  fetchMrr: PropTypes.func.isRequired,
  panelObject: PropTypes.shape({
    component: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  analytic: PropTypes.shape({
    all: PropTypes.array.isRequired,
    single: PropTypes.array.isRequired,
    singleName: PropTypes.string.isRequired,
    monthly: PropTypes.array.isRequired,
    mrr: PropTypes.array.isRequired,
    sold: PropTypes.number,
    revenue: PropTypes.number,
    fetchingAll: PropTypes.bool.isRequired,
    fetchingSingle: PropTypes.bool.isRequired,
    fetchingMonthly: PropTypes.bool.isRequired,
    fetchingMrr: PropTypes.bool.isRequired,
  }),
};

Subscription.defaultProps = {
  analytic: {
    sold: 0,
    revenue: 0,
  },
};

export default connect(
  mapStateToProps,
  {
    fetchAll,
    fetchSingle,
    fetchMonthly,
    fetchMrr,
  },
)(Subscription);
