import styled from 'styled-components';

export const Div = styled.div`
    min-width: 160px;
    max-width: 260px;
    min-height: 26px;
    border-radius: 7px;
    text-align: center;
    background-color: ${props => props.backgroundColor};
    padding-left: 10px;
    padding-right: 10px;
`;

export const Contents = styled.p`
    color: white;
    font-size: 16px;
    margin-bottom: 0;
`;

export const SubContent = styled.p`
    color: white;
    font-size: 12px;
`;
