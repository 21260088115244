import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import axios from 'axios';
import swal from 'sweetalert';
import history from '~/history';

import Checkbox from '~/components/UI/reduxFormElements/Checkbox/Checkbox';
import Dropdown from '~/components/UI/reduxFormElements/Dropdown/Dropdown';
import FroalaEditor from '~/components/UI/reduxFormElements/FroalaEditor/FroalaEditor';
import { required } from '~/validation';

import MatchingQuestionFormRepeatables from './MatchingQuestionFormRepeatables';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import Answers from '../Answers/Answers';

import {
  NewQuestionForm,
  SpacingContainer,
  QuestionHeader,
  SaveQuestionButton,
  DeleteQuestionButton,
} from './Form.styles';

const MatchingForm = ({
  addClickHandler,
  questionData,
  onSubmitQuestion,
  onSaveQuestions,
  onDeleteQuestion,
  match,
}) => {
  const [params, setParams] = useState({
    topics: [],
    lessons: [],
    physician_tasks: [],
    status: [],
    tracks: [],
  });

  const [currentTab, setCurrentTab] = useState('prompt');

  const handleTabClick = (name) => {
    setCurrentTab(name);
  };

  useEffect(() => {
    axios.get('/api/v1/propertyApi/onlinemeded/questions/params')
      .then(res => setParams(res.data))
      .catch((e) => {
        console.log(e);
        return swal({
          title: 'Error',
          text: 'Something went wrong getting question meta-information. Please try again.',
          icon: 'warning',
        });
      });
  }, []);

  const handleSaveAllClick = (e) => {
    e.preventDefault();

    /**
     * User is saving a new question.
     */
    if (!match.params.id) {
      onSubmitQuestion(0);
    }

    /**
     * User has edited one of the questions in the set,
     * and is saving or updating all.
     */
    onSaveQuestions();
  };

  const handleDeleteAllClick = (e) => {
    e.preventDefault();
    let swalTitle = `Deleting question #${match.params.id}`;

    if (questionData.questions && questionData.questions.length > 0) {
      swalTitle = `Deleting questions${questionData.questions.map(question => ` #${question.id}`)}`;
    }

    return swal({
      title: swalTitle,
      text: 'Are you sure you would like to delete all of these questions?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No',
          visible: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
    })
      .then((value) => {
        if (value !== null) {
          axios.delete(`/api/v1/propertyApi/onlinemeded/questions/${match.params.id}/all`)
            .catch((error) => {
              console.log(error);
              swal({
                title: 'Something went wrong',
                icon: 'warning',
              });
            });
          swal({
            title: 'Questions deleted successfully',
            icon: 'success',
          });

          history.push('/onlinemeded/questions/overview');
        }
      });
  };

  return (
    <Fragment>
      <QuestionHeader>
        <div className="question-info">
          <h1>{match.params.id ? `Question #${match.params.id}` : 'New Question'}</h1>
        </div>
      </QuestionHeader>
      <NewQuestionForm
        className="question-form"
      >
        <SpacingContainer>
          <Field
            name="status"
            component={Dropdown}
            data={params.status}
            placeholder="Select a status"
            label="Status"
          />
          <Field
            name="track"
            component={Dropdown}
            data={params.tracks}
            placeholder="Select a Track"
            label="Track"
            validate={required}
          />
          <Field
            name="topic"
            component={Dropdown}
            data={params.topics || []}
            placeholder="Select a Topic"
            label="Topic"
            validate={required}
            filterBy={{
              track_id: questionData.track,
            }}
          />
          <Field
            name="lesson"
            component={Dropdown}
            data={params.lessons}
            placeholder="Select a Lesson"
            label="Lesson"
            validate={required}
            filterBy={{
              category_id: questionData.topic,
            }}
          />
          <Field
            name="showOnChallenge"
            component={Checkbox}
            label="Show on Challenge"
          />
          <Field
            name="showOnQbanks"
            component={Checkbox}
            label="Show on Qbanks"
            disabled
          />
          <SaveQuestionButton onClick={handleSaveAllClick}>
            Save
          </SaveQuestionButton>
          <DeleteQuestionButton onClick={handleDeleteAllClick}>
            Delete All
          </DeleteQuestionButton>
        </SpacingContainer>
        <Tabs
          activeTab={currentTab}
          onSelect={handleTabClick}
        >
          <Tab
            id="prompt"
            name="Prompt"
          >
            <SpacingContainer>
              <Field
                name="vignette"
                component={FroalaEditor}
                label="Vignette"
                validate={required}
              />
            </SpacingContainer>
          </Tab>
          <Tab
            id="answers"
            name="Answers"
          >
            <SpacingContainer>
              <FieldArray
                name="answers"
                component={Answers}
                questionKey={questionData.id}
                validate={required}
                noRadio
              />
            </SpacingContainer>
          </Tab>
        </Tabs>
        <SpacingContainer>
          <FieldArray
            name="questions"
            component={MatchingQuestionFormRepeatables}
            onSubmitQuestion={onSubmitQuestion}
            onDeleteQuestion={onDeleteQuestion}
            addClickHandler={addClickHandler}
            params={params}
            answers={questionData.answers}
          />
          <hr />
          <Field
            name="explanation"
            component={FroalaEditor}
            label="Explanation"
            validate={required}
          />
          <Field
            name="teachingPoint"
            component={FroalaEditor}
            label="Teaching Point"
          />
        </SpacingContainer>
      </NewQuestionForm>
    </Fragment>
  );
};

export const questionType = PropTypes.shape({
  vignette: PropTypes.string,
  interrogatory: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.string,
  })),
});

MatchingForm.propTypes = {
  addClickHandler: PropTypes.func.isRequired,
  questionData: questionType,
  onSubmitQuestion: PropTypes.func.isRequired,
  onSaveQuestions: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  params: PropTypes.shape({
    topics: PropTypes.array,
    lessons: PropTypes.array,
    physician_tasks: PropTypes.array,
    status: PropTypes.array,
    tracks: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

MatchingForm.defaultProps = {
  questionData: {},
  params: {
    topics: [],
    lessons: [],
    physician_tasks: [],
    status: [],
    tracks: [],
  },
  match: {
    params: {},
  },
};

export default MatchingForm;
