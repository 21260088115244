import axios from 'axios/index';
import swal from 'sweetalert';
import history from '../history';
import * as constants from '../constants';

export function fetchModel(panelSlug, itemId) {
  return (dispatch, getState) => {
    const { basePath, basePropertyApiPath } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/${panelSlug}/${itemId}`;
    dispatch({ type: 'FETCH_DATA' });
    axios.get(endpoint)
      .then(
        (response) => {
          dispatch({
            type: 'FETCH_DATA_FULFILLED',
            payload: response.data,
          });
        },
        (err) => {
          if (err.response && err.response.status === 403) {
            const { navigation } = getState();
            dispatch({
              type: 'FETCH_DATA_UNAUTHORIZED',
              payload: err,
            });
            history.push(`${basePath}/${navigation.data[0].slug}/overview`);
          } else {
            // Question was not found, redirect to default page
            if (err.response && err.response.status === 404) {
              const { navigation } = getState();
              history.push(`${basePath}/${navigation.data[0].slug}/overview`);
            }
            dispatch({
              type: 'FETCH_DATA_REJECTED',
              payload: err,
            });
          }
        },
      );
  };
}

export function fetchModelParams(panelSlug) {
  return (dispatch, getState) => {
    const endpoint = `${getState().navigation.basePropertyApiPath}/${panelSlug}/params`;
    dispatch({ type: 'FETCH_PARAMS' });
    axios.get(endpoint)
      .then(
        (response) => {
          dispatch({
            type: 'FETCH_PARAMS_FULFILLED',
            payload: response.data,
          });
        },
        (err) => {
          dispatch({ type: 'FETCH_PARAMS_REJECTED', payload: err });
        },
      );
  };
}

export function updateModel(user, model, panelSlug, id, modelIndex) {
  function getDataObjectFromModel() {
    if (!model) {
      return {};
    }

    const data = {
      id: model.id,
    };
    const keys = Object.keys(model.data);
    for (let i = 0; i < keys.length; i += 1) {
      // Don't include comments for post data
      if (keys[i] !== 'comments') {
        if (typeof model.data[keys[i]] === 'object') {
          data[keys[i]] = model.data[keys[i]].data;
        } else {
          data[keys[i]] = model.data[keys[i]];
        }
      }
    }
    return data;
  }

  const modelData = getDataObjectFromModel();
  const data = {
    data: modelData,
    user,
  };

  return (dispatch, getState) => {
    const endpoint = `${getState().navigation.basePropertyApiPath}/${panelSlug}/${id}`;
    const { models } = getState().model;
    dispatch({
      type: 'UPDATE_MODEL',
      modelIndex,
    });
    // If id is new and modelIndex is something but not zero
    if (data.data.id === 'new' && modelIndex) {
      data.parentId = models[modelIndex - 1].object.id;
      data.childId = modelIndex + 1 < models.length ? models[modelIndex + 1].object.id : null;
    }
    axios.post(endpoint, data)
      .then(
        (response) => {
          const { basePath, panelObject } = getState().navigation;
          dispatch({
            type: 'UPDATE_MODEL_FULFILLED',
            payload: response.data,
            modelIndex,
          });
          swal({
            title: `${panelObject.panelSingularName} ${data.data.id === 'new' ? 'Created' : 'Updated'}!`,
            icon: 'success',
          });
          history.push(`${basePath}/${panelObject.panelSlug}/editor/${response.data.id}`);
        },
        (err) => {
          dispatch({
            type: 'UPDATE_MODEL_REJECTED',
            payload: err,
            modelIndex,
          });
          swal({
            title: 'Error',
            text: err.response.data.message,
            icon: 'error',
          });
        },
      );
  };
}

export function cloneModel(panelSlug, id) {
  return (dispatch, getState) => {
    const { basePropertyApiPath, property } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/${panelSlug}/clone`;
    dispatch({
      type: 'CLONE_MODEL',
    });
    axios.post(endpoint, { id })
      .then(
        (response) => {
          const { panelObject } = getState().navigation;

          dispatch({
            type: 'CLONE_MODEL_FULFILLED',
            payload: response.data,
            linking: panelObject.settings && panelObject.settings.linking,
          });
          swal({
            title: 'Model cloned successfully!',
            icon: 'success',
          });
          history.push(`/${property}/${panelObject.panelSelector}/${response.data.question.id}`);
        },
        (err) => {
          dispatch({
            type: 'CLONE_MODEL_REJECTED',
            payload: err,
          });
          swal({
            title: 'Error while cloning',
            text: err.response.data.message,
            icon: 'error',
          });
        },
      );
  };
}

export function deleteModel(panelSlug, id) {
  return (dispatch, getState) => {
    const { basePropertyApiPath, property } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/${panelSlug}/${id}`;
    dispatch({
      type: 'DELETE_MODEL',
    });
    axios.delete(endpoint)
      .then(
        (response) => {
          const { navigation } = getState();

          dispatch({
            type: 'DELETE_MODEL_FULFILLED',
            redirect_id: response.data.redirect_id,
          });
          swal({
            title: `${navigation.panelObject.panelSingularName} Deleted!`,
            icon: 'success',
          });

          if (response.data.redirect_id) {
            history.push(`/${property}/${panelSlug}/editor/${response.data.redirect_id}`);
          } else {
            history.push(`/${property}/${panelSlug}/overview`);
          }
        },
        (err) => {
          dispatch({
            type: 'DELETE_MODEL_REJECTED',
            payload: err,
          });
          if (err.response) {
            swal({
              title: 'Error while deleting',
              text: err.response.data.message,
              icon: 'error',
            });
          } else {
            // TODO: don't make ui changes in the action
            // put swal in top level container and react to redux state change caused by DELETE_MODEL_REJECTED
            swal({
              title: 'Error while deleting',
              text: err.message,
              icon: 'error',
            });
          }
        },
      );
  };
}

export function changeModel(data, modelIndex = null) {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_MODEL',
      payload: data,
      modelIndex,
    });
  };
}

export function newModel(panel, modelIndex = null) {
  return (dispatch, getState) => {
    const { panelObject } = getState().navigation;
    if (panelObject.settings && panelObject.settings.linking) {
      let currentModel = null;
      let fieldsToOverride = null;

      // Getting data we need for adding linked models if model already exists.
      if (modelIndex !== null) {
        currentModel = getState().model.models[modelIndex].object;
        fieldsToOverride = panelObject.tabs[0].fields;
      }

      dispatch({
        type: 'NEW_MODEL',
        payload: panel,
        linking: true,
        currentModel,
        fieldsToOverride,
        modelIndex,
      });
    } else {
      dispatch({
        type: 'NEW_MODEL',
        payload: panel,
      });
    }
  };
}

export function reorderModel(panelSlug, modelIndex, direction) {
  return (dispatch, getState) => {
    const endpoint = `${getState().navigation.basePropertyApiPath}/${panelSlug}/reorder`;
    const { models } = getState().model;

    if (models.some(model => model.object.id === 'new')) {
      swal({
        title: 'Unsaved Items',
        text: 'Reordering is not possible until all items have been saved.',
        icon: 'error',
      });
      return;
    }

    // Check if reorder in the desired direction is possible
    if (direction === 'up' && modelIndex === 0) {
      swal({
        title: 'Impossible!',
        text: 'Reordering up on the top item is not possible',
        icon: 'error',
      });
      return;
    } else if (direction === 'down' && modelIndex === models.length - 1) {
      swal({
        title: 'Impossible!',
        text: 'Reordering down on the bottom item is not possible',
        icon: 'error',
      });
      return;
    }

    dispatch({
      type: 'REORDER_MODEL',
      modelIndex,
    });
    axios.post(endpoint, {
      modelId: models[modelIndex].object.id,
      direction,
    })
      .then(
        (response) => {
          dispatch({
            type: 'REORDER_MODEL_FULFILLED',
            payload: response.data,
            direction,
            modelIndex,
          });
        },
        (err) => {
          dispatch({
            type: 'REORDER_MODEL_REJECTED',
            payload: err,
          });
          if (err.response) {
            swal({
              title: 'Error while reordering',
              text: err.response.data.message,
              icon: 'error',
            });
          } else {
            swal({
              title: 'Error while reordering',
              test: 'err.message',
              icon: 'error',
            });
          }
        },
      );
  };
}

export function postComment({
  unique, type, message, modelIndex, header,
}) {
  return (dispatch, getState) => {
    const endpoint = `${getState().navigation.baseAdminApiPath}/comments`;
    dispatch({
      type: constants.MODEL_POST_COMMENT,
    });
    axios.post(endpoint, {
      unique,
      type,
      message,
      header,
    })
      .then(
        (response) => {
          response.data.comment.isNew = true;
          dispatch({
            type: constants.MODEL_POST_COMMENT_SUCCESS,
            payload: response.data,
            meta: {
              modelIndex,
              modelType: type,
            },
          });
        },
        (error) => {
          dispatch({
            type: constants.MODEL_POST_COMMENT_SUCCESS,
            payload: error,
          });
        },
      );
  };
}

export function updateStatus(status, optionalMessage, modelIndex) {
  return (dispatch, getState) => {
    const { panelSlug } = getState().navigation.panelObject;
    const endpoint = `${getState().navigation.basePropertyApiPath}/${panelSlug}/update-status`;
    dispatch({
      type: constants.UPDATE_MODEL_STATUS,
      meta: {
        modelIndex,
      },
    });
    const modelId = modelIndex !== null ? getState().model.models[modelIndex].object.id : getState().model.object.id;
    axios.post(endpoint, {
      modelId,
      modelIndex,
      status,
    })
      .then((response) => {
        dispatch({
          type: constants.UPDATE_MODEL_STATUS_SUCCESS,
          meta: {
            modelIndex,
          },
          payload: response.data.status,
        });
        dispatch(postComment({
          unique: modelId,
          type: panelSlug,
          message: optionalMessage,
          modelIndex,
          header: `Status updated: ${status}`,
        }));
      })
      .catch((error) => {
        dispatch({
          type: constants.UPDATE_MODEL_STATUS_ERROR,
          payload: error,
        });
      });
  };
}

export function resolveErrorReports(errorReportIds) {
  return (dispatch, getState) => {
    const endpoint = `${getState().navigation.basePropertyApiPathSlugless}/error-reports/resolve`;
    dispatch({
      type: constants.RESOLVE_ERROR_REPORT,
    });
    axios.post(endpoint, {
      errorReportIds,
    })
      .then(
        (response) => {
          dispatch({
            type: constants.RESOLVE_ERROR_REPORT_SUCCESS,
            payload: response.data.errorReportIds,
          });
        },
        (error) => {
          dispatch({
            type: constants.RESOLVE_ERROR_REPORT_ERROR,
            payload: error,
          });
        },
      );
  };
}
