/* eslint-disable import/no-extraneous-dependencies */
import createHistory from 'history/createBrowserHistory';
import store from './store';

import { updateNavigationParameters } from './actions/navigationActions';

const history = createHistory();

const currentLocation = history.location;

function navigate(location) {
  const params = location.pathname.split('/');
  if (params.length > 1) {
    const nav = {
      property: params[1],
      panelSlug: params[2],
      component: params[3],
    };
    if (params.length > 4) {
      // eslint-disable-next-line prefer-destructuring
      nav.id = parseInt(params[4], 10);
    }

    store.dispatch(updateNavigationParameters(nav));
  }
}

history.listen((location, action) => {
  if (action !== 'REPLACE' && location !== currentLocation) {
    navigate(location);
  }
});

history.block(() => (store.getState().navigation.showUnsavedChangesWarning ?
  'You have unsaved changes!\n\nAre you sure you want to leave this page?' : null));

navigate(currentLocation);

export default history;
