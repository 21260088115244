import { deepClone } from '../utils';

function buildSchemaLockableEmrDefinitions(schema, formData) {
  const emrs = formData && formData.emrs ? formData.emrs : [];
  const lockIds = [];
  const lockNames = [];
  emrs.forEach((emr, i) => {
    if (emr.details) {
      emr.details.forEach((item, j) => {
        if (item.lock) {
          lockIds.push(`#/emrs/${i}/details/${j}/`);
          const title = `${item.title || '[no title]'}`;
          const text = `${item.text || '[no text]'}`;
          const rtf = item.rtf ? '[RTF]' : '';
          const media = `[${item.media.name || 'no media'}]`;
          const name = `${emr.title || '[untitled section]'} - ${title}: ${rtf}${text} ${media}`;
          lockNames.push(name);
        }
      });
    }
  });
  const newSchema = { ...schema };
  if (lockIds.length) {
    // setting enum or enumNames to an empty array will cause react-json-form validation error
    newSchema.questionSchema.definitions.unlocks = {
      type: 'string',
      enum: lockIds,
      enumNames: lockNames,
    };
  }
  return newSchema;
}

function buildQuestionsSchemaData(schema, formData) {
  const questions = formData && formData.questions ? formData.questions : [];
  const lockIds = [];
  const lockNames = [];
  questions.forEach((question, i) => {
    // use only questions that have a postscript, per requirements in DEV-2577
    if ((question.postscript && question.postscript.length > 0) || (question.unlocks && question.unlocks.length > 0)) {
      lockIds.push(`#/questions/${i}/`);
      const prompt = `${question.prompt || '[no prompt]'}`;
      lockNames.push(prompt);
    }
  });
  const newSchema = { ...schema };
  if (lockIds.length) {
    // setting enum or enumNames to an empty array will cause react-json-form validation error
    newSchema.labSticksSchema.definitions.questions = {
      type: 'string',
      enum: lockIds,
      enumNames: lockNames,
    };
  }
  return newSchema;
}

export const buildSchema = (defaultSchema, formData) => {
  let newSchema;
  newSchema = deepClone(defaultSchema);
  newSchema = buildSchemaLockableEmrDefinitions(newSchema, formData);
  newSchema = buildQuestionsSchemaData(newSchema, formData);
  return newSchema;
};
