import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Alert,
} from 'react-bootstrap';

import {
  Div,
  Textarea,
  Buttons,
  StatusChangedMessage,
} from './questionModerationStyles';

const propTypes = {
  model: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pendingStatus: PropTypes.string,
  optionalMessage: PropTypes.string.isRequired,
  onChangeOptionalMessage: PropTypes.func.isRequired,
  onSubmitStatus: PropTypes.func.isRequired,
};

const defaultProps = {
  pendingStatus: null,
};

class QuestionModeration extends React.Component {
  constructor() {
    super();

    this.onSubmitStatusApproved = this.onSubmitStatusApproved.bind(this);
    this.onSubmitStatusRejected = this.onSubmitStatusRejected.bind(this);
  }

  onSubmitStatusApproved() {
    this.props.onSubmitStatus('Approved');
  }

  onSubmitStatusRejected() {
    this.props.onSubmitStatus('Reviewed');
  }

  render() {
    const {
      model,
      value,
      pendingStatus,
      optionalMessage,
      onChangeOptionalMessage,
    } = this.props;

    return (
      <Div>
        <label>Question Moderation</label>
        { model.isStatusUpdated ? (
          <StatusChangedMessage style={{ color: value === 'Approved' ? '#0bbee6' : '#fd7f7c' }}>
            {`This question status was changed to "${value}".`}
          </StatusChangedMessage>
        ) : (
          <div>
            <Textarea
              placeholder="Write your feedback (optional).
              Once you select an action, your message will be sent and the status of this question will change."
              onChange={e => onChangeOptionalMessage(e)}
              value={optionalMessage}
            />
            <Buttons>
              <Button
                bsStyle="primary"
                onClick={this.onSubmitStatusApproved}
                disabled={model.isStatusUpdating}
                style={model.isStatusUpdating || pendingStatus === 'Reviewed' ?
                {
                  opacity: '.5',
                } :
                {
                  opacity: '1',
                }}
              >
                Approve
              </Button>
              <Button
                onClick={this.onSubmitStatusRejected}
                disabled={model.isStatusUpdating}
                style={model.isStatusUpdating || pendingStatus === 'Approved' ?
                {
                  opacity: '.5',
                } :
                {
                  opacity: '1',
                }}
              >
                Reject
              </Button>
            </Buttons>
            { pendingStatus && <Alert bsStyle="warning">Press button again to confirm</Alert> }
          </div>
        )}
      </Div>
    );
  }
}

QuestionModeration.propTypes = propTypes;
QuestionModeration.defaultProps = defaultProps;

export default connect(
  null,
  {},
)(QuestionModeration);
