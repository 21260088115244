import axios from 'axios';
import history from '../history';
import * as constants from '../constants';
import { slugify } from '../utils';
import { caseDefaults } from '../schema/caseSchema_0.x.0';

export function fetchLessons() {
  return (dispatch, getState) => {
    const { basePropertyApiPath } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/lessons`;
    dispatch({ type: constants.FETCH_LESSONS });
    return axios.get(endpoint)
      .then(
        (response) => {
          dispatch({
            type: constants.FETCH_LESSONS_SUCCESS,
            payload: response.data,
          });
        },
        (error) => {
          dispatch({ type: constants.FETCH_LESSONS_ERROR, payload: error });
        },
      );
  };
}

export function newDocument() {
  return (dispatch) => {
    dispatch(fetchLessons());
    dispatch({
      type: constants.NEW_DOCUMENT,
    });
  };
}

export function resetSummary(document) {
  return (dispatch) => {
    dispatch({
      type: constants.RESET_SUMMARY,
      payload: document,
    });
  };
}

export function saveDocument(documentId, formData, documentStatus, metaData) {
  return (dispatch, getState) => {
    dispatch({ type: constants.SAVE_DOCUMENT });

    const doc = {
      ...getState().cases.document,
      // once document has been loaded and saved with the current schema version it is now formatted in that version.
      schemaVersion: caseDefaults.document.schemaVersion,
      formData,
    };

    const { basePath, basePropertyApiPath, property } = getState().navigation;
    const body = {
      document: JSON.stringify(doc),
      slug: slugify(doc.formData.title),
      property_slug: property,
      categoryIds: metaData.categoryIds || [],
      topicIds: metaData.topicIds || [],
      question_count: doc.formData.questions ? doc.formData.questions.length : 0,
      title: doc.formData.title,
      faculty_title: doc.formData.facultyTitle,
      free: metaData.free ? 1 : 0,
      thumbnail_url: doc.formData.video ? doc.formData.video.imageUrl : null,
    };

    if (documentStatus) {
      // TODO: this should be loaded from the backend
      const statusMap = {
        'Not Reviewed': 'not_reviewed',
        'In Process': 'in_process',
        // eslint-disable-next-line quote-props
        'Approved': 'approved',
      };
      body.status_slug = statusMap[documentStatus];
    }

    // endpoint without documentId will create a new document
    let endpoint = `${basePropertyApiPath}/cases`;
    // POST for storing new cases and PUT for updating existing cases
    let axiosMethod = axios.post;
    if (documentId) {
      endpoint += `/${documentId}`;
      axiosMethod = axios.put;
    }
    axiosMethod(endpoint, body)
      .then(
        (response) => {
          const isNewDoc = !documentId;
          dispatch({
            type: constants.SAVE_DOCUMENT_SUCCESS,
            payload: {
              id: response.data.id,
              slug: response.data.slug,
              categories: metaData.categoryIds,
              topics: metaData.topicIds,
              free: metaData.free,
              document: doc,
              documentStatus: response.data.status ? response.data.status.description : 'Not Reviewed',
            },
          });
          dispatch({ type: constants.UNSAVED_CHANGES_WARNING, payload: false });
          if (isNewDoc) {
            history.push(`${basePath}/cases/editor/${response.data.id}`);
          }
        },
        (error) => {
          dispatch({
            type: constants.SAVE_DOCUMENT_ERROR,
            payload: {
              document: doc,
              subcontent: error.response.data.message,
              errors: error.response.data.errors,
            },
          });
        },
      );
  };
}

export function loadDocument() {
  return (dispatch, getState) => {
    dispatch(fetchLessons());
    const { basePropertyApiPath, id } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/cases/${id || ''}`;
    dispatch({ type: constants.LOAD_DOCUMENT });
    axios.get(endpoint)
      .then(
        (response) => {
          if (response.data && response.data.id && response.data.document) {
            const document = JSON.parse(response.data.document);
            dispatch({
              type: constants.LOAD_DOCUMENT_SUCCESS,
              payload: {
                id: response.data.id,
                slug: response.data.slug,
                categories: response.data.categories,
                topics: response.data.topics,
                document,
                status: response.data.status,
                free: response.data.free,
                comments: response.data.comments,
                errorReports: response.data.error_reports,
              },
            });
          } else {
            dispatch({ type: constants.LOAD_DOCUMENT_ERROR, payload: 'Missing or Unauthorized' });
          }
        },
        (error) => {
          dispatch({
            type: constants.LOAD_DOCUMENT_ERROR,
            payload: error.response.data.message,
            subcontent: '',
          });
        },
      );
  };
}

export function deleteCase(documentId) {
  return (dispatch, getState) => {
    const { basePath, basePropertyApiPath } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/cases/${documentId}`;
    dispatch({ type: constants.UNSAVED_CHANGES_WARNING, payload: false });
    dispatch({ type: constants.DELETE_DOCUMENT });
    axios.delete(endpoint)
      .then(
        (response) => {
          dispatch({
            type: constants.DELETE_DOCUMENT_SUCCESS,
            payload: response.data,
          });

          history.push(`${basePath}/cases/overview`);
        },
        (error) => {
          dispatch({ type: constants.DELETE_DOCUMENT_ERROR, payload: error });
        },
      );
  };
}

export function cloneCase(documentId) {
  return (dispatch, getState) => {
    const { basePath, basePropertyApiPath } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/cases/${documentId}/clone`;
    dispatch({ type: constants.CLONE_DOCUMENT });
    axios.post(endpoint)
      .then(
        (response) => {
          const caseId = response.data.case.id;
          dispatch({
            type: constants.CLONE_DOCUMENT_SUCCESS,
            payload: {
              id: caseId,
              document: JSON.parse(response.data.case.document),
              comments: response.data.case.comments,
              errorReports: response.data.case.error_reports,
            },
          });

          dispatch({
            type: constants.UPDATE_NAVIGATION_PARAMETERS,
            payload: {
              ...getState().navigation,
              id: caseId,
            },
          });

          history.push(`${basePath}/cases/editor/${caseId}`);
        },
        (error) => {
          const payload = {};
          if (error.response && error.response.data && error.response.data.message) {
            payload.message = error.response.data.message;
          }
          dispatch({ type: constants.CLONE_DOCUMENT_ERROR, payload });
        },
      );
  };
}

export function fetchReelDxVideo(videoId) {
  return (dispatch, getState) => {
    const { basePropertyApiPath } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/cases/videos/${videoId}`;
    dispatch({ type: constants.FETCH_REEL_DX_VIDEO });
    axios.get(endpoint)
      .then(
        (response) => {
          dispatch({
            type: constants.FETCH_REEL_DX_VIDEO_SUCCESS,
            payload: response.data,
          });
        },
        (error) => {
          dispatch({
            type: constants.FETCH_REEL_DX_VIDEO_ERROR,
            payload: error,
          });
        },
      );
  };
}

export function uploadImage(file, name, documentId) {
  const data = new FormData();
  data.append('image', file);
  data.append('document_id', documentId);

  return (dispatch, getState) => {
    const { basePropertyApiPath, property } = getState().navigation;
    const endpoint = `${basePropertyApiPath}/cases/upload`;
    data.append('property_slug', property);
    dispatch({ type: constants.UPLOAD_IMAGE });

    const config = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch({ type: constants.UPLOAD_PROGRESS, payload: percentCompleted });
      },
    };
    axios.post(endpoint, data, config)
      .then(
        (response) => {
          dispatch({
            type: constants.UPLOAD_IMAGE_SUCCESS,
            payload: {
              id: response.data.id,
              response: response.data,
              upload: { [name]: response.data.url },
            },
          });
        },
        (error) => {
          dispatch({
            type: constants.UPLOAD_IMAGE_ERROR,
            payload: {
              subcontent: error.response.data.message,
              errors: error.response.data.errors,
            },
          });
        },
      );
  };
}

export function postComment({
  unique, type, message, modelIndex,
}) {
  return (dispatch, getState) => {
    const { baseAdminApiPath } = getState().navigation;
    dispatch({
      type: constants.CASE_POST_COMMENT,
    });
    axios.post(`${baseAdminApiPath}/comments`, { unique, type, message })
      .then(
        (response) => {
          dispatch({
            type: constants.CASE_POST_COMMENT_SUCCESS,
            payload: response.data,
            modelIndex,
            modelType: type,
          });
        },
        (error) => {
          dispatch({
            type: constants.CASE_POST_COMMENT_ERROR,
            payload: error,
          });
        },
      );
  };
}
