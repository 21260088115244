/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
/* froala BEGIN */
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';
import FroalaEditor from 'react-froala-wysiwyg';
import { FROALA_KEY } from '../constants';
/* froala END */


const InputWidget = props => (
  <input
    type="text"
    className="form-control"
    data-cy={`case_${props.id}`}
    required={props.required}
    value={props.value || ''}
    readOnly={props.readonly}
    onChange={event => props.onChange(event.target.value)} />
);

const TextAreaWidget = props => (
  <textarea
    data-cy={`case_${props.id}`}
    required={props.required}
    className="form-control"
    rows="5"
    onChange={event => props.onChange(event.target.value)}
    value={props.value || ''} />
);

const toolbarButtons = ['fullscreen', 'bold', 'italic', 'underline', 'subscript', 'superscript', '|',
  'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-',
  'insertImage', 'insertLink', 'embedly', 'insertTable', '|',
  'fontAwesome', 'specialCharacters', 'clearFormatting', '|',
  'spellChecker', 'help', 'html', '|', 'undo', 'redo'];
const froalaConfig = props => ({
  key: FROALA_KEY,
  toolbarButtons,
  imageUploadURL: `/api/v1/froala/file/case?api_token=${props.formContext.apiToken}`,
  // Support up to 11MB that way content team can actually upload 10MB files
  imageMaxSize: 11 * 1024 * 1024,
  imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
  imageUploadParams: { model_id: props.formContext.documentId },
  wordPasteModal: false,
  wordPasteKeepFormatting: false,
});

const FroalaWidget = props => (
  <FroalaEditor
    config={froalaConfig(props)}
    tag="textarea"
    data-cy={`case_${props.id}`}
    rows="5"
    onModelChange={value => props.onChange(value)}
    model={props.value || ''} />
);

export const widgets = {
  inputWidget: InputWidget,
  textAreaWidget: TextAreaWidget,
  froalaWidget: FroalaWidget,
};

export const metaSchema = {
  data: {
    definitions: {
      categories: {
        type: 'number',
      },
      topics: {
        type: 'number',
      },
    },
    title: 'Meta',
    type: 'object',
    properties: {
      free: { title: 'Free', type: 'boolean' },
      categoryIds: {
        title: 'Topics',
        type: 'array',
        items: {
          type: 'number',
          $ref: '#/definitions/categories',
        },
      },
      topicIds: {
        title: 'Lessons',
        type: 'array',
        items: {
          type: 'number',
          $ref: '#/definitions/topics',
        },
      },
    },
  },
};

export const caseSchema = {

  hpiSchema: {
    definitions: {
      categories: {
        type: 'number',
      },
    },
    title: 'History of Present Illness',
    type: 'object',
    required: [
      'title',
      'facultyTitle',
    ],
    properties: {
      facultyTitle: { title: 'Faculty Mode Label', type: 'string', minLength: 5 },
      title: { title: 'Topic', type: 'string', minLength: 5 },
      oneLiner: { title: 'One-liner', type: 'string' },
      video: {
        title: 'ReelDx Video',
        type: 'object',
        properties: {
          reelDxId: { type: 'string', title: 'Reel Dx Video Id' },
          videoUrl: { type: 'string', title: 'Video URL' },
          imageUrl: { type: 'string', title: 'Poster Image URL' },
          srtUrl: { type: 'string', title: 'Closed Caption URL' },
          disclaimer: { title: 'Disclaimer', type: 'string' },
        },
      },
      complaint: { title: 'Chief Complaint', type: 'string' },
      intro: { title: 'History of Present Illness', type: 'string' },
      reviewOfSystem: { title: 'Review of System', type: 'string' },

      pastMedical: {
        title: 'Past Medical',
        type: 'array',
        minItems: 4,
        maxItems: 4,
        items: {
          type: 'object',
          properties: {
            title: { type: 'string', title: 'Title' },
            history: { type: 'array', title: '', items: { type: 'string' } },
          },
        },
      },

      meds: { title: 'Meds', type: 'array', items: { type: 'string' } },
      allergies: { title: 'Allergies', type: 'array', items: { type: 'string' } },

      physicalExam: {
        title: 'Physical Exam',
        type: 'object',
        properties: {
          vitals: {
            title: 'Physical Exam - Vitals',
            type: 'object',
            properties: {
              temp: { title: 'Temp', type: 'string' },
              bp: { title: 'BP', type: 'string' },
              hr: { title: 'HR', type: 'string' },
              rr: { title: 'RR', type: 'string' },
              o2: { title: 'O2', type: 'string' },
              weight: { title: 'Weight', type: 'string' },
            },
          },
          notes: { title: 'Additional Notes', type: 'array', items: { type: 'string' } },
          displayType: {
            title: 'Display Type',
            type: 'string',
            enum: ['single-column', 'column-gallery'],
            enumNames: ['Single Column', 'Side-By-Side with Image/Image Gallery'],
          },
          details: {
            title: 'Physical Exam - Details',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: { type: 'string', title: 'Title' },
                body: { type: 'string', title: 'Body' },
              },
            },
          },
          specialized_exam_details: {
            title: 'Specialized Physical Exam - Details',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: { type: 'string', title: 'Title' },
                body: { type: 'string', title: 'Body' },
              },
            },
          },
          images: {
            title: 'Physical Exam - Image or Video',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: { type: 'string', title: 'Caption Title' },
                body: { type: 'string', title: 'Caption Body' },
                media: {
                  type: 'object',
                  properties: {
                    name: { type: 'string' },
                    url: { type: 'string' },
                    mediaType: {
                      type: 'string',
                      default: 'image',
                      enum: [
                        'image',
                        'video',
                      ],
                    },
                  },
                },
              },
            },
          },
        },
      },
      summary: {
        title: 'Summary Override',
        type: 'array',
        minItems: 0,
        maxItems: 9,
        items: {
          type: 'object',
          properties: {
            title: { type: 'string', title: 'Title' },
            body: { type: 'string', title: 'Summary' },
          },
        },
      },
    },
  },

  emrSchema: {
    title: '',
    type: 'object',
    properties: {

      emrs: {
        type: 'array',
        title: 'EMR',
        items: {
          type: 'object',
          properties: {
            title: { title: 'Section Heading', type: 'string' },
            displayType: {
              title: 'Display Type',
              type: 'string',
              enum: [
                'single-column',
                'single-column-rtf',
                'content-gallery',
                'image-flip',
                'left-right-show-text',
                'left-right-reveal-text',
                'top-bottom-show-text',
                'top-bottom-reveal-text',
                'vital-signs',
              ],
              enumNames: [
                'Single Column',
                'Single Column RTF',
                'Side-By-Side with Image/Image Gallery',
                'Image Only, Flip for Text',
                'Left/Right, Show Text',
                'Left/Right, Interact to Reveal Text',
                'Top/Bottom, Show Text',
                'Top/Bottom, Interact to Reveal Text',
                'Vital Signs',
              ],
            },
            details: {
              title: '',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  title: { title: 'Title', type: 'string' },
                  text: { title: 'Text', type: 'string' },
                  rtf: { title: 'RTF', type: 'string' },
                  labsShorthandRtf: { title: 'Labs - Shorthand (for H&P)', type: 'string' },
                  imagingShorthandRtf: { title: 'Imaging - Shorthand (for H&P)', type: 'string' },
                  hideText: { title: 'Hide Text Until Image Interaction', type: 'boolean' },
                  lock: { title: 'Lock Until Question Interaction', type: 'boolean' },
                  media: {
                    type: 'object',
                    properties: {
                      name: { type: 'string' },
                      url: { type: 'string' },
                      mediaType: {
                        type: 'string',
                        enum: [
                          'image',
                          'video',
                        ],
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  questionSchema: {
    definitions: {
      unlocks: {
        type: 'string',
      },
    },

    title: '',
    type: 'object',
    properties: {

      questions: {
        title: 'Questions',
        type: 'array',
        items: {
          type: 'object',
          required: ['prompt', 'longAnswer'],
          properties: {
            prompt: { title: 'Question', type: 'string' },
            longAnswer: { title: 'Answer', type: 'string' },
            lesson: {
              type: 'array',
              title: 'Lesson Links',
              items: {
                type: 'object',
                required: ['topicSlug', 'lessonSlug'],
                properties: {
                  displayText: { title: 'Display Text', type: 'string' },
                  topicSlug: { title: 'Topic Slug', type: 'string' },
                  lessonSlug: { title: 'Lesson Slug', type: 'string' },
                },
              },
            },
            postscript: { title: 'The Case Continues', type: 'string' },
            unlocks: {
              title: 'Unlocks Checked Content',
              type: 'array',
              items: {
                type: 'string',
                $ref: '#/definitions/unlocks',
              },
              uniqueItems: true,
            },
          },
        },
      },

    },
  },

  keyPointsAndResourcesSchema: {
    title: '',
    type: 'object',
    properties: {

      keyPoints: {
        type: 'array',
        title: 'Key Points',
        items: { type: 'string' },
      },

      resources: {
        title: 'Resources',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            title: { title: 'Title', type: 'string' },
            text: { title: 'Text', type: 'string' },
          },
        },
      },

    },
  },

  hpMinerSchema: {
    title: 'H&P Miner',
    type: 'object',
    properties: {
      hpMiners: {
        title: '',
        type: 'object',
        properties: {
          summaries: {
            title: 'Summaries',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: { title: 'Title', type: 'string' },
                body: { title: 'Summary', type: 'string' },
              },
            },
          },
          patientHistory: {
            title: 'Patient History',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: { title: 'Title', type: 'string' },
                body: { title: 'Summary', type: 'string' },
              },
            },
          },
          physical: {
            title: 'Physical',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: { title: 'Title', type: 'string' },
                body: { title: 'Summary', type: 'string' },
              },
            },
          },
        },
      },
    },
  },

  /* eslint-disable id-length */
  labSticksSchema: {
    definitions: {
      question: {
        type: 'string',
      },
    },
    type: 'object',
    properties: {
      labSticks: {
        title: 'Sticks',
        type: 'array',
        uniqueItems: true,
        items: {
          type: 'object',
          properties: {
            stickType: {
              title: 'Type',
              type: 'string',
              enum: [
                'CBC',
                'BMP',
                'Magnesium',
                'LFT',
                'PT/Coag',
                'Cholesterol',
              ],
            },
            locked: {
              title: 'Locked?',
              type: 'boolean',
              default: false,
            },
          },
          dependencies: {
            stickType: {
              oneOf: [
                {
                  properties: {
                    stickType: {
                      enum: [
                        'CBC',
                      ],
                    },
                    wbc: { title: 'WBC', type: 'string' },
                    hgb: { title: 'Hgb', type: 'string' },
                    hct: { title: 'Hct', type: 'string' },
                    plt: { title: 'PLT', type: 'string' },
                    additionalComments: { title: 'Additional Comments', type: 'string' },
                  },
                },
                {
                  properties: {
                    stickType: {
                      enum: [
                        'BMP',
                      ],
                    },
                    na: { title: 'NA', type: 'string' },
                    k: { title: 'K', type: 'string' },
                    ci: { title: 'CI', type: 'string' },
                    co2: { title: 'CO2', type: 'string' },
                    bun: { title: 'BUN', type: 'string' },
                    cr: { title: 'Cr', type: 'string' },
                    glu: { title: 'Glu', type: 'string' },
                    additionalComments: { title: 'Additional Comments', type: 'string' },
                  },
                },
                {
                  properties: {
                    stickType: {
                      enum: [
                        'Magnesium',
                      ],
                    },
                    ca: { title: 'Ca', type: 'string' },
                    mg: { title: 'Mg', type: 'string' },
                    p: { title: 'P', type: 'string' },
                    additionalComments: { title: 'Additional Comments', type: 'string' },
                  },
                },
                {
                  properties: {
                    stickType: {
                      enum: [
                        'LFT',
                      ],
                    },
                    tp: { title: 'TP', type: 'string' },
                    alb: { title: 'Alb', type: 'string' },
                    tbili: { title: 'T.Bili', type: 'string' },
                    dbili: { title: 'D.Bili', type: 'string' },
                    ast: { title: 'AST', type: 'string' },
                    alt: { title: 'ALT', type: 'string' },
                    alp: { title: 'ALP', type: 'string' },
                    additionalComments: { title: 'Additional Comments', type: 'string' },
                  },
                },
                {
                  properties: {
                    stickType: {
                      enum: [
                        'PT/Coag',
                      ],
                    },
                    pt: { title: 'PT', type: 'string' },
                    ptt: { title: 'PTT', type: 'string' },
                    inr: { title: 'INR', type: 'string' },
                    additionalComments: { title: 'Additional Comments', type: 'string' },
                  },
                },
                {
                  properties: {
                    stickType: {
                      enum: [
                        'Cholesterol',
                      ],
                    },
                    tchol: { title: 'T.Chol', type: 'string' },
                    hdl: { title: 'HDL', type: 'string' },
                    ldl: { title: 'LDL', type: 'string' },
                    trig: { title: 'Trig', type: 'string' },
                    additionalComments: { title: 'Additional Comments', type: 'string' },
                  },
                },
              ],
            },
            locked: {
              oneOf: [
                {
                  properties: {
                    locked: { enum: [true] },
                    question: {
                      title: 'Unlocked By:',
                      type: 'string',
                      $ref: '#/definitions/questions',
                    },
                  },
                },
                {
                  properties: {
                    locked: { enum: [false] },
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
  /* eslint-enable id-length */

  quizSchema: {
    type: 'object',
    properties: {
      quizzes: {
        title: 'Quizzes',
        maxItems: 1,
        type: 'array',
        items: {
          type: 'object',
          properties: {
            quizTitle: { title: 'Quiz Title', type: 'string' },
            quizData: {
              title: '',
              type: 'array',
              items: {
                type: 'object',
                title: 'Quiz Section',
                properties: {
                  answerChoice: { title: 'Item Has Answer Choices?', type: 'string', enum: ['Yes', 'No'] },
                },
                dependencies: {
                  answerChoice: {
                    oneOf: [
                      {
                        required: [
                          'question',
                        ],
                        properties: {
                          answerChoice: {
                            enum: ['Yes'],
                          },
                          question: {
                            title: 'Question',
                            type: 'string',
                          },
                          options: {
                            title: 'Answers',
                            type: 'array',
                            items: {
                              type: 'object',
                              required: [
                                'option',
                              ],
                              properties: {
                                option: {
                                  title: 'Answer Option',
                                  type: 'string',
                                },
                              },
                            },
                          },
                        },
                      },
                      {
                        properties: {
                          answerChoice: {
                            enum: ['No'],
                          },
                          explanation: { title: 'Explanation', type: 'string', minLength: 5 },
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const uiSchema = {

  hpi: {
    'ui:rootFieldId': 'hpiForm',
    title: {
      'ui:widget': 'inputWidget',
    },
    facultyTitle: {
      'ui:widget': 'inputWidget',
    },
    oneLiner: {
      'ui:widget': 'inputWidget',
    },
    complaint: {
      'ui:widget': 'inputWidget',
    },
    video: {
      'ui:field': 'videoFields',
    },
    intro: {
      'ui:widget': 'textAreaWidget',
      'ui:options': {
        rows: 5,
      },
    },
    reviewOfSystem: {
      'ui:widget': 'textAreaWidget',
      'ui:options': {
        rows: 5,
      },
    },
    pastMedical: {
      items: {
        history: {
          items: {
            'ui:widget': 'textarea',
            'ui:options': {
              rows: 5,
            },
          },
        },
      },
    },
    physicalExam: {
      vitals: { 'ui:field': 'vitals' },
      details: {
        items: {
          body: { 'ui:widget': 'textarea' },
        },
      },
      specialized_exam_details: {
        items: {
          body: { 'ui:widget': 'textarea' },
        },
      },
      images: {
        items: {
          media: { 'ui:field': 'imageUploader' },
        },
      },
    },
    summary: {
      'ui:options': {
        orderable: false,
        addable: true,
        removable: true,
      },
      items: {
        body: { 'ui:widget': 'froalaWidget' },
      },
    },
  },

  question: {
    'ui:rootFieldId': 'questionsForm',
    questions: {
      items: {
        longAnswer: {
          'ui:widget': 'froalaWidget',
          'ui:options': {
            rows: 14,
          },
        },
        lesson: {
          items: { 'ui:field': 'lessonLink' },
        },
        unlocks: {
          'ui:widget': 'checkboxes',
          'ui:options': {
            addable: false,
          },
        },
        postscript: { 'ui:widget': 'froalaWidget' },
      },
    },
  },

  emr: {
    'ui:rootFieldId': 'emrForm',
    emrs: {
      items: {
        details: {
          items: {
            rtf: {
              'ui:widget': 'froalaWidget',
              'ui:help': 'Only use RTF when Display Type is Vital Signs(one item only) or Single Column RTF',
            },
            labsShorthandRtf: {
              'ui:widget': 'froalaWidget',
            },
            imagingShorthandRtf: {
              'ui:widget': 'froalaWidget',
            },
            media: { 'ui:field': 'imageUploader' },
          },
        },
      },
    },
  },

  keyPoints_resources: {
    'ui:rootFieldId': 'kprForm',
    keyPoints: {
      items: { 'ui:widget': 'textarea' },
    },
    resources: {
      items: {
        text: { 'ui:widget': 'froalaWidget' },
      },
    },
  },

  hpMiner: {
    'ui:rootFieldId': 'hpMiner',
    hpMiners: {
      'ui:options': {
        orderable: false,
        addable: false,
        removable: true,
      },
      summaries: {
        items: {
          body: { 'ui:widget': 'froalaWidget' },
        },
      },
      patientHistory: {
        items: {
          body: { 'ui:widget': 'froalaWidget' },
        },
      },
      physical: {
        items: {
          body: { 'ui:widget': 'froalaWidget' },
        },
      },
    },
  },

  labSticksUI: {
    'ui:rootFieldId': 'labSticks',
    labSticks: {
      'ui:options': {
        orderable: false,
        addable: true,
        removable: true,
      },
      items: {
        stickType: { 'ui:widget': 'select' },
        additionalComments: { 'ui:widget': 'froalaWidget' },
      },
    },
  },

  quiz: {
    'ui:rootFieldId': 'quizzes',
    quizzes: {
      'ui:options': {
        orderable: false,
        addable: true,
        removable: true,
      },
      items: {
        quizData: {
          items: {
            question: { 'ui:widget': 'froalaWidget' },
            explanation: { 'ui:widget': 'froalaWidget' },
            answerChoice: { 'ui:widget': 'select' },
          },
        },
      },
    },
  },
};

// default data
const formData = {
  pastMedical: [
    {
      title: 'Medical History',
    },
    {
      title: 'Surgical History',
    },
    {
      title: 'Family History',
    },
    {
      title: 'Social History',
    },
  ],
  physicalExam: {
    vitals: {},
    details: [
      { title: 'Gen' },
      { title: 'HEENT' },
      { title: 'Cards' },
      { title: 'Pulm' },
      { title: 'Abdo' },
      { title: 'Extrem' },
    ],
  },
  summary: [
    {
      title: 'Summary 1',
    },
    {
      title: 'Summary 2',
    },
    {
      title: 'PmHx',
    },
    {
      title: 'Medications',
    },
    {
      title: 'PsHx',
    },
    {
      title: 'Allergies',
    },
    {
      title: 'FamHx',
    },
    {
      title: 'SocHx',
    },
    {
      title: 'Physical',
    },
  ],
  hpMiners: {
    summaries: [
      {
        title: 'Summary 1',
      },
      {
        title: 'Summary 2',
      },
    ],
    patientHistory: [
      {
        title: 'PmHx',
      },
      {
        title: 'Medications',
      },
      {
        title: 'PsHx',
      },
      {
        title: 'Allergies',
      },
      {
        title: 'FamHx',
      },
      {
        title: 'SocHx',
      },
    ],
    physical: [
      {
        title: 'Physical',
      },
      {
        title: 'Vital Signs',
      },
    ],
  },
  emrs: [
    {
      title: 'Labs',
      displayType: 'content-gallery',
    },
    {
      title: 'Imaging',
      displayType: 'image-flip',
    },
    {
      title: 'Additional Studies',
      displayType: 'content-gallery',
    },
    {
      title: 'Relevant Clinical Cause',
      displayType: 'content-gallery',
    },
  ],
  quizzes: [
    {
      quizData: [
        {
          options: [
            {
              option: 'Sick',
            },
            {
              option: 'Not Sick',
            },
            {
              option: 'On the fence',
            },
          ],
        },
      ],
    },
  ],
};

const document = {
  schemaVersion: '0.5.1',
  formData,
};

export const caseDefaults = {
  document,
  formData,
};
