import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import swal from 'sweetalert';

import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import Answers from '../Answers/Answers';

import Dropdown from '../../UI/reduxFormElements/Dropdown/Dropdown';
import FroalaEditor from '../../UI/reduxFormElements/FroalaEditor/FroalaEditor';
import { Button } from '../../UI/Button/Button';
import { required, atLeastOneItem, listItemsHaveValue } from '../../../validation';
import {
  SpacingContainer,
  CenteredButton,
  DeleteQuestionButton,
  OrderButton,
  NotificationText,
} from './Form.styles';

const EmbeddedQuestion = ({
  question,
  index,
  fields,
  onDeleteQuestion,
  params,
}) => {
  const [currentTab, setCurrentTab] = useState('prompt');

  const handleTabClick = (name) => {
    setCurrentTab(name);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    if (onDeleteQuestion && fields.get(index).id) {
      onDeleteQuestion(index, fields);
    }
  };

  return (
    <Fragment>
      <div style={{ display: 'none' }}>
        <Field
          name="id"
          component="input"
        />
      </div>
      <Tabs
        activeTab={currentTab}
        onSelect={handleTabClick}
      >
        <Tab
          id="prompt"
          name="Prompt"
        >
          <SpacingContainer>
            <Field
              name={`${question}.educational_objective`}
              component={FroalaEditor}
              label="Educational objective"
            />
            <Field
              name={`${question}.vignette`}
              component={FroalaEditor}
              label="Vignette"
            />
            <Field
              name={`${question}.interrogatory`}
              component={FroalaEditor}
              label="Interrogatory"
              validate={required}
            />
            <Field
              name={`${question}.physician_tasks`}
              component={Dropdown}
              data={params.physician_tasks}
              placeholder="Select a Physician Task"
              label="Physician tasks"
            />
          </SpacingContainer>
        </Tab>
        <Tab
          id="answers"
          name="Answers"
        >
          <SpacingContainer>
            <FieldArray
              name={`${question}.answers`}
              component={Answers}
              questionKey={question}
              validate={[required, atLeastOneItem, listItemsHaveValue]}
            />
          </SpacingContainer>
        </Tab>
      </Tabs>
      <CenteredButton>
        <DeleteQuestionButton onClick={handleDeleteClick}>
          {index === 0 ?
            <div>Delete Question</div>
          :
            <div>Delete Linked Question</div>
          }
        </DeleteQuestionButton>
      </CenteredButton>
    </Fragment>
  );
};

EmbeddedQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.shape({}).isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  params: PropTypes.shape({
    physician_tasks: PropTypes.array,
  }).isRequired,
};

const EmbeddedQuestionFormRepeatables = ({
  fields,
  onSubmitQuestion,
  onDeleteQuestion,
  params,
  addClickHandler,
}) => {
  const canAddQuestions = fields.length === 0 || Boolean(fields.get(0).id);
  const handleAddField = (e) => {
    e.preventDefault();
    if (!canAddQuestions) {
      return swal({
        title: 'Error',
        text: 'Please save parent question before adding more prompts.',
        icon: 'warning',
      });
    }
    addClickHandler();

    return fields.push({
      answers: Array(5).fill({}),
    });
  };

  return (
    <Fragment>
      {fields.map((question, index, allFields) => (
        <EmbeddedQuestion
          key={question}
          question={question}
          index={index}
          fields={allFields}
          onSubmitQuestion={onSubmitQuestion}
          onDeleteQuestion={onDeleteQuestion}
          params={params}
        />
      ))}
      <hr />
      <CenteredButton>
        <Button onClick={handleAddField}>Add linked question</Button>
      </CenteredButton>
    </Fragment>
  );
};

EmbeddedQuestionFormRepeatables.propTypes = {
  addClickHandler: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  params: PropTypes.shape({
    physician_tasks: PropTypes.array,
  }).isRequired,
};

export default EmbeddedQuestionFormRepeatables;
