/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  formData: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

const urlRoot = 'https://onlinemeded.org';

class LessonLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.formData };
  }

  onChange(name) {
    return (event) => {
      this.setState({
        [name]: event.target.value,
      }, () => this.props.onChange(this.state));
    };
  }

  render() {
    const {
      displayText, topicSlug, lessonSlug,
    } = this.state;
    return (
      <div>
        <input
          type="text"
          className="form-control"
          style={{ width: '50%', display: 'inline' }}
          placeholder="topic slug"
          value={topicSlug}
          onChange={this.onChange('topicSlug')} />
        <input
          type="text"
          className="form-control"
          style={{ width: '50%', display: 'inline' }}
          placeholder="lesson slug "
          value={lessonSlug}
          onChange={this.onChange('lessonSlug')} />
        <p>
          <input
            type="text"
            className="form-control"
            style={{ width: '100%' }}
            placeholder="link display text "
            value={displayText}
            onChange={this.onChange('displayText')} />
        </p>
        <p>
          <a href={`${urlRoot}/${topicSlug}/${lessonSlug}`} rel="noopener noreferrer" target="_blank">
            { topicSlug && lessonSlug ? displayText || `${urlRoot}/${topicSlug}/${lessonSlug}` : '' }
          </a>
        </p>
      </div>
    );
  }
}

LessonLink.propTypes = propTypes;

export default LessonLink;
