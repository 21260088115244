import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '../sharedStyles';

import { CheckboxContainer, InputContainer, CheckboxVisual } from './Checkbox.styles';

const Checkbox = ({
  input,
  label,
  disabled,
}) => (
  <CheckboxContainer>
    <InputContainer>
      <input
        type="checkbox"
        className="checkbox-input"
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        id={input.name}
        disabled={disabled}
      />
      <CheckboxVisual
        checked={input.value}
      >
        <span className="checkbox-inner" />
      </CheckboxVisual>
    </InputContainer>
    {label && (
      <Label htmlFor={input.name}>{label}</Label>
    )}
  </CheckboxContainer>
);

Checkbox.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: null,
  disabled: false,
};

export default Checkbox;
