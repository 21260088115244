import styled from 'styled-components';

export const UnresolvedSpan = styled.span`
    color: red;
`;

export const ResolvedSpan = styled.span`
    color: green;
`;

export const Controls = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 50%;
    left: 50%;

    button {
        margin: 0 auto;
    }
`;
