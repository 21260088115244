import axios from 'axios/index';
import * as constants from '../constants';

const baseUrl = '/api/v1/propertyApi';

export function fetchSingle(component, slug, params = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_SINGLE });
    axios.get(`${baseUrl}/${component}/${slug}`, {
      params,
    })
      .then((response) => {
        dispatch({
          type: constants.FETCH_SINGLE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_SINGLE_ERROR, payload: err });
      });
  };
}

export function fetchMonthly(component) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_MONTHLY });
    axios.get(`${baseUrl}/${component}/monthly`)
      .then((response) => {
        dispatch({
          type: constants.FETCH_MONTHLY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_MONTHLY_ERROR, payload: err });
      });
  };
}

export function fetchAll(component, optionalParams = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_ALL });
    axios.get(`${baseUrl}/${component}`, {
      params: optionalParams,
    })
      .then((response) => {
        dispatch({
          type: constants.FETCH_ALL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_ALL_ERROR, payload: err });
      });
  };
}

export function fetchMrr(component) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_MRR });
    axios.get(`${baseUrl}/${component}/mrr`)
      .then((response) => {
        dispatch({
          type: constants.FETCH_MRR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_MRR_ERROR, payload: err });
      });
  };
}

export function fetchTotalAverageLTV(component, optionalParams = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_TOTAL_AVERAGE_LTV });
    axios.get(`${baseUrl}/${component}/average-ltv`, {
      params: optionalParams,
    })
      .then((response) => {
        dispatch({
          type: constants.FETCH_TOTAL_AVERAGE_LTV_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_TOTAL_AVERAGE_LTV_ERROR, payload: err });
      });
  };
}

export function fetchFirstPurchaseLTV(component, optionalParams = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_FIRST_PURCHASE_LTV });
    axios.get(`${baseUrl}/${component}/purchase-ltv`, {
      params: optionalParams,
    })
      .then((response) => {
        dispatch({
          type: constants.FETCH_FIRST_PURCHASE_LTV_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: constants.FETCH_FIRST_PURCHASE_LTV_ERROR, payload: err });
      });
  };
}
