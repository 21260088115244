/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Radio from '../../UI/reduxFormElements/Radio/Radio';

import {
  QuestionPreviewContainer,
  Title,
  Paragraph,
  AnswerContainer,
} from './Preview.styles';

const ExpandableQuestion = ({ title, index, children }) => {
  const [expanded, setExpanded] = useState(index === 0);

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div>
      <Title clickable onClick={toggleExpanded}>{title}</Title>
      {expanded && <div>{children}</div>}
    </div>
  );
};

ExpandableQuestion.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ExpandableQuestion.defaultProps = {
  title: null,
};

const EmbeddedQuestionPreview = ({
  questions,
  explanation,
  teaching_point: teachingPoint,
}) => (
  <QuestionPreviewContainer>
    <Title>Question Preview</Title>
    <hr />
    <div>
      {questions && questions.map((question, index) => (
        <Fragment key={`question[${index}]`}>
          <ExpandableQuestion index={index} title={`Part ${index + 1}`}>
            <Paragraph dangerouslySetInnerHTML={{ __html: question.vignette }} />
            <Paragraph dangerouslySetInnerHTML={{ __html: question.interrogatory }} />
            <div>
              {question.answers && question.answers.map((answer, answerIndex) => (
                answer.value &&
                <AnswerContainer key={`answer-${answer.value}-${answerIndex}`}>
                  <Radio
                    input={{
                      name: `question[${index}].answer`,
                      value: `question[${index}].answer`,
                    }}
                  />
                  <span dangerouslySetInnerHTML={{ __html: answer.value }} />
                </AnswerContainer>
              ))}
            </div>
          </ExpandableQuestion>
          {Object.keys(question).length > 0 && <hr />}
        </Fragment>
      ))}
      {(explanation || teachingPoint) && (
        <Title>Explanation</Title>
      )}
      <Paragraph dangerouslySetInnerHTML={{ __html: explanation }} />
      <Paragraph dangerouslySetInnerHTML={{ __html: teachingPoint }} />
    </div>
  </QuestionPreviewContainer>
);

EmbeddedQuestionPreview.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    vignette: PropTypes.string,
    interrogatory: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.string,
    })),
  })),
  explanation: PropTypes.string,
  teaching_point: PropTypes.string,
};

EmbeddedQuestionPreview.defaultProps = {
  questions: [],
  explanation: null,
  teaching_point: null,
};

export default EmbeddedQuestionPreview;
