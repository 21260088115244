/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Row, Col, Nav, NavItem } from 'react-bootstrap';

const propTypes = {
  emrs: PropTypes.instanceOf(Array),
};

const defaultProps = {
  emrs: [],
};


function listChartItems(items) {
  const arry = items || [];
  if (!Array.isArray(arry)) return '';
  return arry.map((item, i) => {
    let img = '';
    if (item.media && item.media.url) {
      img = (<img src={item.media.url} width={50} height={50} />);
    }
    return (<div key={i}>{img} <p>{item.text}</p></div>);
  });
}

class CaseEmrPreview extends React.PureComponent {
  render() {
    const { emrs } = this.props;
    return (
      <Tab.Container id="emr" defaultActiveKey={0}>
        <Row className="clearfix">
          <Col lg={3}>
            <Nav bsStyle="pills" stacked>
              { emrs.map((emr, i) => (<NavItem key={i} eventKey={i}>{emr.title}</NavItem>)) }
            </Nav>
          </Col>
          <Col lg={9}>
            <Tab.Content animation>
              { emrs.map((emr, i) => (
                <Tab.Pane key={i} eventKey={i}> { listChartItems(emr.details) }</Tab.Pane>))
              }
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

CaseEmrPreview.propTypes = propTypes;
CaseEmrPreview.defaultProps = defaultProps;

export default CaseEmrPreview;
