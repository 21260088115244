import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, isDirty } from 'redux-form';

import {
  updateEmbeddedQuestion,
  saveEmbeddedQuestions,
  deleteEmbeddedQuestion,
  getEmbeddedQuestion,
  destroyCurrentQuestion,
  setUnapproved,
} from '../../../actions/qbank/embedded';
import EmbeddedForm from '../../../components/Qbank/Embedded/Form';

const EmbeddedEditContainer = (props) => {
  const questionId = props.match.params.id;
  const { currentQuestion } = props;

  useEffect(() => {
    props.reset();
  }, [currentQuestion]);

  useEffect(() => {
    props.getQuestion(questionId);
  }, [questionId]);

  useEffect(() => {
    return () => {
      props.destroy();
      props.destroyQuestion();
    }
  }, [])

  return (
    <EmbeddedForm
      {...props}
    />
  );
};

EmbeddedEditContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  getQuestion: PropTypes.func.isRequired,
  qbank: PropTypes.shape({
    currentQuestion: PropTypes.shape({}),
  }),
  initialize: PropTypes.func.isRequired,
};

EmbeddedEditContainer.defaultProps = {
  qbank: {},
};

const formDecoratedComponent = reduxForm({
  form: 'embedded_question',
  enableReinitialize: true,
})(EmbeddedEditContainer);

const formSelector = formValueSelector('embedded_question');

const mapStateToProps = state => ({
  questionData: {
    questions: formSelector(state, 'questions'),
    explanation: formSelector(state, 'explanation'),
    teachingPoint: formSelector(state, 'teachingPoint'),
    track: formSelector(state, 'track'),
    topic: formSelector(state, 'topic'),
  },
  currentQuestion: state.qbank?.currentQuestion,
  initialValues: {
    /**
     * Anything that goes in this object will be attempted to preload into
     * Redux form. The question data is coming from `getEmbeddedQuestion`
     * and saved in `reducers/qbankReducer`
     */
    ...(state.qbank && state.qbank.currentQuestion),
  },
});

export default connect(
  mapStateToProps,
  {
    /**
     * Should be an action for saving existing, rather than creating new. Maybe this can be the same action.
     */
    onSubmitQuestion: updateEmbeddedQuestion,
    onSaveQuestions: saveEmbeddedQuestions,
    onDeleteQuestion: deleteEmbeddedQuestion,
    getQuestion: getEmbeddedQuestion,
    destroyQuestion: destroyCurrentQuestion,
    addClickHandler: setUnapproved,
    dirty: isDirty('embedded_question'),
  },
)(formDecoratedComponent);
