import {
  QBANK_SAVE_QUESTION_TO_STORE,
  DESTROY_CURRENT_QUESTION_IN_STORE,
} from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case QBANK_SAVE_QUESTION_TO_STORE:
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case DESTROY_CURRENT_QUESTION_IN_STORE:
      return {
        ...state,
        currentQuestion: undefined,
      };
    default:
      return state;
  }
};
