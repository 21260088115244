import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ResolvedSpan,
  UnresolvedSpan,
  Controls,
} from './errorReportStyles';
import { resolveErrorReports } from '../../../actions/modelActions';
import { deleteTab } from '../../../actions/windowActions';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  isMinimized: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  resolveErrorReports: PropTypes.func.isRequired,
  deleteTab: PropTypes.func.isRequired,
};

class ErrorReport extends React.Component {
  OnResolve() {
    const {
      data,
      index,
    } = this.props;
    this.props.resolveErrorReports([data.id]);
    this.props.deleteTab(index);
  }

  render() {
    const {
      data,
      isMinimized,
    } = this.props;

    return (
      <div>
        { data.is_resolved ? <ResolvedSpan>Resolved</ResolvedSpan> : <UnresolvedSpan>Unresolved</UnresolvedSpan> }
        <p>{ data.reason }</p>
        { !isMinimized &&
          <Controls>
            <Button bsStyle="primary" onClick={() => this.OnResolve()}>Mark as Resolved</Button>
          </Controls>
        }
      </div>
    );
  }
}

ErrorReport.propTypes = propTypes;

export default connect(
  null,
  {
    resolveErrorReports,
    deleteTab,
  },
)(ErrorReport);
