import styled, { css } from 'styled-components';

export const RadioContainer = styled.div``;

export const InputContainer = styled.div`
  position: relative;
  height: 25px;
  width: 25px;

  .radio-input {
    height: 100%;
    width: 100%;
    opacity: 0;
    margin: 0;
    cursor: pointer;
  }
`;

export const RadioButtonVisual = styled.span`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 50px;
  pointer-events: none;
  border: 1px solid ${props => props.theme.color.form.border};
  padding: 2px;

  .radio-button-inner {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 2;
    border-radius: 50px;
  }

  ${props => props.checked && css`
    .radio-button-inner {
      background: ${props.theme.color.brand.main};
    }
  `}
`;
