export default function () {
  return {
    id: 'new',
    comments: [],
    data: {
      track_id: {
        data: 2,
        available: true,
      },
      category_id: {
        data: null,
        available: true,
      },
      topic_id: {
        data: null,
        available: true,
      },
      question: {
        data: '',
        available: true,
      },
      answer: {
        data: '',
        available: true,
      },
      imageurl: {
        data: '',
        available: true,
      },
      status: {
        data: 'Not Reviewed',
        available: true,
      },
    },
  };
}
