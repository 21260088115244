import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Div } from './linkingControlsStyles';
import { reorderModel, newModel } from '../../actions/modelActions';

const propTypes = {
  modelIndex: PropTypes.number.isRequired,
  panelObject: PropTypes.instanceOf(Object).isRequired,
  models: PropTypes.instanceOf(Object).isRequired,
  newModel: PropTypes.instanceOf(Object).isRequired,
  reorderModel: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  panelObject: state.navigation.panelObject,
  models: state.model.models,
});

class LinkingControls extends React.Component {
  constructor() {
    super();
    this.onMoveLinkedUp = this.onMoveLinkedUp.bind(this);
    this.onMoveLinkedDown = this.onMoveLinkedDown.bind(this);
    this.onNewLinkedModel = this.onNewLinkedModel.bind(this);
  }

  onMoveLinkedUp() {
    const { panelObject, modelIndex } = this.props;
    this.props.reorderModel(panelObject.panelSlug, modelIndex, 'up');
  }

  onMoveLinkedDown() {
    const { panelObject, modelIndex } = this.props;
    this.props.reorderModel(panelObject.panelSlug, modelIndex, 'down');
  }

  onNewLinkedModel() {
    const { panelObject, modelIndex } = this.props;
    this.props.newModel(panelObject.panelSlug, modelIndex);
  }

  render() {
    const { panelObject, models, modelIndex } = this.props;

    return (
      <Div>
        <Button
          bsStyle="default"
          bsSize="small"
          onClick={this.onMoveLinkedUp}
          disabled={modelIndex === 0}
        >
                    Move Up
        </Button>
        <Button
          bsStyle="default"
          bsSize="small"
          onClick={this.onMoveLinkedDown}
          disabled={modelIndex === models.length - 1}
        >
                    Move Down
        </Button>
        <Button
          bsStyle="default"
          bsSize="small"
          onClick={this.onNewLinkedModel}
        >
                    Add New Linked {panelObject.panelSingularName}
        </Button>
      </Div>
    );
  }
}

LinkingControls.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    reorderModel,
    newModel,
  },
)(LinkingControls);
