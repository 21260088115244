import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import EmbeddedNewContainer from '../../containers/Qbank/Embedded/New';
import EmbeddedEditContainer from '../../containers/Qbank/Embedded/Edit';

import MatchingNewContainer from '../../containers/Qbank/Matching/New';
import MatchingEditContainer from '../../containers/Qbank/Matching/Edit';

import { GlobalStyleInjector } from './styles';

const Qbank = ({ match }) => (
  <React.Fragment>
    <GlobalStyleInjector />
    <Switch>
      <Route path={`${match.path}/embedded/new`} component={EmbeddedNewContainer} />
      <Route path={`${match.path}/embedded/:id`} component={EmbeddedEditContainer} />
      <Route path={`${match.path}/matching/new`} component={MatchingNewContainer} />
      <Route path={`${match.path}/matching/:id`} component={MatchingEditContainer} />
      <Redirect to={match.path} />
    </Switch>
  </React.Fragment>
);

Qbank.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default Qbank;
