import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { Div } from './selectStyles';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: null,
  disabled: false,
};

class Select extends React.Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  onChange(selectedOption) {
    this.props.onChange({
      [this.props.name]: selectedOption.value,
    });
  }

  render() {
    const {
      value,
      label,
      options,
      disabled,
    } = this.props;
    // use type independent equality in findIndex because numeric values are inconsistently passed as strings.
    // eslint-disable-next-line eqeqeq
    const selectedOption = value ? options[options.findIndex(option => option.value == value)] : options[0];
    return (
      <Div>
        <label>{label}</label><br />
        <ReactSelect
          value={selectedOption}
          options={options}
          onChange={this.onChange}
          isDisabled={disabled}
        />
      </Div>
    );
  }
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
