import React from 'react';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

export default property => ({
  default: 'full',
  full: [
    {
      Header: 'ID',
      accessor: 'id',
      width: 50,
      Cell: id => (<Link to={`/${property}/auditions/audition/${id.value}`}>{id.value}</Link>),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ['id'] }),
      filterAll: true,
    },
    {
      Header: 'Track',
      accessor: 'track.name',
      width: 125,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ['name'] }),
      filterAll: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 200,
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 300,
    },
    {
      Header: 'Payment Method',
      accessor: 'payment_method',
      width: 125,
    },
    {
      Header: 'Payment Email',
      accessor: 'payment_email',
      width: 125,
    },
    {
      Header: 'Submitter Type',
      accessor: 'submitter_type',
      filterable: false,
    },
    {
      Header: 'Submitted At',
      accessor: 'created_at',
      width: 150,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      Cell: (item) => {
        let color;
        switch (item.value) {
          case 'Approved':
            color = '#2ab27b';
            break;
          case 'Denied':
            color = '#bf5329';
            break;
          default:
            color = '#636b6f';
        }
        return (
          <div
            style={{ color }}
          >
            {item.value}
          </div>
        );
      },
    },
  ],
  simplified: [
    {
      Header: 'ID',
      accessor: 'id',
      width: 50,
      Cell: id => (<Link to={`/${property}/auditions/audition/${id.value}`}>{id.value}</Link>),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ['id'] }),
      filterAll: true,
    },
    {
      Header: 'Track',
      accessor: 'track.name',
      width: 125,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ['name'] }),
      filterAll: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 200,
    },
    {
      Header: 'Submitted At',
      accessor: 'created_at',
      width: 150,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      Cell: (item) => {
        let color;
        switch (item.value) {
          case 'Approved':
            color = '#2ab27b';
            break;
          case 'Denied':
            color = '#bf5329';
            break;
          default:
            color = '#636b6f';
        }
        return (
          <div
            style={{ color }}
          >
            {item.value}
          </div>
        );
      },
    },
  ],
}
);
