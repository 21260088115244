import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import './bootstrap';
import Main from './components/Main';
import store from './store';

import theme from './theme';

const app = document.getElementById('root');

if (app) {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </Provider>
    , app,
  );
}
