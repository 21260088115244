import styled from 'styled-components';

export const Div = styled.div`

`;

export const Label = styled.label`
    padding-right: 10px;
`;

export const StyledInput = styled.input`
    width: 85%;
    height: 35px;
    float: right;
`;
