import React from 'react';
// TODO: rename Panel to distinguish from Bootstrap and better identify purpose with state routing
// TODO: rethink react-router deep integration into redux or use connected-react-router
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Audition from '../Audition';
import Editor from '../Editor';
import Overview from '../Overview';
import Uploader from '../Uploader';
import Subscription from '../Subscription';
import Order from '../Order';
import CompanyRevenue from '../CompanyRevenue';
import { MainPage } from './styles';
import CaseEditor from '../Editor/caseEditor';

const propTypes = {
  isNavFetched: PropTypes.bool.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  user: state.user.user,
  navigation: state.navigation.data,
  panelObject: state.navigation.panelObject,
  isNavFetched: state.navigation.fetched,
  model: state.model.object,
});

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentMap: {
        editor: <Editor key={props.match.url} />,
        audition: <Audition />,
        overview: <Overview match={props.match} />,
        uploader: <Uploader />,
        subscriptions: <Subscription />,
        orders: <Order />,
        companyRevenue: <CompanyRevenue />,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match !== this.props.match) {
      this.setState({
        componentMap: {
          ...this.state.componentMap,
          editor: <Editor key={nextProps.match.url} />,
          overview: <Overview match={nextProps.match} />,
        },
      });
    }
  }

  renderComponent() {
    const { panelSlug, component } = this.props.match.params;

    // Case Editor UI is not driven by database config like the others below.
    if (panelSlug === 'cases' && component === 'editor') {
      return <CaseEditor />;
    }

    if (this.state.componentMap[component]) {
      return this.state.componentMap[component];
    }

    return (
      <h1>404 Not Found</h1>
    );
  }

  render() {
    if (this.props.isNavFetched) {
      return (
        <MainPage>
          {this.renderComponent()}
        </MainPage>
      );
    }
    return (
      <MainPage>
        <h1>Loading...</h1>
      </MainPage>
    );
  }
}

Panel.propTypes = propTypes;

export default withRouter(connect(
  mapStateToProps,
  {},
)(Panel));
