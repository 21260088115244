import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import infoPanels from './infoPanels';
import { Main, Toolbar, Tabs, Tab, Controls, Panel, ResizeBar, StyledButton } from './styles';

import {
  selectTab,
  deleteTab,
  minimizeWindow,
  expandWindow,
  resizeStart,
  resizeTick,
  resizeEnd,
} from '../../actions/windowActions';

const propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  isMinimized: PropTypes.bool.isRequired,
  isResizing: PropTypes.bool.isRequired,
  selected: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  expandWindow: PropTypes.func.isRequired,
  minimizeWindow: PropTypes.func.isRequired,
  resizeStart: PropTypes.func.isRequired,
  resizeEnd: PropTypes.func.isRequired,
  resizeTick: PropTypes.func.isRequired,
  selectTab: PropTypes.func.isRequired,
  deleteTab: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tabs: state.window.tabs,
  isMinimized: state.window.isMinimized,
  isResizing: state.window.isResizing,
  selected: state.window.selected,
  height: state.window.height,
});

class Window extends React.Component {
  constructor(props) {
    super(props);
    this.onExpandWindow = this.onExpandWindow.bind(this);
    this.onMinimizeWindow = this.onMinimizeWindow.bind(this);
    this.onDragResize = this.onDragResize.bind(this);
    this.onDragEndResize = this.onDragEndResize.bind(this);
  }

  componentDidMount() {
    // Sets key down shortcut keys
    window.addEventListener('keydown', (e) => {
      // If window is open and ctrl+z is pressed then expand or minimize the window
      if (this.props.tabs.length > 0 && e.keyCode === 90 && e.ctrlKey) {
        if (this.props.isMinimized) { this.props.expandWindow(); } else { this.props.minimizeWindow(); }
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown');
  }

  onTabClick(index) {
    return () => this.props.selectTab(index);
  }

  onDeleteTab(index) {
    return () => this.props.deleteTab(index);
  }

  onMinimizeWindow() {
    this.props.minimizeWindow();
  }

  onExpandWindow() {
    this.props.expandWindow();
  }

  onDragStartResize(e) {
    e.nativeEvent.dataTransfer.setDragImage(new Image(0, 0), 0, 0);
    this.props.resizeStart({
      x: e.clientX,
      y: e.clientY,
    });
  }

  onDragEndResize() {
    this.props.resizeEnd();
  }

  onDragResize(e) {
    this.props.resizeTick({
      x: e.clientX,
      y: e.clientY,
    });
  }

  render() {
    const {
      tabs, selected, isMinimized, isResizing, height,
    } = this.props;

    if (tabs.length > 0) {
      const InfoPanel = infoPanels(tabs[selected].type);

      return (
        <Main isMinimized={isMinimized} isResizing={isResizing} height={height} >
          <ResizeBar
            draggable
            onDragStart={e => this.onDragStartResize(e)}
            onDragEnd={this.onDragEndResize}
            onDrag={e => this.onDragResize(e)}
          />
          <Toolbar>
            <Tabs>
              { tabs.map((tab, index) => (
                <Tab
                  key={`${tab.type}-${tab.data.id}`}
                  isSelected={index === selected}
                  onClick={this.onTabClick(index)}
                >
                  <span>{tab.type} {tab.data.id}</span>
                  <StyledButton onClick={this.onDeleteTab(index)}>X</StyledButton>
                </Tab>
              ))
              }
            </Tabs>
            <Controls>
              { isMinimized
                ? <button onClick={this.onExpandWindow}>Expand</button>
                : <button onClick={this.onMinimizeWindow}>Minimize</button> }
            </Controls>
          </Toolbar>
          {/* Custom component based on type of tab, e.g., error_report, question  */}
          <Panel>
            <InfoPanel
              data={tabs[selected].data}
              index={selected}
              isMinimized={isMinimized} />
          </Panel>
        </Main>
      );
    }

    return null;
  }
}

Window.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    selectTab,
    deleteTab,
    minimizeWindow,
    expandWindow,
    resizeStart,
    resizeTick,
    resizeEnd,
  },
)(Window);
