/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Div, Contents } from './formMessageStyles';


const propTypes = {
  message: PropTypes.instanceOf(Object),
};

const defaultProps = {
  message: {},
};

const maxMessage = 500;

const renderErrors = errors => (
  <ul style={{ textAlign: 'left' }}>
    {/* handle api errors */}
    {errors && Array.isArray(errors) && errors.map((error, index) => (
      <li key={`error-${index}`}>{error}</li>
      ))}
    {/* handle legacy api errors?  not sure what generates this format */}
    {typeof errors === 'object' && Object.keys(errors).map(key => (
        Array.isArray(errors[key]) && errors[key].map((error, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${key}-error-${index}`}>{error}</li>
        ))))}
  </ul>
);

export default class FormMessage extends React.Component {
  constructor(props) {
    super(props);
    this.dismissDetailsAlert = this.dismissDetailsAlert.bind(this);
    this.state = {
      showDetailsAlert: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.message !== this.props.message) {
      const showDetailsAlert = !!(nextProps.message.subcontent || nextProps.message.errors);
      this.setState({
        showDetailsAlert,
      });
    }
  }

  dismissDetailsAlert() {
    this.setState({ showDetailsAlert: false });
  }

  render() {
    const { message } = this.props;
    let longMessage = '';

    if (message && (message.contents || message.subcontent)) {
      if (message.subcontent) {
        longMessage = message.subcontent.substr(0, maxMessage);
      }
      return (
        <Div>
          <Div backgroundColor={message.color}>
            <Contents data-cy="case-status">{message.contents}</Contents>
          </Div>
          <SweetAlert
            title="Warning"
            type="warning"
            show={this.state.showDetailsAlert}
            onConfirm={this.dismissDetailsAlert}
            style={{ width: '800px', maxHeight: '600px' }}>
            { longMessage }
            { renderErrors(message.errors) }
          </SweetAlert>
        </Div>
      );
    }
    return (<Div />);
  }
}

FormMessage.propTypes = propTypes;
FormMessage.defaultProps = defaultProps;
