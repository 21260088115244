import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchReelDxVideo } from '../../../actions/caseActions';
import { debounce } from '../../../utils';

const propTypes = {
  fetchReelDxVideo: PropTypes.func.isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  formContext: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

class ReelDxVideo extends React.Component {
  constructor(props) {
    super(props);
    this.handleVideoIdChange = this.handleVideoIdChange.bind(this);
    this.state = { ...props.formData };
  }

  componentWillReceiveProps(nextProps) {
    if (this.urlsHaveChanged(nextProps)) {
      const { videoUrl, posterImageUrl, srtUrl, disclaimer } = nextProps.formContext.videoInputs;
      this.setState({
        ...this.state,
        reelDxId: nextProps.formContext.videoInputs.videoUrl ? this.state.reelDxId : '',
        videoUrl: videoUrl || '',
        imageUrl: posterImageUrl || '',
        srtUrl: srtUrl || '',
        disclaimer: disclaimer || '',
      }, () => this.props.onChange(this.state));
    } else if (nextProps.formData !== this.props.formData) {
      const {
        reelDxId,
        videoUrl,
        imageUrl,
        srtUrl,
        disclaimer,
      } = nextProps.formData;
      this.setState({
        reelDxId: reelDxId || '',
        videoUrl: videoUrl || '',
        imageUrl: imageUrl || '',
        srtUrl: srtUrl || '',
        disclaimer: disclaimer || '',
      });
    }
  }

  onChange(name) {
    return (event) => {
      this.setState({
        [name]: event.target.value,
      }, () => this.props.onChange(this.state));
    };
  }

  urlsHaveChanged(nextProps) {
    return nextProps.formContext.videoInputs.videoUrl !== this.props.formContext.videoInputs.videoUrl
      || nextProps.formContext.videoInputs.posterImageUrl !== this.props.formContext.videoInputs.posterImageUrl
      || nextProps.formContext.videoInputs.srtUrl !== this.props.formContext.videoInputs.srtUrl;
  }

  handleVideoIdChange(event) {
    const reelDxId = event.target.value;
    if (reelDxId.length >= 1) {
      debounce(this.props.fetchReelDxVideo(reelDxId), 300);
    }
    this.setState({
      reelDxId,
    }, () => this.props.onChange(this.state));
  }

  render() {
    const {
      reelDxId,
      videoUrl,
      imageUrl,
      srtUrl,
      disclaimer,
    } = this.state;

    return (
      <div>
        <label htmlFor="reel-dx-video-id">
          <strong>Reel DX Video ID</strong>
        </label>
        <input
          id="reel-dx-video-id"
          data-cy="case_hpiForm_reelDxId"
          className="form-control"
          placeholder="enter id to auto-populate video url and poster image url"
          onChange={this.handleVideoIdChange}
          value={reelDxId} />

        <label htmlFor="reel-dx-video-url">
          <strong>Video URL</strong>
        </label>
        <input
          id="reel-dx-video-url"
          data-cy="case_hpiForm_videoUrl"
          className="form-control"
          onChange={this.onChange('videoUrl')}
          value={videoUrl} />

        <label htmlFor="reel-dx-video-poster-image-url">
          <strong>Poster Image URL</strong>
        </label>
        <input
          id="reel-dx-video-poster-image-url"
          data-cy="case_hpiForm_posterImageUrl"
          className="form-control"
          onChange={this.onChange('imageUrl')}
          value={imageUrl} />

        <label htmlFor="reel-dx-video-srt-url">
          <strong>Closed Caption URL</strong>
        </label>
        <input
          id="reel-dx-video-srt-url"
          data-cy="case_hpiForm_srtUrl"
          className="form-control"
          onChange={this.onChange('srtUrl')}
          value={srtUrl} />

        <label htmlFor="reel-dx-video-disclaimer">
          <strong>Disclaimer</strong>
        </label>
        <textarea
          id="reel-dx-video-disclaimer"
          data-cy="case_hpiForm_disclaimer"
          className="form-control"
          onChange={this.onChange('disclaimer')}
          value={disclaimer} />
      </div>
    );
  }
}

ReelDxVideo.propTypes = propTypes;

export default connect(
  null,
  { fetchReelDxVideo },
)(ReelDxVideo);
