import styled from 'styled-components';

export const Div = styled.div`

`;

export const Table = styled.div`
  display: table;
  margin: 20px 5px;
  width: 100%;
`;

export const Row = styled.div`
  display: table-row;
  margin: 10px 5px;
  height: 25px;
`;

export const Header = styled.span`
  display: table-cell
  font-weight: 600;
  padding: 5px 10px;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
`;

export const Data = styled.span`
  display: table-cell;
  border-bottom: 1px solid grey;
  padding: 5px 10px;
`;

export const AcceptDenyTab = styled.div`
  margin: 25px 20px;

  button {
    margin: 5px 10px;
  }
`;

export const OptionalMessageHeader = styled.span`
  display: block;
`;

export const OptionalMessageSpan = styled.span`
  display: block;
  font-style: italic;
  font-size: 12px;
`;

export const OptionalMessage = styled.textarea`
  width: 65%;
  height: 100px;
  max-width: 100%;
  max-height: 250px;
  min-width: 25%;
  min-height: 50px;
`;

export const ReviewButtons = styled.div`

`;
