/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import { Image } from './questionStyles';

const mapStateToProps = state => ({
  params: state.model.params,
});

class QuestionPreviewer extends React.Component {
  render() {
    const { data } = this.props.model;

    return (
      <div>
        <ImageStrippedFromHTML html={data.vignette.data} />
        <p dangerouslySetInnerHTML={{ __html: data.interrogatory.data }} />
        <AnswerList
          answers={[
                        data.Answer1.data,
                        data.Answer2.data,
                        data.Answer3.data,
                        data.Answer4.data,
                        data.Answer5.data,
                        data.Answer6.data,
                        data.Answer7.data,
                        data.Answer8.data,
                    ]}
          correctAnswer={data.correctanswer.data}
        />
      </div>
    );
  }
}

QuestionPreviewer.propTypes = {
  model: PropTypes.instanceOf(Object).isRequired,
};

export default connect(
  mapStateToProps,
  {},
)(QuestionPreviewer);

const imgSrcRegex = /src="([^"\s]*)/;

class ImageStrippedFromHTML extends React.Component {
  render() {
    const { html } = this.props;
    if (!html) {
      return (<div />);
    }

    const split = html.split(/<img |">/);
    const vignetteBreakout = [];
    const imageList = [];
    let imageIndex = 0;
    // find and extract img src in html for display in LightboxedImage
    split.forEach((item, i) => {
      if (item.indexOf('src') >= 0) {
        const matches = item.match(imgSrcRegex);
        if (!(matches && matches.length > 1)) {
          return;
        }
        const src = matches[1];

        vignetteBreakout.push({
          id: i, image: true, content: item, src, index: imageIndex,
        });
        imageList.push({ id: imageIndex, src, index: imageIndex });
        imageIndex += 1;
      } else {
        vignetteBreakout.push({ id: i, image: false, content: item });
      }
    });

    return (
      <div>
        {vignetteBreakout.map(item => (
          item.image
            ? <LightboxedImage image={item} imageList={imageList} />
            : <p dangerouslySetInnerHTML={{ __html: item.content }} key={item.id} />
        ))}
      </div>
    );
  }
}

ImageStrippedFromHTML.propTypes = {
  html: PropTypes.string,
};

ImageStrippedFromHTML.defaultProps = {
  html: null,
};

class LightboxedImage extends React.Component {
  constructor() {
    super();
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };
  }

  gotoPrevLightboxImage(currentImage) {
    if (currentImage > 0) {
      this.setState({ currentImage: currentImage - 1 });
    }
  }

  gotoNextLightboxImage(currentImage, imageCount) {
    if (currentImage < imageCount) {
      this.setState({ currentImage: currentImage + 1 });
    }
  }

  closeLightbox() {
    this.setState({ lightboxIsOpen: false });
  }

  renderLightbox(currentImage) {
    this.setState({ lightboxIsOpen: true });

    if (currentImage) {
      this.setState({ currentImage });
    } else {
      this.setState({ currentImage: 0 });
    }
  }

  render() {
    const { imageList, image } = this.props;
    const { currentImage, lightboxIsOpen } = this.state;

    return (
      <div>
        <Image
          src={image.src}
          onClick={() => this.renderLightbox(image.index)}
        />
        <Lightbox
          images={imageList}
          currentImage={currentImage}
          isOpen={lightboxIsOpen}
          onClickPrev={() => this.gotoPrevLightboxImage(currentImage)}
          onClickNext={() => this.gotoNextLightboxImage(currentImage, imageList.length)}
          onClose={() => this.closeLightbox()}
        />
      </div>
    );
  }
}

LightboxedImage.propTypes = {
  imageList: PropTypes.instanceOf(Array).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }).isRequired,
};

class AnswerList extends React.Component {
  render() {
    const { answers, correctAnswer } = this.props;
    return (
      <form>
        {answers.map((answer, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <div style={{ paddingLeft: '40px' }} key={i}>
            {(answer && (
            <div className="radio">
              <label>
                <input type="radio" value="1" readOnly checked={Number(correctAnswer) === i + 1} />
                {answer}
              </label>
            </div>
                            ))}
          </div>
                    ))}
      </form>
    );
  }
}

AnswerList.propTypes = {
  answers: PropTypes.instanceOf(Array).isRequired,
  correctAnswer: PropTypes.string,
};

AnswerList.defaultProps = {
  correctAnswer: null,
};
