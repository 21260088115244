import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl,
  Glyphicon,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Badge,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Wrapper,
  NavTitle,
} from './styles';
import { fetchUser } from '../../actions/userActions';
import { logout } from '../../actions/authActions';
import { changeProperty, fetchNavigation } from '../../actions/navigationActions';
import { newModel } from '../../actions/modelActions';
import { newDocument } from '../../actions/caseActions';

React.Bootstrap = require('react-bootstrap');

const propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  isNavFetched: PropTypes.bool.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchNavigation: PropTypes.func.isRequired,
  changeProperty: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  newModel: PropTypes.func.isRequired,
  newDocument: PropTypes.func.isRequired,
  properties: PropTypes.instanceOf(Object),
  selectedProperty: PropTypes.string,
  notificationObjects: PropTypes.instanceOf(Object),
  currentLocation: PropTypes.string,
  showUnsavedChangesWarning: PropTypes.bool.isRequired,
};

const defaultProps = {
  properties: [],
  selectedProperty: null,
  notificationObjects: null,
  currentLocation: null,
};

const mapStateToProps = state => ({
  user: state.user.user,
  selectedProperty: state.navigation.property,
  notificationObjects: state.notifications.notificationObjects,
  properties: state.navigation.properties,
  isNavFetched: state.navigation.fetched,
  defaultProperty: state.navigation.defaultProperty,
  showUnsavedChangesWarning: state.navigation.showUnsavedChangesWarning,
});

const navElement = {
  display: 'flex',
  alignItems: 'center',
};

class AdminNav extends Component {
  constructor(props) {
    super(props);
    this.onChangeProperty = this.onChangeProperty.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    this.props.fetchNavigation();
    this.props.fetchUser();
  }

  onChangeProperty(event) {
    event.preventDefault();
    this.props.changeProperty(this.propertyEl.value);
  }

  // eslint-disable-next-line class-methods-use-this
  onLinkClick(e, linkPath) {
    return linkPath === this.props.currentLocation ? e.preventDefault() : null;
  }

  // Returns on click function if on click action is not undefined
  buildOnClickAction(onClickAction) {
    if (!onClickAction) {
      return null;
    }
    if (this.props.showUnsavedChangesWarning) {
      return null;
    }

    // Setting function variable to bind this to later
    let resultFunction = null;

    if (onClickAction.action === 'new' && onClickAction.target === 'cases') {
      resultFunction = () => {
        this.props.newDocument();
      };
    } else if (onClickAction.action === 'new') {
      resultFunction = () => {
        this.props.newModel(onClickAction.target);
      };
    }

    return resultFunction.bind(this);
  }

  logout() {
    this.props.logout();
  }

  render() {
    const {
      user,
      selectedProperty,
      properties,
      isNavFetched,
      notificationObjects,
    } = this.props;
    return (
      <Wrapper>
        <Navbar fluid>
          <Navbar.Brand style={navElement}>
            <span>Admin</span>
          </Navbar.Brand>

          <Nav style={navElement}>
            {isNavFetched && selectedProperty ? properties[selectedProperty].panels.map(item => (
              <NavDropdown
                data-cy={`panel-${item.slug}-${selectedProperty}`}
                key={item.slug}
                eventKey={item.slug}
                title={
                  <NavTitle>
                    <span>{item.name}</span>
                    { notificationObjects[item.slug] && notificationObjects[item.slug].count > 0 &&
                      <Badge>{notificationObjects[item.slug].count}</Badge>
                    }
                  </NavTitle>
                }
                id={item.slug}>
                {item.subitems.filter(subitem =>
                  subitem.settings === null || (subitem.settings && !subitem.settings.hide_in_navigation))
                  .map(subitem => (
                    <LinkContainer
                      key={`${item.slug}-${subitem.component}`}
                      onClick={e => this.onLinkClick(
                        e,
                        `/${selectedProperty}/${item.slug}/${subitem.component}`,
                      )}
                      to={`/${selectedProperty}/${item.slug}/${subitem.component}`}>
                      <MenuItem
                        data-cy={`subitem-${item.slug}-${subitem.component}-${selectedProperty}`}
                        eventKey={subitem.slug}
                        key={subitem.slug}
                        name={subitem.name}
                        onClick={this.buildOnClickAction(subitem.on_click_action)}>
                        {subitem.name}
                      </MenuItem>
                    </LinkContainer>
                  ))}
                {item.slug === 'questions' && (
                  <React.Fragment>
                    <hr />
                    <LinkContainer
                      key="default"
                      to="/onlinemeded/questions/editor"
                      >
                      <MenuItem
                        >
                        New Default
                      </MenuItem>
                    </LinkContainer>
                    <LinkContainer
                      key="embedded-question"
                      to="/qbank/embedded/new"
                      >
                      <MenuItem
                        >
                        New Embedded
                      </MenuItem>
                    </LinkContainer>
                    <LinkContainer
                      key="matching-question"
                      to="/qbank/matching/new"
                      >
                      <MenuItem
                        >
                        New Matching
                      </MenuItem>
                    </LinkContainer>
                  </React.Fragment>
                )}
              </NavDropdown>
            )) : null}
          </Nav>

          <Nav pullRight style={navElement}>
            {isNavFetched && selectedProperty && (
              <NavItem>
                <FormControl
                  componentClass="select"
                  onChange={this.onChangeProperty}
                  inputRef={(el) => {
                    this.propertyEl = el;
                  }}
                  value={selectedProperty}
                  data-cy="properties"
                >
                  {properties && Object.keys(properties).map(property => (
                    <option key={`property-${properties[property].id}`} value={properties[property].slug}>
                      {properties[property].name}
                    </option>))}
                </FormControl>
              </NavItem>
            )}
            <NavItem eventKey={1} href="#">
              <Glyphicon glyph="user" /> {user.fullName}
            </NavItem>
            <NavItem eventKey={2} href="#">
              <button className="btn btn-danger" onClick={this.logout} data-cy="logout">
                <Glyphicon glyph="log-out" /> Logout
              </button>
            </NavItem>
          </Nav>
        </Navbar>
      </Wrapper>
    );
  }
}

AdminNav.propTypes = propTypes;
AdminNav.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  {
    changeProperty,
    fetchUser,
    fetchNavigation,
    logout,
    newModel,
    newDocument,
  },
)(AdminNav);
