import styled from 'styled-components';

export const Div = styled.div`
    margin-left: 15px;

    button {
        margin: 0 2.5px;
    }

    button:focus {
        outline: 0;
    }
`;
