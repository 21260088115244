import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import AuditionDetails from './auditionDetails';
import Preview from '../Preview';
import { fetchModel } from '../../actions/modelActions';
import { decrementCount } from '../../actions/notificationActions';
import {
  MainPage,
  SubPanel,
  Panel,
  Header1,
  ApprovedMessage,
  DeniedMessage,
} from './styles';

const propTypes = {
  model: PropTypes.instanceOf(Object).isRequired,
  panelObject: PropTypes.instanceOf(Object).isRequired,
  fetchModel: PropTypes.instanceOf(Object).isRequired,
  basePath: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  params: state.model.params,
  panelObject: state.navigation.panelObject,
  model: state.model.object,
  basePath: state.navigation.basePath,
});

class Audition extends React.Component {
  componentWillMount() {
    const { panelObject } = this.props;
    this.props.fetchModel(panelObject.panelSlug, panelObject.itemId);
  }

  render() {
    const {
      model,
    } = this.props;

    if (!model) {
      return (
        <MainPage>
          ...Loading
        </MainPage>
      );
    }

    if (model.justViewed) {
      decrementCount('auditions');
    }
    decrementCount('auditions');
    return (
      <MainPage>
        <Header1>Audition</Header1>
        {model.status === 'Approved' && (
          <LinkContainer to={`${this.props.basePath}/questions/editor/${model.qbank_id}`} >
            <ApprovedMessage>This audition was approved, click to go to question</ApprovedMessage>
          </LinkContainer>
        )}
        {model.status === 'Denied' && (
          <DeniedMessage>This audition was denied</DeniedMessage>
        )}
        <Panel>
          <SubPanel>
            <AuditionDetails
              model={model}
            />
          </SubPanel>
          <SubPanel>
            <Preview
              model={model}
            />
          </SubPanel>
        </Panel>
      </MainPage>
    );
  }
}

Audition.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    fetchModel,
    decrementCount,
  },
)(Audition);
