import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import SweetAlert from 'react-bootstrap-sweetalert';
import { uploadImage } from '../../../actions/caseActions';

const propTypes = {
  uploadImage: PropTypes.func.isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  formContext: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  savedDocumentId: PropTypes.number.isRequired,
};

const maxImageSizeMB = 50;
const dropZoneHeight = '200px';
const dropzoneStyle = {
  height: dropZoneHeight, display: 'flex', alignItems: 'center', justifyContent: 'center',
};
const re = /(\.[^.]+)$/;

/*
  TODO: After Match Day 2019, consider moving the uploadImage axios post into this component.
  The current upload flow works like this:
  1. User adds a file to the dropzone.
  2. uploadImage redux action is fired and posts image to server.
  3. UPLOAD_IMAGE_SUCCESS reducer recieves the s3 url of the posted image and stores it in `uploads`.
  4. caseEditor updates formContext with the data from `uploads` redux state.
  5. json-react-schema-form (jrsf) passes formContext data down to all child components (namely this one).
  6. imageUploader updates the formData by calling jsrf's onChange handler with the new url in componentWillReceiveProps
  7. Sorry.
 */
class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.showDropzone = this.showDropzone.bind(this);
    this.onDismissUploadErrors = this.onDismissUploadErrors.bind(this);
    this.state = {
      ...props.formData,
      showDropzone: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const url = nextProps.formContext.uploads[this.state.name];
    if (url && !nextProps.formData.url) {
      const ext = re.exec(url)[0];
      let mediaType = 'image';
      if (ext === '.mp4') mediaType = 'video';
      this.setState({
        url,
        mediaType,
      }, () => this.props.onChange(this.state));
    } else {
      this.setState({
        ...nextProps.formData,
        name: nextProps.formData.name || '',
        url: nextProps.formData.url || '',
        mediaType: nextProps.formData.mediaType || '',
        showDropzone: false,
      });
    }
  }

  onDismissUploadErrors() {
    this.setState({ uploadError: null });
  }

  uploadFile(files) {
    const file = files[0];
    if (!this.props.savedDocumentId) {
      this.setState({
        uploadError: 'You must first save your new case before trying to upload an image or video.',
      });
      return;
    }
    if (file.size > maxImageSizeMB * 1024 * 1024) {
      this.setState({
        uploadError: `Max media size is ${maxImageSizeMB} MB`,
      });
      return;
    }
    const name = file.name.replace(/[\W_]+/g, '_');
    const { documentId } = this.props.formContext;
    this.setState({
      name,
    });
    this.props.uploadImage(file, name, documentId);
  }

  removeFile() {
    this.setState({
      name: '',
      url: '',
    }, () => this.props.onChange(this.state));
  }

  showDropzone() {
    this.setState({
      showDropzone: true,
    });
  }

  renderMedia(url, mediaType) {
    return (
      <div>
        {mediaType === 'video'
          ?
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video width="140" height="200" muted autoPlay loop>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          :
            <img src={url} style={{ maxHeight: dropZoneHeight }} />
        }
        <button style={{ display: 'block' }} onClick={this.removeFile}>Delete Image/Video</button>
      </div>
    );
  }

  renderDropzone() {
    return (
      this.state.showDropzone ?
        <Dropzone accept=".png, .jpg, .gif, .mp4" onDrop={this.uploadFile}>
          <p style={dropzoneStyle}>Image/Video</p>
        </Dropzone>
        : <button style={{ display: 'block' }} onClick={this.showDropzone}>Attach Image/Video</button>
    );
  }

  renderUploadErrors() {
    if (!this.state.uploadError) {
      return '';
    }
    return (
      <SweetAlert
        title="Upload Error"
        type="error"
        onConfirm={this.onDismissUploadErrors}>
        {this.state.uploadError}
      </SweetAlert>
    );
  }

  render() {
    const { name, url, mediaType } = this.state;
    return (
      <div>{name}
        {this.renderUploadErrors()}
        {url ? this.renderMedia(url, mediaType) : this.renderDropzone()}
      </div>
    );
  }
}

ImageUploader.propTypes = propTypes;

const mapStateToProps = state => ({
  savedDocumentId: state.cases.documentId,
});

export default connect(
  mapStateToProps,
  { uploadImage },
)(ImageUploader);
