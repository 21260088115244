/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Div,
  AnswersList,
  Explanation,
} from './auditionStyles';

const propTypes = {
  model: PropTypes.instanceOf(Object).isRequired,
};

class AuditionPreviewer extends React.Component {
  render() {
    const { model } = this.props;

    if (!model || JSON.stringify(model) === '{}') {
      return null;
    }

    return (
      <Div>
        <p dangerouslySetInnerHTML={{ __html: model.vignette }} />
        <p dangerouslySetInnerHTML={{ __html: model.question }} />
        <AnswersList>
          { model.answers.map((answer, index) => (
            <label key={`${answer}`} >
              <input
                type="radio"
                value="1"
                checked={Number(model.correct_answer) === index}
                readOnly />
              {answer}
            </label>
          ))}
        </AnswersList>
        <label>Explanation</label>
        <Explanation dangerouslySetInnerHTML={{ __html: model.explanation }} />
        <label>Teaching Point</label>
        <p dangerouslySetInnerHTML={{ __html: model.teaching_point }} />
      </Div>
    );
  }
}

AuditionPreviewer.propTypes = propTypes;

export default AuditionPreviewer;
