import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Div } from './styles';
import { uploadFile } from '../../actions/uploadActions';

const mapStateToProps = state => ({
  panelObject: state.navigation.panelObject,
});

class Uploader extends React.Component {
  constructor() {
    super();
    this.onUpload = this.onUpload.bind(this);
  }
  onUpload(e) {
    e.preventDefault();
    this.props.uploadFile(this.state.file);
  }

  onSelectFile(e) {
    if (e.target.files && e.target.files[0]) {
      this.setState({ file: e.target.files[0] });
    }
  }

  render() {
    return (
      <Div>
        <h1>UPLOADER!</h1>
        <form onSubmit={this.onUpload}>
          <input
            type="file"
            accept=".xlsx"
            onChange={this.onSelectFile}
          />
          <Button
            type="submit"
            bsStyle="primary"
          >Upload
          </Button>
        </form>
      </Div>
    );
  }
}

Uploader.propTypes = {
  uploadFile: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    uploadFile,
  },
)(Uploader);
