import React from 'react';
import PropTypes from 'prop-types';
import { Div } from './caseStyles';

const propTypes = {
  resources: PropTypes.instanceOf(Array),
};

const defaultProps = {
  resources: [],
};

class CaseResourcePreview extends React.PureComponent {
  render() {
    const { resources } = this.props;
    return (
      <Div>
        <h3>Resources</h3>
        {
          resources.map((resource, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={i}>
              <b>{resource.title}</b>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: resource.text }} />
              <a href={resource.link}>{resource.link}</a>
            </span>
            ))
        }
      </Div>
    );
  }
}

CaseResourcePreview.propTypes = propTypes;
CaseResourcePreview.defaultProps = defaultProps;

export default CaseResourcePreview;
