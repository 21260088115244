import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import analytic from './analyticReducer';
import auth from './authReducer';
import cases from './caseReducer';
import dataList from './overviewTableReducer';
import model from './modelReducer';
import navigation from './navigationReducer';
import notifications from './notifcationsReducer';
import permissions from './permissionsReducer';
import user from './userReducer';
import qbank from './qbankReducer';
import window from './windowReducer';

/**
 * Redux form reducer plugins
 */
import embeddedReducer from './formPlugins/qbank/embeddedQuestionReducer';
import matchingReducer from './formPlugins/qbank/matchingQuestionReducer';

export default combineReducers({
  analytic,
  auth,
  cases,
  dataList,
  form: formReducer.plugin({
    embedded_question: embeddedReducer,
    matching_question: matchingReducer,
  }),
  model,
  navigation,
  notifications,
  permissions,
  user,
  qbank,
  window,
});
