import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '../sharedStyles';

import { RadioContainer, InputContainer, RadioButtonVisual } from './styles';

const Radio = ({
  input,
  label,
}) => (
  <RadioContainer>
    {label && (
      <Label>{label}</Label>
    )}
    <InputContainer>
      <input
        type="radio"
        className="radio-input"
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        checked={input.checked}
      />
      <RadioButtonVisual
        checked={input.checked}
      >
        <span className="radio-button-inner" />
      </RadioButtonVisual>
    </InputContainer>
  </RadioContainer>
);

Radio.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
};

Radio.defaultProps = {
  label: null,
};

export default Radio;
