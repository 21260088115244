import axios from 'axios';
import swal from 'sweetalert';
import { change } from 'redux-form';
import history from '../../history';
import {
  QBANK_FORM_SAVE_ID_TO_QUESTION,
  QBANK_SAVE_QUESTION_TO_STORE,
  DESTROY_CURRENT_QUESTION_IN_STORE,
} from '../../constants';
import { generateValidationError, handleError, handleSuccess } from './actionUtils';
import { getIntegerFromString } from '../../utils';

const EMBEDDED_QUESTION_TYPE_ID = 2;

/**
 * Transforms the embedded question from a ReduxForm object
 * into something that the backend/database will accept.
 * @param {Object} form ReduxForm question form object
 * @param {Number} index Question's index in the `questions` FieldArray
 */
const transformToDatabaseFriendly = (form, index) => {
  const selectedQuestion = form.questions[index];

  /**
   * Here's where we modify the question outside of what can be handled with Redux Form
   */

  let nextQuestion = {
    ...selectedQuestion,
    question_type_id: EMBEDDED_QUESTION_TYPE_ID,
    /**
     * Important: `correctanswer` is stored to match the corresponding 'Answer<I>' column,
     * so if Answer3 is correct, `correctanswer` has to be 3, not 2 - hence the +1.
     */
    correctanswer: selectedQuestion.correctanswer ? parseInt(getIntegerFromString(selectedQuestion.correctanswer), 10) + 1 : undefined,
    linked_id: form.questions[index - 1] ? form.questions[index - 1].id : undefined,
    track_id: form.track,
    category_id: form.topic,
    topic_id: form.lesson,
    show_on_challenge: form.showOnChallenge,
    show_on_qbanks: form.showOnQbanks,
    status: form.status,
  };

  nextQuestion.answers.forEach((answer, idx) => {
    nextQuestion[`Answer${idx + 1}`] = answer.value;
  });
  delete nextQuestion.answers;

  /**
   * Modifications that will happen to the question Object ONLY
   * if it is the first one (parent)
   */
  if (index === 0) {
    nextQuestion = {
      ...nextQuestion,
      explanation: form.explanation,
      teachingpoint: form.teachingPoint,
    };
  }

  return nextQuestion;
};

/**
 * Transforms a question that comes from the backend
 * to something that Redux form can read and modify.
 * @param {Object} question Question that comes from the backend
 */
const transformToFormFriendly = (question) => {
  const {
    id,
    topic_id: lesson,
    category_id: topic,
    track_id: track,
    explanation,
    questions,
    teachingPoint,
    show_on_challenge: showOnChallenge,
    show_on_qbanks: showOnQbanks,
    status,
  } = question;

  const newQuestions = questions.map((item) => {
    const modifiedItem = {
      ...item,
    };

    modifiedItem.answers = Object.keys(item)
      .filter(key => key.startsWith('Answer'))
      .map((answer, index) => {
        delete modifiedItem[`Answer${index + 1}`];
        return { value: item[answer] };
      })
      .filter(answer => answer.value);

    modifiedItem.correctanswer = `answers[${item.correctanswer - 1}]`;

    return modifiedItem;
  });

  return {
    id,
    lesson,
    topic,
    track,
    explanation,
    questions: newQuestions,
    teachingPoint,
    showOnChallenge,
    showOnQbanks,
    status,
  };
};

export const createEmbeddedQuestion = (index, showSuccess = true) => (dispatch, getState) => {
  const questionForm = getState().form.embedded_question.values;
  const validationErrors = getState().form.embedded_question.syncErrors;
  const shouldRunValidations = questionForm.status !== 'Unreviewed';

  if (shouldRunValidations && validationErrors) {
    return generateValidationError(validationErrors, index);
  }

  const question = transformToDatabaseFriendly(questionForm, index);

  return axios.post('/api/v1/propertyApi/onlinemeded/questions/new', {
    data: question,
  })
    .then((res) => {
      dispatch({
        type: QBANK_FORM_SAVE_ID_TO_QUESTION,
        payload: {
          questionId: res.data.id,
          questionIndex: index,
        },
      });

      // If we are creating the first question of the form, we push them to the new url.
      if (index === 0) {
        history.push(`/qbank/embedded/${res.data.id}`);
      }

      if (showSuccess) {
        handleSuccess();
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getEmbeddedQuestion = id => (dispatch) => {
  axios.get(`/api/v1/propertyApi/onlinemeded/questions/${id}`)
    .then(res => res.data)
    .then((question) => {
      dispatch({
        type: QBANK_SAVE_QUESTION_TO_STORE,
        payload: transformToFormFriendly(question),
      });
    })
    .catch((e) => {
      console.log(e);
      handleError();
    });
};

export const destroyCurrentQuestion = () => ({
  type: DESTROY_CURRENT_QUESTION_IN_STORE,
});

export const updateEmbeddedQuestion = (index, showSuccess = true) => (dispatch, getState) => {
  const questionForm = getState().form.embedded_question.values;
  const validationErrors = getState().form.embedded_question.syncErrors;
  const relatedIds = [];
  const shouldRunValidations = questionForm.status !== 'Unreviewed';

  questionForm.questions.map(question => (
    relatedIds.push(question.id)
  ));

  if (shouldRunValidations && validationErrors) {
    return generateValidationError(validationErrors, index);
  }

  const question = transformToDatabaseFriendly(questionForm, index);

  if (!question.id) {
    return axios.post('/api/v1/propertyApi/onlinemeded/questions/new', {
      data: question,
      user: getState().user.user,
      relatedIds,
      question_type_id: EMBEDDED_QUESTION_TYPE_ID,
    })
      .then((res) => {
        if (showSuccess) {
          handleSuccess();
        }

        dispatch({
          type: QBANK_FORM_SAVE_ID_TO_QUESTION,
          payload: {
            questionId: res.data.id,
            questionIndex: index,
          },
        });
      })
      .catch((e) => {
        console.log(e);
        handleError();
      });
  }

  return axios.post(`/api/v1/propertyApi/onlinemeded/questions/${question.id}`, {
    data: question,
    user: getState().user.user,
    relatedIds,
    question_type_id: EMBEDDED_QUESTION_TYPE_ID,
  })
    .then(() => {
      if (showSuccess) {
        handleSuccess();
      }
    })
    .catch((e) => {
      console.log(e);
      handleError();
    });
};

export const saveEmbeddedQuestions = () => (dispatch, getState) => {
  const questionForm = getState().form.embedded_question.values;

  questionForm.questions.forEach((question, index) => {
    if (!question.id) {
      const newIndex = questionForm.questions.findIndex(item => item.linked_id === question.linked_id);
      dispatch(createEmbeddedQuestion(newIndex, true));
    } else {
      dispatch(updateEmbeddedQuestion(index, true));
    }
  });
};

export const deleteEmbeddedQuestion = (index, fields) => (dispatch, getState) => {
  const questionForm = getState().form.embedded_question.values;
  const { id } = questionForm.questions[index];

  return swal({
    title: `Deleting question #${id}`,
    text: 'Are you sure you would like to delete?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'No',
        closeModal: true,
        visible: true,
      },
      confirm: {
        text: 'Yes',
        closeModal: true,
        visible: true,
      },
    },
  })
    .then((value) => {
      if (value !== null) {
        axios.delete(`/api/v1/propertyApi/onlinemeded/questions/${id}`)
          .then(() => {
            swal({
              title: 'Question deleted successfully',
              icon: 'success',
            });

            if (questionForm.questions.length <= 1) {
              history.push('/onlinemeded/questions/overview');
            } else {
              fields.remove(index);
            }
          })
          .catch((e) => {
            console.log(e);
            handleError();
          });
      }
    });
};

export const setUnapproved = () => (dispatch) => {
  dispatch(change('embedded_question', 'status', 'Unreviewed'));
};
