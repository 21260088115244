import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import DropdownItem from "./DropdownItem";
import {
  DropdownContainer,
  ItemContainer,
  ValueField,
  InputArrow
} from "./styles";

import { Label, LabelError } from "../sharedStyles";

const Dropdown = ({ input, meta, data, placeholder, label, filterBy }) => {
  const getDataWithFilter = useCallback(() => {
    if (!data) return [];
    if (!filterBy) return data;

    const filterByKey = Object.keys(filterBy).find(item => item);
    const filterByValue = filterBy[filterByKey];

    if (!filterByValue) return data;

    /**
     * Assumes that data[i][filterByKey] is either a string or a number
     */
    if (
      data.find(i => i[filterByKey]) &&
      typeof data.find(i => i[filterByKey])[filterByKey] !== "object"
    ) {
      return data.filter(item => item[filterByKey] === filterByValue);
    }

    /**
     * Assumes that data[i][filterByKey] is an array of values
     */
    return data.filter(item =>
      item[filterByKey]
        ? item[filterByKey].some(id => id === filterByValue)
        : false
    );
  }, [filterBy, data]);

  const [active, setIsActive] = useState(false);
  const handleSelect = value => {
    input.onChange(value);
    setIsActive(false);
  };

  const handleClick = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  const getCurrentInputLabel = () => {
    const currentSelection = getDataWithFilter().find(
      i => i.value === input.value
    );
    if (currentSelection) return currentSelection.label;
    return undefined;
  };

  /**
   * If only 1 item is passed to the dropdown, pre-select it.
   */
  useEffect(() => {
    if (getDataWithFilter().length === 1) {
      input.onChange(getDataWithFilter().find(i => i.value).value);
    }
  }, []);

  return (
    <div>
      <Fragment>
        {label && <Label htmlFor={input.name}>{label}</Label>}
        {meta.error && <LabelError>{meta.error}</LabelError>}
      </Fragment>
      <DropdownContainer onFocus={handleClick} onBlur={handleBlur} tabIndex={0}>
        <ValueField
          type="text"
          value={getCurrentInputLabel() || ""}
          placeholder={placeholder}
          readOnly
        />
        <InputArrow />
        <ItemContainer active={active}>
          {getDataWithFilter().map(item => (
            <DropdownItem
              key={item.value}
              name={item.label || item.value}
              value={item.value}
              onClick={handleSelect}
            />
          ))}
        </ItemContainer>
      </DropdownContainer>
    </div>
  );
};

Dropdown.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    checked: PropTypes.bool
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  filterBy: PropTypes.shape({
    [PropTypes.string]: PropTypes.number
  })
};

Dropdown.defaultProps = {
  label: null,
  placeholder: null,
  filterBy: null,
  data: null
};

export default Dropdown;
