
export function createTab(type, data) {
  return (dispatch) => {
    // Tab model
    // {
    //     type: string, Type of tab object, this is for unique tab logic based on type, e.g., question, error_report
    //     data: object, containing data object, e.g., question, error_report
    // }
    const tab = {
      type,
      data,
    };
    dispatch({
      type: 'CREATE_TAB',
      payload: tab,
    });
  };
}

export function deleteTab(index) {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_TAB',
      payload: index,
    });
  };
}

export function selectTab(index) {
  return (dispatch) => {
    dispatch({
      type: 'SELECT_TAB',
      payload: index,
    });
  };
}

export function minimizeWindow() {
  return (dispatch) => {
    dispatch({
      type: 'MINIMIZE_WINDOW',
    });
  };
}

export function expandWindow() {
  return (dispatch) => {
    dispatch({
      type: 'EXPAND_WINDOW',
    });
  };
}

export function resizeStart(mousePosition) {
  return (dispatch) => {
    dispatch({
      type: 'RESIZE_START',
      payload: mousePosition,
    });
  };
}

export function resizeTick(mousePosition) {
  return (dispatch) => {
    dispatch({
      type: 'RESIZE_TICK',
      payload: mousePosition,
    });
  };
}

export function resizeEnd() {
  return (dispatch) => {
    dispatch({
      type: 'RESIZE_END',
    });
  };
}
