export default function () {
  return {
    id: 'new',
    data: {
      first_name: {
        data: '',
        available: true,
      },
      last_name: {
        data: '',
        available: true,
      },
      email: {
        data: '',
        available: true,
      },
      is_activated: {
        data: 0,
        available: true,
      },
      trust_level: {
        data: '',
        available: true,
      },
    },
  };
}
