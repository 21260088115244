export default function reducer(state = {
  tabs: [],
  isMinimized: false,
  isResizing: false,
  selected: 0,
  tabDeleted: false,
  mouseStartPosition: null,
  startingHeight: 375,
  height: 375,
  minHeight: 175,
  maxHeight: 500,
}, action) {
  switch (action.type) {
    case 'CREATE_TAB': {
      // Check if tab is opened with data already
      const newTabId = `${action.payload.type}-${action.payload.data.id}`;
      for (let i = 0; i < state.tabs.length; i++) {
        // Tab with this data already exists, return state to exit
        if (newTabId === `${state.tabs[i].type}-${state.tabs[i].data.id}`) {
          // Set selected to existing tab
          return {
            ...state,
            selected: i,
          };
        }
      }
      const temp = Object.assign([], state.tabs);
      temp.push(action.payload);
      return {
        ...state,
        tabs: temp,
        selected: temp.length - 1,
      };
    }
    case 'DELETE_TAB': {
      const temp = Object.assign([], state.tabs);
      temp.splice(action.payload, 1);
      return {
        ...state,
        tabs: temp,
        tabDeleted: true,
        selected: state.selected >= temp.length ? temp.length - 1 : state.selected,
      };
    }
    case 'SELECT_TAB': {
      if (state.tabDeleted) {
        return {
          ...state,
          tabDeleted: false,
        };
      }
      return {
        ...state,
        selected: action.payload,
      };
    }
    case 'MINIMIZE_WINDOW': {
      return {
        ...state,
        isMinimized: true,
      };
    }
    case 'EXPAND_WINDOW': {
      return {
        ...state,
        isMinimized: false,
      };
    }
    case 'RESIZE_START': {
      return {
        ...state,
        isResizing: true,
        mouseStartPosition: action.payload,
        startingHeight: state.height,
      };
    }
    case 'RESIZE_TICK': {
      if (action.payload.y === 0 || state.isMinimized) { return state; }

      const verticalPixelChange = state.mouseStartPosition.y - action.payload.y;
      let newHeight = state.startingHeight + verticalPixelChange;

      // Clamp new height
      if (newHeight > state.maxHeight) {
        newHeight = state.maxHeight;
      } else if (newHeight < state.minHeight) {
        newHeight = state.minHeight;
      }

      return {
        ...state,
        height: newHeight,
      };
    }
    case 'RESIZE_END': {
      return {
        ...state,
        isResizing: false,
      };
    }
  }

  return state;
}
