import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs, Alert } from 'react-bootstrap';

import { changeModel, postComment } from '../../actions/modelActions';

import { Field, InvalidDiv } from './styles';
import fieldComponents from './fieldComponents';

// Setup props with Redux and initialize form (8 answers specified to avoid dynamically checking all fields
const mapStateToProps = state => ({
  navigation: state.navigation.data,
  panelObject: state.navigation.panelObject,
  isNavFetched: state.navigation.fetched,
  isParamsFetched: state.model.paramsFetched,
  isModelFetched: state.model.modelFetched,
});

function buildAnswers(data) {
  const output = [];

  let i = 1;
  let currentAnswer = data.Answer1;
  while (currentAnswer) {
    output.push({
      value: i,
      label: currentAnswer.data,
    });

    currentAnswer = data[`Answer${++i}`];
  }

  return output;
}

class AdminForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTabsShortcutAlert: false,
    };

    this.onChangeModel = this.onChangeModel.bind(this);
  }

  componentDidMount() {
    this.checkAlerts();
  }

  onChangeModel(data) {
    this.props.changeModel(data, this.props.modelIndex);
  }

  checkAlerts() {
    if (!localStorage.getItem('seenTabsShortcutAlert')) {
      this.setState({
        showTabsShortcutAlert: true,
      });
    }
  }

  handleTabsAlertClose() {
    localStorage.setItem('seenTabsShortcutAlert', true);
    this.setState({ showTabsShortcutAlert: false });
  }

  parseFieldOptions(field, params, model) {
    let options = null;
    // If a field has a defined dependency_column then we need to filter out unavailable options
    if (field.parent) {
      // Flag for checking if the current selection is available
      let isCurrentSelectionAvailable = false;
      options = params[field.options].filter((option) => {
        if (option.id === 0) {
          return true;
        }
        // Filter out options based on field dependency value
        if (typeof option[field.parent.column_name] === 'object') {
          // Array for parent options that this option belongs to.
          const optionsArray = option[field.parent.column_name];
          // If parent options are none and the parent object is none then return this option
          if (optionsArray.length === 0 && model.data[field.parent.column_name].data === 0) {
            // If the current option the currently selected option
            // Set true so we know not to set default option
            if (option.id === model.data[field.column_name].data) {
              isCurrentSelectionAvailable = true;
            }
            return true;
          }

          // Loop through options and see if one applies to current option.
          // We want to exclude current option if it doesn't relate to parent option.
          for (let i = 0; i < optionsArray.length; i++) {
            if (optionsArray[i] === model.data[field.parent.column_name].data) {
              if (option.id === model.data[field.column_name].data) {
                isCurrentSelectionAvailable = true;
              }
              return true;
            }
          }
          return false;
        }
        if (option[field.parent.column_name] === model.data[field.parent.column_name].data) {
          // Current selection exists in filtered list, Hooray!
          if (option.id === model.data[field.column_name].data) {
            isCurrentSelectionAvailable = true;
          }
          return true;
        }
        return false;
      });

      // If there are no options for this field and the field id data is not 0 then set it to 0
      if (options.length === 1 && model.data[field.column_name].data !== 0) {
        this.props.changeModel({
          [field.column_name]: 0,
        }, this.props.modelIndex);
      }

      // If current selection is not available and there is at least
      // one option we change the form value to the first available option.
      if (!isCurrentSelectionAvailable && options.length > 1) {
        this.props.changeModel({
          [field.column_name]: options[1].id,
        }, this.props.modelIndex);
      }
    } else {
      options = params[field.options];
    }
    return options;
  }


  // TODO: every component created here should receive state from props and NOT access the redux store directly!
  renderFields(fields, params, answerOptions) {
    const { model } = this.props;
    return fields.map((field) => {
      let options = null;
      if (field.options && field.options === 'answerOptions') {
        options = answerOptions;
      } else if (field.options) {
        options = this.parseFieldOptions(field, params, model);
      }

      // Validation checks, if invalid we set an invalid error object to the invalid arrays
      // This should be moved to a separate file if there is going to be more validation checks than just is_required
      if (model.data.status && model.data.status.data !== 'Unreviewed' && field.is_required && !model.data[field.column_name].data) {
        this.invalid.push({
          column_name: field.name,
          error: 'is required',
        });
      }

      const Component = fieldComponents(field.type);
      let fieldComponentProps = {};
      if (field.type === 'comments') {
        fieldComponentProps = {
          id: model.id,
          comments: model.comments,
          panelSlug: this.props.panelObject.panelSlug,
          modelIndex: this.props.modelIndex,
          postComment: comment => this.props.postComment(comment),
        };
      } else { // TODO: map props and remove embedded redux state from each component in fieldComponents
        fieldComponentProps = {
          model,
          modelIndex: this.props.modelIndex,
          panelObject: this.props.panelObject,
        };
      }

      // If field is unavailable then return null and don't render field component
      if (model.data[field.column_name] && !model.data[field.column_name].available) {
        return null;
      }

      return (
        <Field key={field.column_name}>
          <Component
            key={field.column_name}
            name={field.column_name}
            label={field.name}
            type={field.type}
            value={model.data[field.column_name] ? model.data[field.column_name].data : []}
            options={options}
            onChange={this.onChangeModel}
            disabled={model.data[field.column_name] ? !model.data[field.column_name].available : false}
            {...fieldComponentProps}
          />
        </Field>
      );
    });
  }

  render() {
    const {
      onSubmit,
      params,
      panelObject,
    } = this.props;
    const answerOptions = buildAnswers(this.props.model.data);

    // Array of invalid errors
    this.invalid = [];

    return (
      <form onSubmit={onSubmit}>
        {
          this.state.showTabsShortcutAlert &&
            <Alert bsStyle="warning" onDismiss={() => this.handleTabsAlertClose()}>
              <span>
                Pro tip: Use the left and right arrow keys to toggle between tabs after clicking the tabs row.
              </span>
            </Alert>
        }
        <Tabs id="editor-tabs" >
          {panelObject.tabs.map((item, i) => (
            <Tab eventKey={i} key={item.name} title={item.name}>
              {this.renderFields(item.fields, params, answerOptions)}
            </Tab>
          ))}
        </Tabs>
        <hr />
        <div>
          <button type="submit" className="btn btn-primary" disabled={this.invalid.length > 0}>
            Submit
          </button>
          { this.invalid.length > 0 &&
            <InvalidDiv>
              <span>Errors:</span>
              <ul>
                {/* eslint-disable-next-line react/no-array-index-key */}
                { this.invalid.map((value, key) => (<li key={key}>{value.column_name} {value.error}</li>))}
              </ul>
            </InvalidDiv>
        }
        </div>
      </form>
    );
  }
}

AdminForm.propTypes = {
  changeModel: PropTypes.func.isRequired,
  modelIndex: PropTypes.number.isRequired,
  model: PropTypes.shape().isRequired,
  panelObject: PropTypes.shape().isRequired,
  postComment: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps,
  {
    changeModel,
    postComment,
  },
)(AdminForm);
