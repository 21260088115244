import styled from 'styled-components';

export const Div = styled.div`
`;

export const SummaryGrid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex-wrap: wrap
`;

export const SummaryItem = styled.div`
  width: 46%;
  min-height: 50px;
  margin: 10px;
`;

export const PhysicalExamWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex-wrap: wrap
`;

export const PhysicalExam = styled.div`
  width: 46%;
  min-height: 50px;
`;

export const VitalsGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
`;

export const VitalBlock = styled.div`
  padding: 5px 5px;
`;
