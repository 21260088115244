import styled, { css } from 'styled-components';

export const NavContainer = styled.div`
  display: flex;
  height: 65px;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
  padding: 0 20px;

  .logo {
    font-size: 20px;
    margin-right: 30px;
  }

  .nav-items {
    margin-right: auto;
    display: flex;
    height: 100%;

    > * {
      margin-right: 15px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .property-selector {
    margin-right: 20px;
  }

  .user {
    margin-right: 20px;
  }

  .glyphicon {
    display: inline-block;
    margin-right: 4px;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;

  .parent-item {
    padding: 5px 20px;
    display:  flex;
    align-items: center;
    height: 100%;
    box-shadow: none;
    outline: none;
    border: 0;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .caret {
    width: 0; 
    height: 0; 
    margin-left: 8px;
    margin-top: 2px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    
    border-top: 4px solid ${props => props.theme.color.brand.titleBlack};
  }
`;

export const DropdownList = styled.ul`
  list-style-type: none;
  position: absolute;
  z-index: 9;
  margin: 0;
  padding: 0;
  min-width: 160px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  padding: 5px 0;

  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  
  transition: 200ms opacity ease, 200ms transform ease;

  ${props => props.isOpen && css`
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  `}
`;

export const DropdownItem = styled.li`
  display: block;
  > a {
    padding: 5px 20px;
    display: block;
    color: ${props => props.theme.color.brand.titleBlack};

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.brand.titleBlack};
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;
