import swal from 'sweetalert';

export const generateValidationError = (validations, index) => {
  const generateErrorMessage = () => {
    if (validations.questions && validations.questions[index]) {
      return `Fields missing on question: ${Object.keys(validations.questions[index]).toString()}`;
    }

    if (validations.questions) {
      return 'There is an error in one of the other questions than the one you are trying to save';
    }

    return `Fields missing on parent question: ${Object.keys(validations).toString()}`;
  };

  return swal({
    title: 'Required fields missing',
    text: generateErrorMessage(),
    icon: 'warning',
  });
};

export const handleSuccess = () => swal({
  title: 'Question saved successfully',
  icon: 'success',
});

export const handleError = () => swal({
  title: 'Something went wrong',
  icon: 'warning',
});
