import React from 'react';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

export default property => ([
  {
    Header: 'ID',
    accessor: 'id',
    width: 50,
    Cell: id => (<Link to={`/${property}flashcards/editor/${id.value}`}>{id.value}</Link>),
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['id'] }),
    filterAll: true,
  },
  {
    Header: 'Topic',
    accessor: 'category_name',
    width: 100,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Lesson',
    accessor: 'topic_name',
    width: 200,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ['name'] }),
    filterAll: true,
  },
  {
    Header: 'Question',
    accessor: 'question',
  },
  {
    Header: 'Answer',
    accessor: 'answer',
  },
  {
    Header: 'Created By',
    accessor: 'created_by',
    sortable: false,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
  },
  {
    Header: 'Error Reports',
    accessor: 'error_reports_count',
    Cell: item => (
      <div
        style={item.value > 0 ? { color: 'red' } : { color: 'green' }}
      >
        {item.value}
      </div>
    ),
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
  },
]);
