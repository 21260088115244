import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TabsContainer, TabButton, TabChild } from './Tabs.styles';

const Tabs = ({ children, activeTab, onSelect }) => {
  const handleTabClick = id => (e) => {
    e.preventDefault();
    onSelect(id);
  };

  return (
    <Fragment>
      <TabsContainer>
        {children.map(child => (
          <TabButton
            onClick={handleTabClick(child.props.id)}
            active={child.props.id === activeTab}
            key={child.props.id}
          >
            {child.props.name}
          </TabButton>
        ))}
      </TabsContainer>
      <div>
        {children.map(child => (
          <TabChild key={child.props.id} active={child.props.id === activeTab}>{child}</TabChild>
        ))}
      </div>
    </Fragment>
  );
};

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  activeTab: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  children: [],
};

export default Tabs;
