import styled, { css } from 'styled-components';

export const CommentDiv = styled.div`
    display: flex;
`;

export const Comment = styled.div`
    padding: 2px 10px;
    margin-bottom: 5px;

    border: 1px solid #e3e3e3;
    border-radius: 3px;
    background: #f6f6f6;

    p {
        margin: 0 10px;
        font-style: italic;
    }

    ${props => props.isNew && css`
        border: none;
        border-left: 2px solid #02c3e8;
        border-radius: 0;
        background: #fff;

        span {
            font-weight: 400;
        }
    `}
`;

export const Header = styled.span`
    font-weight: 600;
`;

export const CreatedAt = styled.span`
    display: block;
`;

export const Input = styled.input`
    width: 100%;
`;

export const Button = styled.button`
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    background-color: #3097d1;
    color: #ffffff;
    border: 1px solid #2a88bd;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;
