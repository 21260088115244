import React, { Children } from 'react';

import { DropdownContainer, DropdownList, DropdownItem } from './Navigation.styles';

const NavDropdown = ({
  children,
  label,
  currentlyOpen,
  name,
  onClick,
  access,
  permissions = [],
}) => {
  const handleClick = () => onClick(name);

  const isOpen = currentlyOpen === name;

  if (!permissions.some(permission => access.indexOf(permission.panel) !== -1)) {
    return null;
  }

  return (
    <DropdownContainer isOpen={isOpen} onClick={handleClick}>
      <div className="parent-item">
        <div>{label}</div>
        <span className="caret" />
      </div>
      <DropdownList isOpen={isOpen}>
        {Children.map(children, child => (
          <DropdownItem>{child}</DropdownItem>
        ))}
      </DropdownList>
    </DropdownContainer>
  );
};

export default NavDropdown;
