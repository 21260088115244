import Question from './question';
import Flashcard from './flashcard';
import Audition from './audition';
import Case from '../case/casePreview';

const matrix = {
  questions: Question,
  flashcards: Flashcard,
  auditions: Audition,
  cases: Case,
};

export default function (key) {
  const result = matrix[key];
  if (!result) {
    return null;
  }

  return result;
}
