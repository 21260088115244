import * as constants from '../constants';

export default function reducer(state = {
  all: [],
  single: [],
  singleName: null,
  monthly: [],
  mrr: [],
  sold: null,
  revenue: null,
  totalAverageLtv: null,
  firstPurchaseLtv: null,
  fetchedAll: false,
  fetchedSingle: false,
  fetchedMonthly: false,
  fetchedMrr: false,
  fetchedTotalAverageLTV: false,
  fetchedFirstPurchaseLTV: false,
  error: null,
  fetchingAll: false,
  fetchingSingle: false,
  fetchingMonthly: false,
  fetchingMrr: false,
  fetchingTotalAverageLTV: false,
  fetchingFirstPurchaseLTV: false,
}, action) {
  switch (action.type) {
    case constants.FETCH_ALL: {
      return { ...state, fetchingAll: true };
    }
    case constants.FETCH_ALL_ERROR: {
      return { ...state, fetchingAll: false, error: action.payload };
    }
    case constants.FETCH_ALL_SUCCESS: {
      return {
        ...state,
        fetchingAll: false,
        fetchedAll: true,
        all: action.payload.data,
        sold: action.payload.sold,
        revenue: action.payload.revenue,
      };
    }

    case constants.FETCH_SINGLE: {
      return { ...state, fetchingSingle: true };
    }
    case constants.FETCH_SINGLE_ERROR: {
      return { ...state, fetchingSingle: false, error: action.payload };
    }
    case constants.FETCH_SINGLE_SUCCESS: {
      return {
        ...state,
        fetchingSingle: false,
        fetchedSingle: true,
        single: action.payload.data,
        singleName: action.payload.name,
      };
    }

    case constants.FETCH_MONTHLY: {
      return { ...state, fetchingMonthly: true };
    }
    case constants.FETCH_MONTHLY_ERROR: {
      return { ...state, fetchingMonthly: false, error: action.payload };
    }
    case constants.FETCH_MONTHLY_SUCCESS: {
      return {
        ...state,
        fetchingMonthly: false,
        fetchedMonthly: true,
        monthly: action.payload.data,
      };
    }

    case constants.FETCH_MRR: {
      return { ...state, fetchingMrr: true };
    }
    case constants.FETCH_MRR_ERROR: {
      return { ...state, fetchingMrr: false, error: action.payload };
    }
    case constants.FETCH_MRR_SUCCESS: {
      return {
        ...state,
        fetchingMrr: false,
        fetchedMrr: true,
        mrr: action.payload.data,
      };
    }

    case constants.FETCH_TOTAL_AVERAGE_LTV: {
      return { ...state, fetchingTotalAverageLTV: true };
    }
    case constants.FETCH_TOTAL_AVERAGE_LTV_ERROR: {
      return { ...state, fetchingTotalAverageLTV: false, error: action.payload };
    }
    case constants.FETCH_TOTAL_AVERAGE_LTV_SUCCESS: {
      return {
        ...state,
        fetchingTotalAverageLTV: false,
        fetchedTotalAverageLTV: true,
        totalAverageLtv: action.payload.data,
      };
    }

    case constants.FETCH_FIRST_PURCHASE_LTV: {
      return { ...state, fetchingFirstPurchaseLTV: true };
    }
    case constants.FETCH_FIRST_PURCHASE_LTV_ERROR: {
      return { ...state, fetchingFirstPurchaseLTV: false, error: action.payload };
    }
    case constants.FETCH_FIRST_PURCHASE_LTV_SUCCESS: {
      return {
        ...state,
        fetchingFirstPurchaseLTV: false,
        fetchedFirstPurchaseLTV: true,
        firstPurchaseLtv: action.payload.data,
      };
    }
  }

  return state;
}
