import styled, { css } from 'styled-components';

export const TabsContainer = styled.div`
  background-color: #E6E6E6;
  width: 100%;
`;

export const TabButton = styled.button`
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  font-weight: 900;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 23px 30px;

  ${props => props.active && css`
    color: ${props.theme.color.brand.main};
  `}
`;

export const TabChild = styled.div`
  display: none;
  ${props => props.active && css`
    display: block;
  `}
`;
