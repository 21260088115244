import axios from 'axios/index';
import * as constants from '../constants';
import history from '../history';


export function unsavedChanges(enabled) {
  return (dispatch) => {
    dispatch({
      type: constants.UNSAVED_CHANGES_WARNING,
      payload: enabled,
    });
  };
}

export function refreshNavigation(shouldUpdate) {
  return (dispatch, getState) => {
    const { property } = getState().navigation;
    let { component } = getState().navigation;
    component = component || 'overview';
    dispatch({
      type: 'CHANGE_PROPERTY',
      payload: {
        slug: property,
        shouldUpdate,
      },
    });
    if (shouldUpdate) {
      const { panelSlug, properties } = getState().navigation;
      const menuItem = properties[property].panels.find(panel => panel.slug === panelSlug) ||
        properties[property].panels[0] ||
        null;
      const subMenuItem = menuItem.subitems.find(subitem => subitem.component === component) ||
        menuItem.subitems.find(subitem => subitem.component === 'overview') ||
        menuItem.subitems[0] ||
        null;
      history.push(`/${property}/${menuItem.slug}/${subMenuItem.component}`);
    }
  };
}

export function changeProperty(property) {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_PROPERTY',
      payload: {
        slug: property,
      },
    });
    dispatch({
      type: 'CLEAR_MODEL_DATA',
    });
    dispatch(refreshNavigation(true));
  };
}

export function fetchNavigation() {
  return (dispatch, getState) => {
    dispatch({
      type: constants.FETCH_NAVIGATION,
    });
    axios.get('/api/v1/adminProduct/navigation')
      .then(
        (response) => {
          const {
            property,
            panelSlug,
            component,
            id,
          } = getState().navigation;
          dispatch({
            type: constants.FETCH_NAVIGATION_SUCCESS,
            payload: {
              ...response.data,
              property: property || response.data.property,
              panelSlug,
              component,
              id,
            },
          });
          dispatch(refreshNavigation(!id));
        },
        (error) => {
          dispatch({
            type: constants.FETCH_NAVIGATION_ERROR,
            payload: error,
          });
        },
      );
  };
}

export function updatePanelObject({
  property, panelSlug, component, id,
}) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_PANEL_OBJECT,
      payload: {
        property,
        panelSlug,
        component,
        id,
      },
    });
  };
}

export function updateNavigationParameters({
  property, panelSlug, component, id,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_NAVIGATION_PARAMETERS',
      payload: {
        property, panelSlug, component, id,
      },
    });
    if (getState().navigation.properties) {
      dispatch({
        type: 'UPDATE_PANEL_OBJECT',
        payload: {
          property, panelSlug, component, id,
        },
      });
    }
  };
}
