import ErrorReport from './errorReport';

const matrix = {
  error_report: ErrorReport,
};

export default function (key) {
  const result = matrix[key];
  if (!result) {
    const msg = 'Info Panel does not exist! ' +
      `Please add info panel to Window/infoPanels and include it in Window/infoPanels/index.js for ${key}`;
    throw new Error(msg);
  }

  return result;
}
