import * as constants from '../constants';

export default function reducer(state = {
  data: [],
  panelObject: {},
  fetching: false,
  fetched: false,
  error: null,
  panelSlug: null,
  component: null,
  id: null, // id of a model object
  showUnsavedChangesWarning: false,
}, action) {
  const buildPanelState = (panel, component, itemId, navData) => {
    if (!navData) {
      return null;
    }
    const menuItem = navData.panels.find(navItem => navItem.slug === panel) || navData[0] || null;
    if (menuItem == null) { return {}; }
    const subItem = menuItem.subitems.find(subitem => subitem.component === component) || menuItem.subitems[0] || null;
    if (subItem == null) { return {}; }

    return {
      ...subItem,
      itemId,
      panelName: menuItem.name,
      panelSlug: menuItem.slug,
      panelSingularName: menuItem.singularName,
      panelSelector: `${menuItem.slug}/${subItem.component}`,
    };
  };

  switch (action.type) {
    case constants.FETCH_NAVIGATION: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case constants.FETCH_NAVIGATION_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    }
    case constants.FETCH_NAVIGATION_SUCCESS: {
      const {
        property,
        properties,
        panelSlug,
        component,
        id,
      } = action.payload;
      const panelObject = buildPanelState(panelSlug, component, id, properties[property]);
      return {
        ...state,
        fetching: false,
        fetched: true,
        panelObject,
        properties: action.payload.properties,
        videoLibraryId: action.payload.videoLibraryId,
        property: action.payload.property,
        basePath: `/${action.payload.properties[property].slug}`,
        basePropertyApiPathSlugless: '/api/v1/propertyApi',
        basePropertyApiPath: `/api/v1/propertyApi/${action.payload.properties[property].slug}`,
        baseAdminApiPath: '/api/v1/adminProduct',
      };
    }
    case constants.UPDATE_PANEL_OBJECT: {
      const {
        property,
        panelSlug,
        component,
        id,
      } = action.payload;
      const panelObject = buildPanelState(panelSlug, component, id, state.properties[property]);
      return {
        ...state,
        panelObject,
        // save panelSlug, component, and id so we'll have them in FETCH_NAVIGATION_SUCCESS
        ...action.payload,
        showUnsavedChangesWarning: false,
      };
    }
    case constants.UNSAVED_CHANGES_WARNING: {
      return {
        ...state,
        showUnsavedChangesWarning: action.payload,
      };
    }
    case constants.CHANGE_PROPERTY: {
      return {
        ...state,
        property: action.payload.slug,
      };
    }
    case constants.UPDATE_NAVIGATION_PARAMETERS: {
      const {
        property,
        panelSlug,
        component,
        id,
      } = action.payload;
      let panelObject = {
        itemId: id,
      };
      const pathProperties = {};
      if (state.properties && state.properties[property]) {
        panelObject = buildPanelState(panelSlug, component, id, state.properties[property]);
        pathProperties.basePath = `/${state.properties[property].slug}`;
        pathProperties.basePropertyApiPathSlugless = '/api/v1/propertyApi';
        pathProperties.basePropertyApiPath = `/api/v1/propertyApi/${state.properties[property].slug}`;
        pathProperties.baseAdminApiPath = '/api/v1/adminProduct';
      }
      return {
        ...state,
        panelObject,
        property,
        panelSlug,
        component,
        id,
        ...pathProperties,
      };
    }

    case constants.LOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        showUnsavedChangesWarning: false,
      };
    }
  }

  return state;
}
