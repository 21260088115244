import axios from 'axios/index';

export function logout() {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT' });
    axios.post('/logout')
      .then(
        (response) => {
          dispatch({ type: 'LOGOUT_FULFILLED', payload: response.data });
          window.location.href = `//${window.location.host}/login`;
        },
        (err) => {
          dispatch({ type: 'LOGOUT_REJECTED', payload: err });
        },
      );
  };
}
