import styled, { css } from 'styled-components';

export const QuestionPreviewContainer = styled.aside`
  background-color: #ffffff;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.15);
  padding: 30px;
  max-width: 90%;
  margin: 20px auto;
  position: sticky;
  top: 35px;

  color: ${props => props.theme.color.brand.titleBlack};
`;

export const Title = styled.h1`
  margin: 0 0 14px 0;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 3px;
  text-transform: uppercase;

  ${props => props.clickable && css`
    cursor: pointer;
  `}
`;

export const Paragraph = styled.p`
  font-family: Georgia, serif;
  font-size: 16px;
  line-height: 25px;
`;

export const AnswerContainer = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }

  > * {
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
