export default function reducer(state = {
  logging_out: false,
  logout_success: false,
  message: null,
  error: null,
}, action) {
  switch (action.type) {
    case 'LOGOUT': {
      return { ...state, logging_out: true };
    }
    case 'LOGOUT_REJECTED': {
      return { ...state, logging_out: false, error: action.payload };
    }
    case 'LOGOUT_FULFILLED': {
      return {
        ...state,
        logging_out: false,
        logout_success: true,
        message: action.payload,
      };
    }
  }

  return state;
}
