import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { createMatchingQuestion } from '../../../actions/qbank/matching';

import MatchingForm from '../../../components/Qbank/Matching/Form';

const formDecoratedComponent = reduxForm({
  form: 'matching_question',
})(MatchingForm);

const formSelector = formValueSelector('matching_question');

const mapStatetoProps = state => ({
  questionData: {
    questions: formSelector(state, 'questions'),
    explanation: formSelector(state, 'explanation'),
    teaching_point: formSelector(state, 'teaching_point'),
    track: formSelector(state, 'track'),
    topic: formSelector(state, 'topic'),
    answers: formSelector(state, 'answers') || [],
    vignette: formSelector(state, 'vignette'),
  },
  initialValues: {
    status: 'Unreviewed',
    questions: [{}], // this shows 1 empty question by default @ new question
    showOnChallenge: true,
    showOnQbanks: false,
  },
});

export default connect(
  mapStatetoProps,
  {
    onSubmitQuestion: createMatchingQuestion,
  },
)(formDecoratedComponent);
