import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { createEmbeddedQuestion } from '../../../actions/qbank/embedded';

import EmbeddedForm from '../../../components/Qbank/Embedded/Form';

const formDecoratedComponent = reduxForm({
  form: 'embedded_question',
})(EmbeddedForm);

const formSelector = formValueSelector('embedded_question');

const mapStatetoProps = state => ({
  questionData: {
    questions: formSelector(state, 'questions'),
    explanation: formSelector(state, 'explanation'),
    teaching_point: formSelector(state, 'teaching_point'),
    track: formSelector(state, 'track'),
    topic: formSelector(state, 'topic'),
  },
  initialValues: {
    status: 'Unreviewed',
    questions: [{
      // this shows 1 empty question with 5 answers by default @ new question
      answers: Array(5).fill({}),
    }],
    showOnChallenge: true,
    showOnQbanks: false,
  },
});

export default connect(
  mapStatetoProps,
  {
    onSubmitQuestion: createEmbeddedQuestion,
  },
)(formDecoratedComponent);
