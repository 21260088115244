import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from './checkCircleStyles';

export const CheckCircle = ({ className, checked, ...props }) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <img
          className="reportSelectImage"
          style={{
            width: '25px',
            height: '25px',
          }}
          src={
            checked
            ? '/images/errorReports/circle_checked.svg'
            : '/images/errorReports/circle_unchecked.svg'
          }
        />
      </StyledCheckbox>
    </CheckboxContainer>
  </label>
);

CheckCircle.propTypes = {
  className: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};
